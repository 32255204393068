import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialState =
  window && process.env.NODE_ENV !== "development"
    ? window.INITIAL_STATE
    : {
        config: {
          PORT: process.env.PORT || 3000,
          BASE_IMAGE_URL: process.env.BASE_IMAGE_URL || "https://dev.okyanusbahis.co.uk",
          BASE_PRODUCT_NAME: process.env.BASE_PRODUCT_NAME || "Okyanusbahis",
          BASE_URL: process.env.BASE_URL,
          REACT_APP_LOGIN_API_PUBLIC_KEY: process.env.REACT_APP_LOGIN_API_PUBLIC_KEY || "",
        },
      };
const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

export default store;
