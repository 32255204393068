import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { loginModal } from "../Login/actions";
import FooterPayments from "./FooterPayments";
import FooterSlogan from "./FooterSlogan";
import FooterBottom from "./FooterBottom";
import FooterMenuTitle from "./FooterMenuTitle";
import FooterMenuLink from "./FooterMenuLink";
import { apiVars } from "../../api";

const Footer = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = (path) => {
    const Token = getItemFromLocalStorage("Token");
    let ispresent = path.includes("/cashier");
    if (!Token && ispresent) {
      props.loginModal(true);
    } else history.push(path);
  };

  let location = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <footer className="footer main-footer">
      <div className="custom-hr-line"></div>
      <FooterPayments />
      <div className="custom-hr-line"></div>
      <div className="footer-menu-block">
        <div className="footer-menu">
          <div className="footer-menu-block">
            <FooterMenuTitle title="OkyanusBahis" />
            <div className="footer-menu-tab">
              <FooterMenuLink
                to="/about-us"
                titleKey="About us"
              />
              <FooterMenuLink
                to="/support"
                titleKey="Contact us"
              />
              <div className="footer-menu-link-block">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.oceanbetpartners.com">
                  Affiliates
                </a>
              </div>
            </div>
          </div>
          <div className="footer-menu-block">
            <FooterMenuTitle title={t("Products")} />
            <div className="footer-menu-tab">
              <div className="footer-menu-link-block">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={apiVars.pokerUrl}>
                  Poker
                </a>
              </div>
              <FooterMenuLink
                to="/sports"
                titleKey="Sports"
              />
              <FooterMenuLink
                to="/casino"
                titleKey="Casino"
              />
              <FooterMenuLink
                to="/live-casino"
                titleKey="Live Casino"
              />
            </div>
          </div>
          <div className="footer-menu-block">
            <FooterMenuTitle title={t("Live Casino")} />
            <div className="footer-menu-tab">
              <FooterMenuLink
                  loginRouteTo="pragmatic-play"
                  titleKey="Pragmatic Play"
              />
              <FooterMenuLink
                  loginRouteTo="evolution"
                  titleKey="Evolution"
              />
              <FooterMenuLink
                  loginRouteTo="ezugi"
                  titleKey="Ezugi"
              />
              <FooterMenuLink
                  loginRouteTo="vivo-gaming"
                  titleKey="Vivo Gaming"
              />
            </div>
          </div>
          <div className="footer-menu-block">
            <FooterMenuTitle title={t("Secure Banking")} />
            <div className="footer-menu-tab">
              <div className="footer-menu-link-block">
                <button
                  onClick={() => handleClick("/cashier/wallets")}
                  className={`${window?.location?.pathname?.includes("/cashier/wallets") ? "active-clr" : ""} footer-menu-link`}>
                  {t("Deposits")}
                </button>
              </div>
              <div className="footer-menu-link-block">
                <button
                  onClick={() => handleClick("/casher/wallets")}
                  className={`${window?.location?.pathname?.includes("/cashier/wallets") ? "active-clr" : ""} footer-menu-link`}>
                  {t("Withdrawals")}
                </button>
              </div>
            </div>
          </div>
          <div className="footer-menu-block">
            <FooterMenuTitle title={t("Terms & Regulations")} />
            <div className="footer-menu-tab">
              <FooterMenuLink
                to="/terms&conditions"
                titleKey="Terms and Conditions"
              />
              <FooterMenuLink
                to="/sportsbook-rules"
                titleKey="sports_book_rules"
              />
              <FooterMenuLink
                to="/responsible-gaming"
                titleKey="Responsible Gaming"
              />
              <FooterMenuLink
                to="/self-exclusion"
                titleKey="Self-Exclusion"
              />
              <FooterMenuLink
                to="/dispute-resolution"
                titleKey="Dispute Resolutions"
              />
              <FooterMenuLink
                to="/aml-policy"
                titleKey="AML"
              />
              <FooterMenuLink
                to="/fairness-rng-testing"
                titleKey="Fairness & Testing"
              />
              <FooterMenuLink
                to="/privacy-policy"
                titleKey="Privacy"
              />
              <FooterMenuLink
                to="/accounts-payouts-bonus"
                titleKey="Account Payouts and Bonus"
              />
            </div>
          </div>
        </div>
      </div>
      <FooterSlogan />
      <FooterBottom />
    </footer>
  );
};

export default connect(null, {
  loginModal,
})(Footer);
