export default function LogoPokerColor() {
  return (
    <svg
      width="270"
      height="55"
      viewBox="0 0 270 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0055 33.9264C20.5379 33.9264 24.7097 24.3913 24.7097 16.0899C24.7097 11.6179 22.6816 10.2686 20.3583 10.2686C19.2328 10.2648 18.1261 10.5573 17.1492 11.1168C16.6358 11.3995 16.4176 11.5665 16.0838 11.5665C15.7501 11.5665 15.5703 11.3352 15.5703 10.9497C16.0305 9.13367 17.1823 7.57002 18.7794 6.59338C20.4484 5.37095 22.4757 4.73814 24.5429 4.79432C28.766 4.79432 31.8723 7.67282 31.8723 13.6611C31.8723 25.9719 23.2978 38.7839 12.9133 38.7839C5.58379 38.7839 0 34.2091 0 25.0081C0 7.8913 12.695 0.720703 21.6034 0.720703C25.159 0.720703 26.7379 1.51743 26.7379 2.36556C26.7379 2.69968 26.5068 2.93099 26.0062 2.93099C25.5056 2.93099 24.7226 2.81534 23.2978 2.81534C16.0196 2.81534 9.81973 10.5256 9.81973 24.2114C9.81973 30.9964 12.0789 33.9264 14.9542 33.9264"
        fill="#209BFF"
      />
      <path
        d="M15.0055 33.9264C20.5379 33.9264 24.7097 24.3913 24.7097 16.0899C24.7097 11.6179 22.6816 10.2686 20.3583 10.2686C19.2328 10.2648 18.1261 10.5573 17.1492 11.1168C16.6358 11.3995 16.4176 11.5665 16.0838 11.5665C15.7501 11.5665 15.5703 11.3352 15.5703 10.9497C16.0305 9.13367 17.1823 7.57002 18.7794 6.59338C20.4484 5.37095 22.4757 4.73814 24.5429 4.79432C28.766 4.79432 31.8723 7.67282 31.8723 13.6611C31.8723 25.9719 23.2978 38.7839 12.9133 38.7839C5.58379 38.7839 0 34.2091 0 25.0081C0 7.8913 12.695 0.720703 21.6034 0.720703C25.159 0.720703 26.7379 1.51743 26.7379 2.36556C26.7379 2.69968 26.5068 2.93099 26.0062 2.93099C25.5056 2.93099 24.7226 2.81534 23.2978 2.81534C16.0196 2.81534 9.81973 10.5256 9.81973 24.2114C9.81973 30.9964 12.0789 33.9264 14.9542 33.9264"
        fill="url(#paint0_linear_3_85595)"
      />
      <path
        d="M50.8186 32.6908C51.2165 32.2924 51.4476 32.1254 51.7813 32.1254C51.9892 32.1285 52.1878 32.2118 52.336 32.3577C52.4842 32.5037 52.5706 32.7012 52.5772 32.9093C52.4881 33.5994 52.1666 34.2386 51.6658 34.7212C50.8678 35.8154 49.8362 36.7176 48.646 37.3623C47.4558 38.007 46.137 38.3778 44.7856 38.4478C39.8821 38.4478 39.5484 30.9303 38.9836 30.9303C38.4131 32.9196 37.9588 34.9406 37.623 36.9828C37.623 37.9338 37.2892 38.1651 36.442 38.1651C34.7477 38.1651 32.4885 38.4478 31.59 38.4478C30.9096 38.4478 30.5117 38.1651 30.5117 37.1628C30.5117 32.2539 35.4793 20.3929 35.4793 11.9758C35.4793 10.1125 34.5166 9.21296 33.3357 9.21296C32.835 9.21296 32.4885 8.98165 32.4885 8.53189C32.4885 7.34964 35.4793 5.48633 38.7012 5.48633C41.6792 5.48633 43.4891 7.06693 43.4891 9.89403C43.4891 13.4536 39.4842 25.8158 39.4842 28.0775C40.3317 27.9839 41.1243 27.6114 41.7376 27.0184C42.3509 26.4253 42.7502 25.6453 42.873 24.8006C42.873 24.1709 42.4751 24.0681 42.4751 23.5156C42.4751 22.3205 45.9665 20.5214 48.5723 20.5214C49.689 20.5214 50.2025 20.9711 50.2025 21.8064C50.2025 24.1709 47.7764 27.2293 43.6046 29.5167C44.2208 31.4957 46.1719 33.8088 48.5081 33.8088C48.9568 33.7962 49.3971 33.6836 49.7968 33.4791C50.1965 33.2745 50.5456 32.9833 50.8186 32.6265"
        fill="#209BFF"
      />
      <path
        d="M50.8186 32.6908C51.2165 32.2924 51.4476 32.1254 51.7813 32.1254C51.9892 32.1285 52.1878 32.2118 52.336 32.3577C52.4842 32.5037 52.5706 32.7012 52.5772 32.9093C52.4881 33.5994 52.1666 34.2386 51.6658 34.7212C50.8678 35.8154 49.8362 36.7176 48.646 37.3623C47.4558 38.007 46.137 38.3778 44.7856 38.4478C39.8821 38.4478 39.5484 30.9303 38.9836 30.9303C38.4131 32.9196 37.9588 34.9406 37.623 36.9828C37.623 37.9338 37.2892 38.1651 36.442 38.1651C34.7477 38.1651 32.4885 38.4478 31.59 38.4478C30.9096 38.4478 30.5117 38.1651 30.5117 37.1628C30.5117 32.2539 35.4793 20.3929 35.4793 11.9758C35.4793 10.1125 34.5166 9.21296 33.3357 9.21296C32.835 9.21296 32.4885 8.98165 32.4885 8.53189C32.4885 7.34964 35.4793 5.48633 38.7012 5.48633C41.6792 5.48633 43.4891 7.06693 43.4891 9.89403C43.4891 13.4536 39.4842 25.8158 39.4842 28.0775C40.3317 27.9839 41.1243 27.6114 41.7376 27.0184C42.3509 26.4253 42.7502 25.6453 42.873 24.8006C42.873 24.1709 42.4751 24.0681 42.4751 23.5156C42.4751 22.3205 45.9665 20.5214 48.5723 20.5214C49.689 20.5214 50.2025 20.9711 50.2025 21.8064C50.2025 24.1709 47.7764 27.2293 43.6046 29.5167C44.2208 31.4957 46.1719 33.8088 48.5081 33.8088C48.9568 33.7962 49.3971 33.6836 49.7968 33.4791C50.1965 33.2745 50.5456 32.9833 50.8186 32.6265"
        fill="url(#paint1_linear_3_85595)"
      />
      <path
        d="M53.0313 22.6306C53.0313 21.3456 56.0735 20.5488 59.2826 20.5488C60.5662 20.5488 61.208 21.05 61.208 22.0138C61.208 24.4939 59.2312 28.6832 59.2312 32.1785C59.2064 32.3906 59.225 32.6055 59.2861 32.8101C59.3472 33.0147 59.4494 33.2046 59.5864 33.3682C59.7234 33.5319 59.8924 33.6658 60.0829 33.7617C60.2735 33.8576 60.4816 33.9136 60.6946 33.9262C61.4249 33.8196 62.1226 33.5523 62.7375 33.1435C63.3523 32.7346 63.8689 32.1944 64.2502 31.5617C65.3625 29.3557 65.9262 26.9134 65.8932 24.4425C65.8932 23.4787 65.4953 23.3631 65.4953 22.6306C65.4953 21.3456 68.5375 20.5488 71.7594 20.5488C73.043 20.5488 73.6719 21.05 73.6719 22.0138C73.6719 23.4273 73.0558 24.0441 71.477 33.3093C70.0008 41.9449 68.2037 54.4227 57.9861 54.4227C53.8785 54.4227 50.2844 52.0582 50.2844 48.1003C50.1808 46.6154 50.6217 45.1436 51.5244 43.9608C52.427 42.778 53.7295 41.9654 55.1878 41.675C55.5857 41.675 55.8039 41.8421 55.8039 42.1762C55.8039 42.7416 55.3033 43.0243 55.3033 44.2708C55.3033 45.9028 56.587 46.918 58.5124 46.918C62.6328 46.918 64.0961 40.4928 64.9305 35.0056C62.6328 37.227 59.5497 38.4468 56.3559 38.3981C55.7481 38.4472 55.1367 38.3719 54.5589 38.1768C53.9811 37.9817 53.4491 37.671 52.9951 37.2634C52.5412 36.8558 52.1749 36.36 51.9185 35.8062C51.6621 35.2523 51.521 34.652 51.5038 34.0418C51.5038 30.2638 53.3651 26.4215 53.3651 24.3911C53.3651 23.4273 52.98 23.3117 52.98 22.5792"
        fill="#209BFF"
      />
      <path
        d="M53.0313 22.6306C53.0313 21.3456 56.0735 20.5488 59.2826 20.5488C60.5662 20.5488 61.208 21.05 61.208 22.0138C61.208 24.4939 59.2312 28.6832 59.2312 32.1785C59.2064 32.3906 59.225 32.6055 59.2861 32.8101C59.3472 33.0147 59.4494 33.2046 59.5864 33.3682C59.7234 33.5319 59.8924 33.6658 60.0829 33.7617C60.2735 33.8576 60.4816 33.9136 60.6946 33.9262C61.4249 33.8196 62.1226 33.5523 62.7375 33.1435C63.3523 32.7346 63.8689 32.1944 64.2502 31.5617C65.3625 29.3557 65.9262 26.9134 65.8932 24.4425C65.8932 23.4787 65.4953 23.3631 65.4953 22.6306C65.4953 21.3456 68.5375 20.5488 71.7594 20.5488C73.043 20.5488 73.6719 21.05 73.6719 22.0138C73.6719 23.4273 73.0558 24.0441 71.477 33.3093C70.0008 41.9449 68.2037 54.4227 57.9861 54.4227C53.8785 54.4227 50.2844 52.0582 50.2844 48.1003C50.1808 46.6154 50.6217 45.1436 51.5244 43.9608C52.427 42.778 53.7295 41.9654 55.1878 41.675C55.5857 41.675 55.8039 41.8421 55.8039 42.1762C55.8039 42.7416 55.3033 43.0243 55.3033 44.2708C55.3033 45.9028 56.587 46.918 58.5124 46.918C62.6328 46.918 64.0961 40.4928 64.9305 35.0056C62.6328 37.227 59.5497 38.4468 56.3559 38.3981C55.7481 38.4472 55.1367 38.3719 54.5589 38.1768C53.9811 37.9817 53.4491 37.671 52.9951 37.2634C52.5412 36.8558 52.1749 36.36 51.9185 35.8062C51.6621 35.2523 51.521 34.652 51.5038 34.0418C51.5038 30.2638 53.3651 26.4215 53.3651 24.3911C53.3651 23.4273 52.98 23.3117 52.98 22.5792"
        fill="url(#paint2_linear_3_85595)"
      />
      <path
        d="M91.4321 38.4495C90.4874 38.4656 89.5613 38.1856 88.7833 37.6488C88.0054 37.1119 87.4146 36.345 87.0935 35.4554C85.1561 37.2713 82.6337 38.3333 79.9823 38.4495C76.6577 38.4495 73.9492 36.1878 73.9492 31.8444C73.9492 26.2544 77.338 20.5488 85.3991 20.5488C88.39 20.5488 89.25 21.6154 90.1357 21.6154C91.0214 21.6154 93.5629 20.5745 94.8466 20.5745C94.9618 20.5571 95.0796 20.5671 95.1903 20.6035C95.301 20.64 95.4016 20.702 95.484 20.7845C95.5665 20.867 95.6283 20.9677 95.6648 21.0786C95.7012 21.1894 95.7111 21.3073 95.6938 21.4227C95.6938 22.772 93.717 27.1797 93.717 31.5874C93.6813 31.8666 93.7033 32.1502 93.7816 32.4206C93.8599 32.691 93.9928 32.9424 94.1721 33.1592C94.3514 33.3761 94.5733 33.5538 94.824 33.6813C95.0747 33.8088 95.3489 33.8834 95.6296 33.9005C96.5923 33.9005 97.0416 33.4507 97.7732 32.7182C98.1711 32.3199 98.4022 32.1528 98.7359 32.1528C98.9426 32.1561 99.1398 32.2398 99.286 32.3861C99.4321 32.5324 99.5157 32.7298 99.519 32.9367C99.4354 33.6258 99.1182 34.2654 98.6204 34.7486C97.7865 35.8724 96.7085 36.7919 95.4677 37.4374C94.2268 38.083 92.8557 38.4379 91.4578 38.4752M85.9254 23.0546C82.7163 23.0546 81.3044 28.9916 81.3044 32.05C81.3044 33.5149 81.9847 34.71 83.2298 34.71C84.4749 34.71 85.4248 33.7463 86.1565 32.05C87.0394 29.6822 87.5336 27.1869 87.6198 24.661C87.6484 24.4336 87.6239 24.2026 87.5481 23.9864C87.4723 23.7701 87.3472 23.5745 87.1829 23.415C87.0185 23.2554 86.8194 23.1363 86.6011 23.0671C86.3829 22.9979 86.1515 22.9804 85.9254 23.0161"
        fill="#209BFF"
      />
      <path
        d="M91.4321 38.4495C90.4874 38.4656 89.5613 38.1856 88.7833 37.6488C88.0054 37.1119 87.4146 36.345 87.0935 35.4554C85.1561 37.2713 82.6337 38.3333 79.9823 38.4495C76.6577 38.4495 73.9492 36.1878 73.9492 31.8444C73.9492 26.2544 77.338 20.5488 85.3991 20.5488C88.39 20.5488 89.25 21.6154 90.1357 21.6154C91.0214 21.6154 93.5629 20.5745 94.8466 20.5745C94.9618 20.5571 95.0796 20.5671 95.1903 20.6035C95.301 20.64 95.4016 20.702 95.484 20.7845C95.5665 20.867 95.6283 20.9677 95.6648 21.0786C95.7012 21.1894 95.7111 21.3073 95.6938 21.4227C95.6938 22.772 93.717 27.1797 93.717 31.5874C93.6813 31.8666 93.7033 32.1502 93.7816 32.4206C93.8599 32.691 93.9928 32.9424 94.1721 33.1592C94.3514 33.3761 94.5733 33.5538 94.824 33.6813C95.0747 33.8088 95.3489 33.8834 95.6296 33.9005C96.5923 33.9005 97.0416 33.4507 97.7732 32.7182C98.1711 32.3199 98.4022 32.1528 98.7359 32.1528C98.9426 32.1561 99.1398 32.2398 99.286 32.3861C99.4321 32.5324 99.5157 32.7298 99.519 32.9367C99.4354 33.6258 99.1182 34.2654 98.6204 34.7486C97.7865 35.8724 96.7085 36.7919 95.4677 37.4374C94.2268 38.083 92.8557 38.4379 91.4578 38.4752M85.9254 23.0546C82.7163 23.0546 81.3044 28.9916 81.3044 32.05C81.3044 33.5149 81.9847 34.71 83.2298 34.71C84.4749 34.71 85.4248 33.7463 86.1565 32.05C87.0394 29.6822 87.5336 27.1869 87.6198 24.661C87.6484 24.4336 87.6239 24.2026 87.5481 23.9864C87.4723 23.7701 87.3472 23.5745 87.1829 23.415C87.0185 23.2554 86.8194 23.1363 86.6011 23.0671C86.3829 22.9979 86.1515 22.9804 85.9254 23.0161"
        fill="url(#paint3_linear_3_85595)"
      />
      <path
        d="M110.16 34.0946C110.16 30.6507 111.957 28.4019 111.957 26.1916C111.957 25.2921 111.457 24.611 110.674 24.611C109.891 24.611 108.363 25.5748 107.131 28.0549C105.845 30.8766 105.026 33.8886 104.705 36.9732C104.705 37.937 104.307 38.1683 103.421 38.1683C101.727 38.1683 99.5705 38.451 98.6848 38.451C97.7991 38.451 97.5039 38.1683 97.5039 37.1659C97.5039 35.0713 99.8658 27.451 99.8658 24.4054C99.8658 23.4416 99.4678 23.326 99.4678 22.5935C99.4678 21.3084 102.523 20.5117 105.796 20.5117C107.08 20.5117 107.709 21.0129 107.709 21.9767C107.643 22.846 107.493 23.7069 107.259 24.5468C108.273 23.2617 111.598 20.5374 114.961 20.5374C118.068 20.5374 119.313 21.9509 119.313 24.097C119.313 26.6671 117.734 29.507 117.734 31.8073C117.734 33.2722 118.517 33.8377 119.531 33.8377C120.545 33.8377 120.943 33.3879 121.674 32.6554C122.072 32.257 122.303 32.09 122.637 32.09C122.844 32.0933 123.041 32.1769 123.187 32.3232C123.333 32.4695 123.417 32.667 123.42 32.8739C123.337 33.563 123.019 34.2026 122.522 34.6858C121.688 35.8096 120.61 36.729 119.369 37.3746C118.128 38.0202 116.757 38.3751 115.359 38.4124C112.15 38.4124 110.225 36.4848 110.225 34.0561"
        fill="#209BFF"
      />
      <path
        d="M110.16 34.0946C110.16 30.6507 111.957 28.4019 111.957 26.1916C111.957 25.2921 111.457 24.611 110.674 24.611C109.891 24.611 108.363 25.5748 107.131 28.0549C105.845 30.8766 105.026 33.8886 104.705 36.9732C104.705 37.937 104.307 38.1683 103.421 38.1683C101.727 38.1683 99.5705 38.451 98.6848 38.451C97.7991 38.451 97.5039 38.1683 97.5039 37.1659C97.5039 35.0713 99.8658 27.451 99.8658 24.4054C99.8658 23.4416 99.4678 23.326 99.4678 22.5935C99.4678 21.3084 102.523 20.5117 105.796 20.5117C107.08 20.5117 107.709 21.0129 107.709 21.9767C107.643 22.846 107.493 23.7069 107.259 24.5468C108.273 23.2617 111.598 20.5374 114.961 20.5374C118.068 20.5374 119.313 21.9509 119.313 24.097C119.313 26.6671 117.734 29.507 117.734 31.8073C117.734 33.2722 118.517 33.8377 119.531 33.8377C120.545 33.8377 120.943 33.3879 121.674 32.6554C122.072 32.257 122.303 32.09 122.637 32.09C122.844 32.0933 123.041 32.1769 123.187 32.3232C123.333 32.4695 123.417 32.667 123.42 32.8739C123.337 33.563 123.019 34.2026 122.522 34.6858C121.688 35.8096 120.61 36.729 119.369 37.3746C118.128 38.0202 116.757 38.3751 115.359 38.4124C112.15 38.4124 110.225 36.4848 110.225 34.0561"
        fill="url(#paint4_linear_3_85595)"
      />
      <path
        d="M134.642 35.0056C132.314 37.1451 129.291 38.3685 126.132 38.4495C125.547 38.4799 124.963 38.3889 124.416 38.1821C123.869 37.9753 123.37 37.6572 122.952 37.2479C122.533 36.8387 122.204 36.3471 121.985 35.8041C121.765 35.2612 121.661 34.6786 121.678 34.0932C121.678 30.5979 123.59 26.4729 123.59 24.4425C123.59 23.4787 123.192 23.3631 123.192 22.6306C123.192 21.3456 126.247 20.5488 129.508 20.5488C130.791 20.5488 131.433 21.05 131.433 22.0138C131.433 24.5839 129.341 28.439 129.341 32.5126C129.322 32.6985 129.343 32.8864 129.404 33.063C129.465 33.2397 129.564 33.401 129.693 33.5355C129.823 33.6701 129.98 33.7748 130.154 33.8423C130.328 33.9097 130.515 33.9384 130.701 33.9262C131.414 33.8585 132.097 33.6108 132.688 33.2062C133.278 32.8016 133.756 32.2534 134.077 31.6131C135.293 29.413 135.971 26.9556 136.054 24.4425C136.054 23.4787 135.656 23.3631 135.656 22.6306C135.656 21.3456 138.711 20.5488 141.984 20.5488C143.268 20.5488 143.897 21.05 143.897 22.0138C143.897 24.2112 141.92 28.5675 141.92 31.5617C141.92 33.0266 142.716 33.8748 143.73 33.8748C144.744 33.8748 145.142 33.425 145.874 32.6925C146.272 32.2941 146.49 32.1271 146.836 32.1271C147.043 32.1304 147.24 32.2141 147.387 32.3603C147.533 32.5066 147.616 32.7041 147.619 32.911C147.536 33.6001 147.219 34.2397 146.721 34.7229C145.897 35.8449 144.828 36.7638 143.596 37.4096C142.364 38.0554 141 38.4111 139.61 38.4495C135.156 38.4495 134.642 35.0056 134.642 35.0056Z"
        fill="#209BFF"
      />
      <path
        d="M134.642 35.0056C132.314 37.1451 129.291 38.3685 126.132 38.4495C125.547 38.4799 124.963 38.3889 124.416 38.1821C123.869 37.9753 123.37 37.6572 122.952 37.2479C122.533 36.8387 122.204 36.3471 121.985 35.8041C121.765 35.2612 121.661 34.6786 121.678 34.0932C121.678 30.5979 123.59 26.4729 123.59 24.4425C123.59 23.4787 123.192 23.3631 123.192 22.6306C123.192 21.3456 126.247 20.5488 129.508 20.5488C130.791 20.5488 131.433 21.05 131.433 22.0138C131.433 24.5839 129.341 28.439 129.341 32.5126C129.322 32.6985 129.343 32.8864 129.404 33.063C129.465 33.2397 129.564 33.401 129.693 33.5355C129.823 33.6701 129.98 33.7748 130.154 33.8423C130.328 33.9097 130.515 33.9384 130.701 33.9262C131.414 33.8585 132.097 33.6108 132.688 33.2062C133.278 32.8016 133.756 32.2534 134.077 31.6131C135.293 29.413 135.971 26.9556 136.054 24.4425C136.054 23.4787 135.656 23.3631 135.656 22.6306C135.656 21.3456 138.711 20.5488 141.984 20.5488C143.268 20.5488 143.897 21.05 143.897 22.0138C143.897 24.2112 141.92 28.5675 141.92 31.5617C141.92 33.0266 142.716 33.8748 143.73 33.8748C144.744 33.8748 145.142 33.425 145.874 32.6925C146.272 32.2941 146.49 32.1271 146.836 32.1271C147.043 32.1304 147.24 32.2141 147.387 32.3603C147.533 32.5066 147.616 32.7041 147.619 32.911C147.536 33.6001 147.219 34.2397 146.721 34.7229C145.897 35.8449 144.828 36.7638 143.596 37.4096C142.364 38.0554 141 38.4111 139.61 38.4495C135.156 38.4495 134.642 35.0056 134.642 35.0056Z"
        fill="url(#paint5_linear_3_85595)"
      />
      <path
        d="M150.424 32.8571C150.424 34.2578 150.938 35.2216 151.952 35.2216C152.735 35.2216 153.235 34.7205 153.235 33.7053C153.235 30.3641 147.652 29.8501 147.652 25.6223C147.652 22.0627 151.194 20.2637 155.43 20.2637C159.666 20.2637 161.412 21.8443 161.412 24.1188C161.457 24.489 161.421 24.8645 161.306 25.2194C161.192 25.5743 161.002 25.8999 160.749 26.1738C160.496 26.4477 160.187 26.6632 159.842 26.8054C159.498 26.9476 159.127 27.0131 158.755 26.9973C158.235 27.0045 157.732 26.8087 157.353 26.4513C156.975 26.0939 156.75 25.603 156.727 25.0826C156.727 24.2345 157.176 23.8875 157.176 23.3863C157.176 22.8852 156.727 22.5896 156.111 22.5896C155.656 22.6434 155.237 22.8632 154.933 23.207C154.63 23.5507 154.464 23.9943 154.468 24.4529C154.468 27.794 160.68 28.2438 160.68 32.356C160.68 36.0312 157.741 38.5113 152.773 38.5113C148.55 38.5113 145.444 36.648 145.444 33.6025C145.419 33.2438 145.467 32.8839 145.583 32.5439C145.7 32.2039 145.883 31.8906 146.122 31.6225C146.361 31.3543 146.652 31.1367 146.976 30.9824C147.3 30.8281 147.652 30.7402 148.011 30.7239C149.538 30.7239 150.437 31.4564 150.437 32.8186"
        fill="#209BFF"
      />
      <path
        d="M150.424 32.8571C150.424 34.2578 150.938 35.2216 151.952 35.2216C152.735 35.2216 153.235 34.7205 153.235 33.7053C153.235 30.3641 147.652 29.8501 147.652 25.6223C147.652 22.0627 151.194 20.2637 155.43 20.2637C159.666 20.2637 161.412 21.8443 161.412 24.1188C161.457 24.489 161.421 24.8645 161.306 25.2194C161.192 25.5743 161.002 25.8999 160.749 26.1738C160.496 26.4477 160.187 26.6632 159.842 26.8054C159.498 26.9476 159.127 27.0131 158.755 26.9973C158.235 27.0045 157.732 26.8087 157.353 26.4513C156.975 26.0939 156.75 25.603 156.727 25.0826C156.727 24.2345 157.176 23.8875 157.176 23.3863C157.176 22.8852 156.727 22.5896 156.111 22.5896C155.656 22.6434 155.237 22.8632 154.933 23.207C154.63 23.5507 154.464 23.9943 154.468 24.4529C154.468 27.794 160.68 28.2438 160.68 32.356C160.68 36.0312 157.741 38.5113 152.773 38.5113C148.55 38.5113 145.444 36.648 145.444 33.6025C145.419 33.2438 145.467 32.8839 145.583 32.5439C145.7 32.2039 145.883 31.8906 146.122 31.6225C146.361 31.3543 146.652 31.1367 146.976 30.9824C147.3 30.8281 147.652 30.7402 148.011 30.7239C149.538 30.7239 150.437 31.4564 150.437 32.8186"
        fill="url(#paint6_linear_3_85595)"
      />
      <path
        d="M212.69 40.5712C211.748 40.5844 210.824 40.3048 210.047 39.7709C209.27 39.2369 208.678 38.475 208.351 37.5899C206.411 39.3992 203.889 40.4563 201.24 40.5712C197.915 40.5712 195.207 38.3223 195.207 33.966C195.207 28.3761 198.596 22.6704 206.657 22.6704C209.648 22.6704 210.508 23.7499 211.393 23.7499C212.279 23.7499 214.782 22.6704 216.079 22.6704C216.194 22.653 216.312 22.663 216.422 22.6995C216.533 22.7359 216.634 22.7979 216.716 22.8804C216.799 22.963 216.861 23.0637 216.897 23.1745C216.933 23.2854 216.943 23.4032 216.926 23.5186C216.926 24.8807 214.949 29.2884 214.949 33.6833C214.912 33.9646 214.932 34.2505 215.011 34.5233C215.089 34.796 215.223 35.0496 215.403 35.2681C215.584 35.4866 215.808 35.6652 216.062 35.7928C216.315 35.9203 216.592 35.994 216.875 36.0092C217.824 36.0092 218.274 35.5466 219.018 34.8142C219.403 34.4286 219.634 34.2487 219.968 34.2487C220.178 34.252 220.379 34.3371 220.527 34.4858C220.676 34.6345 220.761 34.8352 220.764 35.0455C220.764 35.6623 220.417 36.1121 219.853 36.8446C219.021 37.9705 217.943 38.8914 216.702 39.5372C215.461 40.183 214.088 40.5367 212.69 40.5712ZM207.17 25.1506C203.949 25.1506 202.536 31.0747 202.536 34.1459C202.536 35.6109 203.217 36.7931 204.462 36.7931C205.707 36.7931 206.657 35.8422 207.389 34.1459C208.277 31.7749 208.776 29.2751 208.865 26.7441C208.891 26.5177 208.865 26.2882 208.788 26.0737C208.711 25.8591 208.586 25.6652 208.422 25.5071C208.258 25.3491 208.06 25.2312 207.843 25.1627C207.625 25.0941 207.395 25.0768 207.17 25.1121"
        fill="#209BFF"
      />
      <path
        d="M212.69 40.5712C211.748 40.5844 210.824 40.3048 210.047 39.7709C209.27 39.2369 208.678 38.475 208.351 37.5899C206.411 39.3992 203.889 40.4563 201.24 40.5712C197.915 40.5712 195.207 38.3223 195.207 33.966C195.207 28.3761 198.596 22.6704 206.657 22.6704C209.648 22.6704 210.508 23.7499 211.393 23.7499C212.279 23.7499 214.782 22.6704 216.079 22.6704C216.194 22.653 216.312 22.663 216.422 22.6995C216.533 22.7359 216.634 22.7979 216.716 22.8804C216.799 22.963 216.861 23.0637 216.897 23.1745C216.933 23.2854 216.943 23.4032 216.926 23.5186C216.926 24.8807 214.949 29.2884 214.949 33.6833C214.912 33.9646 214.932 34.2505 215.011 34.5233C215.089 34.796 215.223 35.0496 215.403 35.2681C215.584 35.4866 215.808 35.6652 216.062 35.7928C216.315 35.9203 216.592 35.994 216.875 36.0092C217.824 36.0092 218.274 35.5466 219.018 34.8142C219.403 34.4286 219.634 34.2487 219.968 34.2487C220.178 34.252 220.379 34.3371 220.527 34.4858C220.676 34.6345 220.761 34.8352 220.764 35.0455C220.764 35.6623 220.417 36.1121 219.853 36.8446C219.021 37.9705 217.943 38.8914 216.702 39.5372C215.461 40.183 214.088 40.5367 212.69 40.5712ZM207.17 25.1506C203.949 25.1506 202.536 31.0747 202.536 34.1459C202.536 35.6109 203.217 36.7931 204.462 36.7931C205.707 36.7931 206.657 35.8422 207.389 34.1459C208.277 31.7749 208.776 29.2751 208.865 26.7441C208.891 26.5177 208.865 26.2882 208.788 26.0737C208.711 25.8591 208.586 25.6652 208.422 25.5071C208.258 25.3491 208.06 25.2312 207.843 25.1627C207.625 25.0941 207.395 25.0768 207.17 25.1121"
        fill="url(#paint7_linear_3_85595)"
      />
      <path
        d="M231.127 36.2254C231.127 33.1799 232.937 30.2371 232.937 28.0911C232.946 27.9103 232.917 27.7296 232.852 27.5608C232.787 27.3919 232.687 27.2388 232.558 27.1114C232.43 26.984 232.276 26.8853 232.107 26.8216C231.937 26.7579 231.757 26.7307 231.576 26.7418C230.395 26.7418 229.15 28.0268 228.033 30.2371C226.881 33.0837 226.083 36.0618 225.659 39.1039C225.659 40.0677 225.261 40.299 224.478 40.299C222.719 40.299 220.473 40.5689 219.561 40.5689C218.894 40.5689 218.496 40.299 218.496 39.2839C218.496 34.4778 223.464 21.9999 223.464 14.0969C223.464 12.2336 222.501 11.3341 221.32 11.3341C220.807 11.3341 220.473 11.1027 220.473 10.653C220.473 9.47074 223.464 7.60742 226.673 7.60742C229.664 7.60742 231.474 9.18801 231.474 12.0023C231.474 15.1763 228.983 23.6448 228.2 26.1378C229.112 25.0897 230.227 24.2391 231.479 23.638C232.73 23.037 234.091 22.6982 235.478 22.6425C238.572 22.6425 240.266 24.0432 240.266 26.4976C240.266 29.3247 238.572 31.5222 238.572 34.0666C238.542 34.3126 238.566 34.5622 238.642 34.7982C238.717 35.0343 238.843 35.2512 239.01 35.4342C239.177 35.6172 239.381 35.762 239.609 35.8586C239.837 35.9552 240.083 36.0015 240.331 35.9941C241.28 35.9941 241.73 35.5315 242.461 34.799C242.859 34.4135 243.09 34.2336 243.424 34.2336C243.634 34.2369 243.835 34.3219 243.983 34.4706C244.132 34.6193 244.217 34.8201 244.22 35.0303C244.22 35.6472 243.873 36.0969 243.308 36.8423C242.474 37.9644 241.395 38.8819 240.155 39.5253C238.914 40.1687 237.543 40.5211 236.146 40.556C232.706 40.556 231.127 38.8084 231.127 36.2126"
        fill="#209BFF"
      />
      <path
        d="M231.127 36.2254C231.127 33.1799 232.937 30.2371 232.937 28.0911C232.946 27.9103 232.917 27.7296 232.852 27.5608C232.787 27.3919 232.687 27.2388 232.558 27.1114C232.43 26.984 232.276 26.8853 232.107 26.8216C231.937 26.7579 231.757 26.7307 231.576 26.7418C230.395 26.7418 229.15 28.0268 228.033 30.2371C226.881 33.0837 226.083 36.0618 225.659 39.1039C225.659 40.0677 225.261 40.299 224.478 40.299C222.719 40.299 220.473 40.5689 219.561 40.5689C218.894 40.5689 218.496 40.299 218.496 39.2839C218.496 34.4778 223.464 21.9999 223.464 14.0969C223.464 12.2336 222.501 11.3341 221.32 11.3341C220.807 11.3341 220.473 11.1027 220.473 10.653C220.473 9.47074 223.464 7.60742 226.673 7.60742C229.664 7.60742 231.474 9.18801 231.474 12.0023C231.474 15.1763 228.983 23.6448 228.2 26.1378C229.112 25.0897 230.227 24.2391 231.479 23.638C232.73 23.037 234.091 22.6982 235.478 22.6425C238.572 22.6425 240.266 24.0432 240.266 26.4976C240.266 29.3247 238.572 31.5222 238.572 34.0666C238.542 34.3126 238.566 34.5622 238.642 34.7982C238.717 35.0343 238.843 35.2512 239.01 35.4342C239.177 35.6172 239.381 35.762 239.609 35.8586C239.837 35.9552 240.083 36.0015 240.331 35.9941C241.28 35.9941 241.73 35.5315 242.461 34.799C242.859 34.4135 243.09 34.2336 243.424 34.2336C243.634 34.2369 243.835 34.3219 243.983 34.4706C244.132 34.6193 244.217 34.8201 244.22 35.0303C244.22 35.6472 243.873 36.0969 243.308 36.8423C242.474 37.9644 241.395 38.8819 240.155 39.5253C238.914 40.1687 237.543 40.5211 236.146 40.556C232.706 40.556 231.127 38.8084 231.127 36.2126"
        fill="url(#paint8_linear_3_85595)"
      />
      <path
        d="M242.531 35.8921C242.531 32.2811 244.393 28.6059 244.393 26.5755C244.393 25.6117 244.046 25.4961 244.046 24.7636C244.046 23.4785 247.037 22.6818 250.31 22.6818C251.594 22.6818 252.235 23.183 252.235 24.1468C252.235 26.4085 250.426 29.9552 250.426 33.6818C250.426 35.1596 251.273 36.0078 252.287 36.0078C253.301 36.0078 253.699 35.5452 254.43 34.8127C254.828 34.4272 255.047 34.2473 255.393 34.2473C255.601 34.2538 255.798 34.3403 255.944 34.4886C256.09 34.637 256.173 34.8359 256.176 35.044C256.176 35.6608 255.842 36.1106 255.278 36.8559C254.444 37.9797 253.366 38.8992 252.125 39.5447C250.884 40.1903 249.513 40.5452 248.115 40.5825C244.393 40.5825 242.531 38.4879 242.531 35.8921ZM250.028 12.0031C250.438 11.9643 250.851 12.0137 251.241 12.1481C251.63 12.2825 251.986 12.4988 252.286 12.7824C252.585 13.066 252.82 13.4103 252.975 13.7923C253.13 14.1743 253.202 14.5852 253.185 14.9973C253.185 17.2589 251.157 19.6877 249.014 19.6877C248.606 19.6945 248.2 19.6208 247.821 19.4707C247.441 19.3207 247.095 19.0973 246.802 18.8133C246.509 18.5293 246.274 18.1902 246.112 17.8155C245.95 17.4407 245.863 17.0377 245.856 16.6293C245.878 15.4845 246.313 14.3863 247.079 13.5364C247.845 12.6866 248.892 12.1421 250.028 12.0031Z"
        fill="#209BFF"
      />
      <path
        d="M242.531 35.8921C242.531 32.2811 244.393 28.6059 244.393 26.5755C244.393 25.6117 244.046 25.4961 244.046 24.7636C244.046 23.4785 247.037 22.6818 250.31 22.6818C251.594 22.6818 252.235 23.183 252.235 24.1468C252.235 26.4085 250.426 29.9552 250.426 33.6818C250.426 35.1596 251.273 36.0078 252.287 36.0078C253.301 36.0078 253.699 35.5452 254.43 34.8127C254.828 34.4272 255.047 34.2473 255.393 34.2473C255.601 34.2538 255.798 34.3403 255.944 34.4886C256.09 34.637 256.173 34.8359 256.176 35.044C256.176 35.6608 255.842 36.1106 255.278 36.8559C254.444 37.9797 253.366 38.8992 252.125 39.5447C250.884 40.1903 249.513 40.5452 248.115 40.5825C244.393 40.5825 242.531 38.4879 242.531 35.8921ZM250.028 12.0031C250.438 11.9643 250.851 12.0137 251.241 12.1481C251.63 12.2825 251.986 12.4988 252.286 12.7824C252.585 13.066 252.82 13.4103 252.975 13.7923C253.13 14.1743 253.202 14.5852 253.185 14.9973C253.185 17.2589 251.157 19.6877 249.014 19.6877C248.606 19.6945 248.2 19.6208 247.821 19.4707C247.441 19.3207 247.095 19.0973 246.802 18.8133C246.509 18.5293 246.274 18.1902 246.112 17.8155C245.95 17.4407 245.863 17.0377 245.856 16.6293C245.878 15.4845 246.313 14.3863 247.079 13.5364C247.845 12.6866 248.892 12.1421 250.028 12.0031Z"
        fill="url(#paint9_linear_3_85595)"
      />
      <path
        d="M258.378 34.981C258.378 36.3946 258.879 37.3584 259.893 37.3584C260.689 37.3584 261.177 36.8443 261.177 35.8292C261.177 32.5009 255.593 31.974 255.593 27.7591C255.593 24.1995 259.149 22.4004 263.372 22.4004C267.595 22.4004 269.353 23.981 269.353 26.2555C269.398 26.6257 269.362 27.0013 269.248 27.3561C269.133 27.711 268.943 28.0367 268.69 28.3106C268.438 28.5844 268.128 28.7999 267.784 28.9421C267.44 29.0843 267.068 29.1499 266.696 29.1341C266.174 29.1412 265.669 28.9435 265.29 28.5834C264.911 28.2233 264.688 27.7292 264.668 27.2065C264.668 26.3712 265.117 26.0242 265.117 25.523C265.117 25.0219 264.668 24.7263 264.052 24.7263C263.598 24.7803 263.181 25.0006 262.88 25.3447C262.578 25.6887 262.415 26.1323 262.422 26.5897C262.422 29.9179 268.622 30.3677 268.622 34.4927C268.622 38.1679 265.682 40.6481 260.727 40.6481C256.491 40.6481 253.385 38.7848 253.385 35.7392C253.361 35.3805 253.408 35.0206 253.525 34.6806C253.641 34.3406 253.825 34.0273 254.064 33.7592C254.303 33.491 254.593 33.2734 254.917 33.1191C255.242 32.9648 255.594 32.877 255.952 32.8607C257.467 32.8607 258.378 33.5932 258.378 34.9425"
        fill="#209BFF"
      />
      <path
        d="M258.378 34.981C258.378 36.3946 258.879 37.3584 259.893 37.3584C260.689 37.3584 261.177 36.8443 261.177 35.8292C261.177 32.5009 255.593 31.974 255.593 27.7591C255.593 24.1995 259.149 22.4004 263.372 22.4004C267.595 22.4004 269.353 23.981 269.353 26.2555C269.398 26.6257 269.362 27.0013 269.248 27.3561C269.133 27.711 268.943 28.0367 268.69 28.3106C268.438 28.5844 268.128 28.7999 267.784 28.9421C267.44 29.0843 267.068 29.1499 266.696 29.1341C266.174 29.1412 265.669 28.9435 265.29 28.5834C264.911 28.2233 264.688 27.7292 264.668 27.2065C264.668 26.3712 265.117 26.0242 265.117 25.523C265.117 25.0219 264.668 24.7263 264.052 24.7263C263.598 24.7803 263.181 25.0006 262.88 25.3447C262.578 25.6887 262.415 26.1323 262.422 26.5897C262.422 29.9179 268.622 30.3677 268.622 34.4927C268.622 38.1679 265.682 40.6481 260.727 40.6481C256.491 40.6481 253.385 38.7848 253.385 35.7392C253.361 35.3805 253.408 35.0206 253.525 34.6806C253.641 34.3406 253.825 34.0273 254.064 33.7592C254.303 33.491 254.593 33.2734 254.917 33.1191C255.242 32.9648 255.594 32.877 255.952 32.8607C257.467 32.8607 258.378 33.5932 258.378 34.9425"
        fill="url(#paint10_linear_3_85595)"
      />
      <path
        d="M185.431 4.63062C190.565 4.51497 195.186 7.13645 195.186 11.8783C195.186 17.0185 190.938 20.2054 186.484 20.7451C189.526 21.015 194.262 24.6003 194.262 29.1365C194.262 37.9005 187.51 40.4578 182.081 40.4578C178.166 40.4578 175.496 38.4916 174.738 38.4916C173.455 38.4916 170.708 40.4578 169.193 40.4578C168.641 40.4578 168.32 40.1879 168.32 39.751C168.32 39.0442 169.129 38.7101 170.117 36.05C170.977 33.647 173.596 18.6762 175.111 12.8035C175.686 10.5003 177.004 8.45124 178.861 6.97374C180.717 5.49623 183.008 4.6727 185.38 4.63062M180.617 21.7217C180.617 21.7217 178.384 33.5956 178.384 35.0606C178.37 35.3836 178.422 35.7061 178.535 36.0088C178.649 36.3114 178.822 36.588 179.045 36.822C179.268 37.056 179.536 37.2425 179.832 37.3704C180.129 37.4983 180.448 37.565 180.771 37.5664C183.005 37.5664 186.098 34.7393 186.098 27.119C186.137 26.3924 186.02 25.6661 185.757 24.9879C185.494 24.3098 185.09 23.6953 184.572 23.1852C184.053 22.675 183.433 22.2807 182.751 22.0285C182.07 21.7762 181.342 21.6716 180.617 21.7217ZM187.947 10.7346C187.947 8.55002 184.558 8.37011 183.39 10.529C182.239 13.2227 181.541 16.0885 181.323 19.0103C185.174 19.0103 187.947 14.7568 187.947 10.7346Z"
        fill="#209BFF"
      />
      <path
        d="M185.431 4.63062C190.565 4.51497 195.186 7.13645 195.186 11.8783C195.186 17.0185 190.938 20.2054 186.484 20.7451C189.526 21.015 194.262 24.6003 194.262 29.1365C194.262 37.9005 187.51 40.4578 182.081 40.4578C178.166 40.4578 175.496 38.4916 174.738 38.4916C173.455 38.4916 170.708 40.4578 169.193 40.4578C168.641 40.4578 168.32 40.1879 168.32 39.751C168.32 39.0442 169.129 38.7101 170.117 36.05C170.977 33.647 173.596 18.6762 175.111 12.8035C175.686 10.5003 177.004 8.45124 178.861 6.97374C180.717 5.49623 183.008 4.6727 185.38 4.63062M180.617 21.7217C180.617 21.7217 178.384 33.5956 178.384 35.0606C178.37 35.3836 178.422 35.7061 178.535 36.0088C178.649 36.3114 178.822 36.588 179.045 36.822C179.268 37.056 179.536 37.2425 179.832 37.3704C180.129 37.4983 180.448 37.565 180.771 37.5664C183.005 37.5664 186.098 34.7393 186.098 27.119C186.137 26.3924 186.02 25.6661 185.757 24.9879C185.494 24.3098 185.09 23.6953 184.572 23.1852C184.053 22.675 183.433 22.2807 182.751 22.0285C182.07 21.7762 181.342 21.6716 180.617 21.7217ZM187.947 10.7346C187.947 8.55002 184.558 8.37011 183.39 10.529C182.239 13.2227 181.541 16.0885 181.323 19.0103C185.174 19.0103 187.947 14.7568 187.947 10.7346Z"
        fill="url(#paint11_linear_3_85595)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3_85595"
          x1="16.4674"
          y1="8.6504"
          x2="14.8272"
          y2="39.6356"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#53B1FD" />
          <stop
            offset="1"
            stopColor="#53B1FD"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3_85595"
          x1="41.9122"
          y1="12.3532"
          x2="40.1384"
          y2="39.1431"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#53B1FD" />
          <stop
            offset="1"
            stopColor="#53B1FD"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3_85595"
          x1="64.5211"
          y1="25.1517"
          x2="60.4613"
          y2="55.0137"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#53B1FD" />
          <stop
            offset="1"
            stopColor="#53B1FD"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3_85595"
          x1="87.1603"
          y1="24.2834"
          x2="86.706"
          y2="38.9031"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#53B1FD" />
          <stop
            offset="1"
            stopColor="#53B1FD"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3_85595"
          x1="110.894"
          y1="24.249"
          x2="110.445"
          y2="38.8796"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#53B1FD" />
          <stop
            offset="1"
            stopColor="#53B1FD"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3_85595"
          x1="135.08"
          y1="24.2793"
          x2="134.633"
          y2="38.8831"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#53B1FD" />
          <stop
            offset="1"
            stopColor="#53B1FD"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3_85595"
          x1="153.701"
          y1="24.0652"
          x2="152.949"
          y2="38.9233"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#53B1FD" />
          <stop
            offset="1"
            stopColor="#53B1FD"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3_85595"
          x1="208.411"
          y1="26.3932"
          x2="207.958"
          y2="40.9992"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#53B1FD" />
          <stop
            offset="1"
            stopColor="#53B1FD"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint8_linear_3_85595"
          x1="231.787"
          y1="14.4743"
          x2="230.263"
          y2="41.2952"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#53B1FD" />
          <stop
            offset="1"
            stopColor="#53B1FD"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint9_linear_3_85595"
          x1="249.581"
          y1="17.9469"
          x2="247.432"
          y2="41.0879"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#53B1FD" />
          <stop
            offset="1"
            stopColor="#53B1FD"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint10_linear_3_85595"
          x1="261.642"
          y1="26.2019"
          x2="260.891"
          y2="41.06"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#53B1FD" />
          <stop
            offset="1"
            stopColor="#53B1FD"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint11_linear_3_85595"
          x1="182.201"
          y1="12.0916"
          x2="180.478"
          y2="41.2394"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#53B1FD" />
          <stop
            offset="1"
            stopColor="#53B1FD"
            stopOpacity="0"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
