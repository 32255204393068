import { Layout } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "../App.css";
import "../assets/css/custom.scss";
import FooterComponent from "../Components/Footer/Footer";
import HeaderComponent from "../Components/Navbar/Navbar";
// import { getItemFromLocalStorage } from "../utils/localStorage";
import { SidebarMenuItemsWithRouter } from "./SidebarMenuWithRouter";
import LayoutComponentStyles from "./style";
import "./style.scss";
import LandingPage from "../pages/LandingPage";
import { Helmet } from "react-helmet";
const { Content } = Layout;

class OPLayout extends React.Component {
  state = {
    // collapsed: false,
    // contentMargin: 253,
  };

  renderPageHeader = () => {
    const { history } = this.props; //?undefined kris
    return <HeaderComponent history={history} />;
  };

  renderContent = () => {
    const { children } = this.props;
    return (
      <>
        <div id="main-content">
          <div>
					<Content>{children}</Content>
					</div>
        </div>
      </>
    );
  };



  render() {
    return (
      <>
			 <Helmet>
					<title>{`okyanusBahis.com ${this?.props?.pageTitle ? `| ${this?.props?.pageTitle}` : ""}`}</title>
					<meta name="description" content="Join today for your chance to win real money"/>
				</Helmet>
				{this.props.isLandingPage ?
					<LandingPage />
					:
					<Layout style={LayoutComponentStyles.parentLayout} className="layout">
						<SidebarMenuItemsWithRouter />
						<div className="layout-content">
							{this.renderPageHeader()}
							{this.renderContent()}
							<FooterComponent />
						</div>
					</Layout>
				}
      </>
    );
  }
}

export default OPLayout;
