import { Modal } from "antd";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItemFromLocalStorage } from "../../../../../utils/localStorage";
import CasherNav from "../../../context/context";
import { deposit, depositLoading, depositSuccess } from "../../../Services/actions";
import { Images } from "../../../../../Images";
import { useTranslation } from "react-i18next";
import { apiVars } from "../../../../../api";
import DepositFormHeader from "./components/DepositFormHeader";
import { useForm, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import Select from "react-select";
import { styles } from "./components/selectStyles";
import { fetchAccountTypes, fetchBankNames, fetchResponseUrl, saveActivityDepositForm } from "./api";

const DepositForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();

  const { selectedCurrency, selectedPaymentMethod } = useContext(CasherNav);
  let { CurrencyId, PartnerId, PaymentSystemId, Id, MinAmount, MaxAmount, PaymentSystemName, ContentType } = selectedPaymentMethod ? selectedPaymentMethod : [];
  const [bankNames, setBankNames] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);

  const [openModel, setOpenModel] = useState(false);
  const [transactionUrl, setTransactionUrl] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const paymentsNeedBankName = ["FugaPayBankTransfer", "FugaPayPapara", "FugaPayPayFix"];
  const showBankNameInput = selectedPaymentMethod && selectedPaymentMethod.PaymentSystemName && paymentsNeedBankName.includes(selectedPaymentMethod.PaymentSystemName);
  const isNowMethod = selectedPaymentMethod?.PaymentSystemName === "NOWPay";

  function onSubmit(data) {
    handleDeposit(data);
  }

  function resetForm() {
    reset();
    setValue("bankName", "");
    setValue("accountType", "");
  }

  const handleDeposit = (data) => {
    const Token = getItemFromLocalStorage("Token");
    const ClientId = getItemFromLocalStorage("Id");
    setErrMsg("");
    if (data.amount < MinAmount) {
      return setErrMsg(`Amount should not be less than ${MinAmount} ${CurrencyId}`);
    }
    if (data.amount > MaxAmount) {
      return setErrMsg(`Amount should not be greater than ${MaxAmount} ${CurrencyId}`);
    }

    let payload = {
      Loader: true,
      Controller: "Document",
      Method: "CreateDepositRequest",
      Token: Token,
      ClientId: ClientId,
      PartnerId: apiVars.partnerId,
      RequestData: JSON.stringify({
        PartnerId: PartnerId,
        Amount: data.amount,
        Info: JSON.stringify({
          CardNumber: "",
          BankId: showBankNameInput && data.bankName ? data.bankName.value : "",
          AccountType: data.accountType && isNowMethod ? data.accountType.value : "",
          NameSurname: "",
          Amount: data.amount,
          WalletNumber: "",
          BankName: "",
          ToBankNumber: "",
          BankAccountNumber: "",
          ClientName: "",
          NationalId: "",
          TypeDocumentId: "",
          VoucherNumber: "",
          ActivationCode: "",
          SMSCode: "",
          MobileNumber: "",
          PromoCode: data.promoCode,
          OperatorCardNumber: "",
          Info: "",
          TransactionId: "",
          ExpirationDate: "",
        }),
        PaymentSystemId: PaymentSystemId,
        PaymentForm: "",
        ImageName: "",
        ClientId: Id,
        CurrencyId: CurrencyId,
        CashCode: "",
        BetShopId: "",
        BonusId: "",
      }),
      TimeZone: 0,
    };
    // console.log(JSON.parse(JSON.parse(payload.RequestData).Info));
    dispatch(depositLoading(true));
    dispatch(deposit(payload));
    resetForm();
    saveActivityDepositForm(PaymentSystemId, CurrencyId, data.amount);
  };

  const ResponseObject = useSelector((state) => state?.cashierReducer?.deposit?.ResponseObject?.Url);

  useEffect(() => {
    if (ResponseObject) {
      fetchResponseUrl(ResponseObject, ContentType, setTransactionUrl, setOpenModel);
    }
    // eslint-disable-next-line
  }, [ResponseObject]);

  useEffect(() => {
    setErrMsg("");
    clearErrors();
    // eslint-disable-next-line
  }, [PaymentSystemId]);

  useEffect(() => {
    // maybe add loading ?
    if (showBankNameInput) {
      fetchBankNames(PaymentSystemId, setBankNames, setValue);
    }
    if (isNowMethod) {
      fetchAccountTypes(PaymentSystemId, setAccountTypes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethod]);

  return (
    <div className="cabinetForm cabinet-block">
      <div className="cabinetForm-block">
        <DepositFormHeader paymentSystemName={PaymentSystemName} />
        {/* <pre style={{ color: "lightgreen" }}>{PaymentSystemName}</pre> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="inputGroup">
            <p className="inputGroup-label">
              {t("Amount")} ({selectedCurrency})
            </p>
            <TextField
              inputProps={{
                step: "any",
                min: MinAmount,
                max: MaxAmount,
              }}
              {...register("amount", { required: true })}
              type="number"
              className="mui-textfield"
              data-e2e={t("Amount")} //or should be "amount"?
            />
            {errors.amount && <p className="error-message">This field is required</p>}
            {errMsg !== "" && <p className="error-message">{errMsg}</p>}
          </div>
          {showBankNameInput && (
            <div className="inputGroup">
              <p className="inputGroup-label">{t("Bank name")}</p>
              <Controller
                control={control}
                {...register("bankName", { required: showBankNameInput })}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    value={value}
                    options={bankNames}
                    placeholder=""
                    menuPortalTarget={document.body}
                    onChange={(selected) => {
                      setValue("bankName", selected);
                      clearErrors("bankName");
                      return selected.value;
                    }}
                    styles={styles}
                  />
                )}
              />
              {errors.bankName && <p className="error-message">This field is required</p>}
            </div>
          )}
          {isNowMethod && (
            <div className="inputGroup">
              <p className="inputGroup-label">{t("AccountType")}</p>
              <Controller
                control={control}
                {...register("accountType", { required: isNowMethod })}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    data-e2e="Select Account Type"
                    value={value}
                    options={accountTypes}
                    placeholder=""
                    menuPortalTarget={document.body}
                    onChange={(selected) => {
                      setValue("accountType", selected);
                      clearErrors("accountType");
                      return selected.value;
                    }}
                    styles={styles}
                  />
                )}
              />
              {errors.accountType && <p className="error-message">This field is required</p>}
            </div>
          )}
          <div className="inputGroup">
            <p className="inputGroup-label">{t("PromoCode")}</p>
            <TextField
              {...register("promoCode", { required: false })}
              className="mui-textfield"
            />
          </div>

          <button
            className="no-mobile cabinetForm-btn theme-btn full-width sqr-btn"
            data-e2e={t("Confirm")}
            type="submit">
            {t("Confirm")}
          </button>
        </form>
      </div>
      <Modal
        open={openModel}
        destroyOnClose
        footer={null}
        className="theme-modal"
        onCancel={() => {
          dispatch(depositSuccess([]));
          setOpenModel(false);
        }}>
        <div className="theme-modal-body">
          <button
            type="button"
            onClick={() => {
              dispatch(depositSuccess([]));
              setOpenModel(false);
            }}
            className={`theme-modal-close absolute`}>
            <img
              loading="lazy"
              width="17"
              height="17"
              src={Images.closeIcon}
              alt="close icon"
            />
          </button>
          <div className="theme-modal-iframe">
            <iframe
              // style={{height: document.body.scrollHeight + "px"}}
              data-e2e="url"
              data-e2e-transaction={transactionUrl}
              src={transactionUrl}
              width="100%"
              height="500px"
              title="Games"></iframe>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DepositForm;
