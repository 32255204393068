import useOutsideClick from "../../../helpers/outsideClick";
import { useRef, useState } from "react";
import NavbarMainSidebar from "./NavbarMainSidebar";
import { useHistory } from "react-router-dom";
import SidebarLogoMobile from "../../../BetLayout/SidebarLogoMobile";

export default function NavbarMainTablet() {
  const [isSidebar, setIsSidebar] = useState(false);
  const history = useHistory();

  function toggleSidebar(bool) {
    setIsSidebar(bool);
  }

  const refSidebarMain = useRef();
  useOutsideClick(refSidebarMain, () => {
    setIsSidebar(false);
  });

  return (
    <div ref={refSidebarMain} className="main-navbar-left on-tablet">
      <button onClick={() => toggleSidebar(true)} className="navbar-mobileBtn">
        <div></div>
        <div></div>
        <div></div>
      </button>
      <div className="divider"></div>
      <div
        className="navbar-logo-t navbar-logo-t-header cursor-pointer"
        onClick={() => history.push("/home")}
      >
          <SidebarLogoMobile />
      </div>
      <NavbarMainSidebar
        isSidebar={isSidebar}
        closeHandler={() => toggleSidebar(false)}
      />
    </div>
  );
}
