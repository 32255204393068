import { put, takeLatest, call } from "redux-saga/effects";
import { UpdateProfileActions } from "../types";
import ProfileApi from "../api";
import {
  getError,
  openNotificationWithIcon,
} from "../../../../../utils/helper";
import { addItemToLocalStorage } from "../../../../../utils/localStorage";
import { t } from "i18next";

function* updateProfileInfo(action) {
  try {
    const response = yield call(ProfileApi.updateProfileInfo, action.payload);
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    if (response?.data?.ResponseCode === 0) {
      const { Id, Email, CurrencyId, FirstName, LastName, LanguageId } =
        response?.data?.ResponseObject;
      addItemToLocalStorage("Id", Id);
      addItemToLocalStorage("Email", Email);
      addItemToLocalStorage("CurrencyId", CurrencyId);
      addItemToLocalStorage("FirstName", FirstName);
      addItemToLocalStorage("LastName", LastName);
      addItemToLocalStorage("LanguageId", LanguageId);
      addItemToLocalStorage('userData', response?.data?.ResponseObject)

      openNotificationWithIcon("success", t("success"), t("profile_updated"));
    }
    yield put({
      type: UpdateProfileActions.UPDATE_PROFILE_INFO_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({
      type: UpdateProfileActions.UPDATE_PROFILE_INFO_ERROR,
      payload: error,
    });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* sendVerificationCode(action) {
  try {
    const response = yield call(
      ProfileApi.sendVerificationCode,
      action.payload
    );
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    if (response?.data?.ResponseCode === 0) {
    }
    yield put({
      type: UpdateProfileActions.SEND_VERIFICATION_CODE_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({
      type: UpdateProfileActions.SEND_VERIFICATION_CODE_ERROR,
      payload: error,
    });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* getDocumentType(action) {
  try {
    const response = yield call(ProfileApi.documentVerification, action.payload);
    yield put({
      type: UpdateProfileActions.DOCUMENT_TYPE_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({
      type: UpdateProfileActions.DOCUMENT_TYPE_ERROR,
      payload: error,
    });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* SubmitDocument(action) {
  try {
    const response = yield call(ProfileApi.documentVerification, action.payload);
    if (response?.data?.ResponseCode !== 0) {
      openNotificationWithIcon("error", "Error", response?.data?.Description);
    }
    if (response?.data?.ResponseCode === 0) {
    }
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}

function* GetDocumentHistory(action) {
  try {
    const response = yield call(ProfileApi.documentVerification, action.payload);
    yield put({
      type: UpdateProfileActions.DOCUMENT_HISTORY_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({
      type: UpdateProfileActions.DOCUMENT_HISTORY_ERROR,
      payload: error,
    });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}
function* GetDocumentStatus(action) {
  try {
    const response = yield call(ProfileApi.documentVerification, action.payload);
    yield put({
      type: UpdateProfileActions.DOCUMENT_STATUS_SUCCESS,
      payload: response,
    });
    return true;
  } catch (error) {
    console.error("error in startSession saga :: ", error);
    const customizeError = getError(error);
    yield put({
      type: UpdateProfileActions.DOCUMENT_STATUS_ERROR,
      payload: error,
    });
    openNotificationWithIcon("error", "Error", customizeError);
    return false;
  }
}
export function* profileSaga() {
  yield takeLatest(UpdateProfileActions.UPDATE_PROFILE_INFO, updateProfileInfo);
  yield takeLatest(UpdateProfileActions.SEND_VERIFICATION_CODE, sendVerificationCode);
  yield takeLatest(UpdateProfileActions.DOCUMENT_TYPE, getDocumentType);
  yield takeLatest(UpdateProfileActions.SUBMIT_DOCUMENT, SubmitDocument);
  yield takeLatest(UpdateProfileActions.DOCUMENT_HISTORY, GetDocumentHistory);
  yield takeLatest(UpdateProfileActions.DOCUMENT_STATUS, GetDocumentStatus);
}
