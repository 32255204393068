import BasicLayout from "../../../BasicLayout";

const CommonFooterTermsAndConditions = ({ title, data }) => {
  return (
    <BasicLayout>
      <div className="content-px content-py uw-content legal">
				<div className="layout-title">{title}</div>
				<div className="legal-block" dangerouslySetInnerHTML={{__html: data}}></div>
			</div>
    </BasicLayout>
  );
};

export default CommonFooterTermsAndConditions;
