import { notification } from "antd";
import { apiVars } from "../api";

export const getImageFullUrl = (endpointUrl) => {
  return process.env.BASE_IMAGE_URL || "" + endpointUrl;
};
/**++
 * isNotEmptyArray - check the given array is empty or not
 * @param arr
 * @returns boolean
 */
export function isNotEmptyArray(arr) {
  return arr && arr.length > 0 && Array.isArray(arr);
}

/**
 * checkIsArray - check the given value is array or not
 * @param value
 * @returns boolean
 */
export function checkIsArray(value) {
  return value && Array.isArray(value);
}

/**
 * isNotEmptyObject - return boolean if object is empty or not
 * @param obj
 * @returns returns boolean
 */
export function isNotEmptyObject(obj) {
  return obj && obj instanceof Object && Object.keys(obj).length > 0;
}
export const isStringified = (value) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export const openNotificationWithIcon = (type, title, description) => {
  notification[type]({
    message: title,
    description,
  });
};

export function getError(payload) {
  const status = payload.status || "";
  const errorMsg = payload?.data?.error || "";
  const statusText = payload.statusText || "";
  const error =
    errorMsg ||
    (status
      ? `${status}
       - ${statusText}`
      : "");
  return error;
}

export const checkValidUrl = (url = "") => {
  let newUrl;
  var pattern = new RegExp("^(https?|http)://");

  if (!pattern.test(url)) {
    newUrl = `https://resources.${apiVars.currentHost}.casino/products/${url}`;
  } else {
    return url;
  }

  return newUrl;
};

export function replaceGamesKeysNames(game) {
  let temp = {
    gameImage: checkValidUrl(game?.i) || "-",
    name: game?.n || "-",
    nickName: game?.nn || "-",
    favourite: game?.f || false,
    rating: game?.r || 0,
    jackpotValue: game?.jp || "",
    backgroundImage: game?.bi || "",
    hasDemo: game?.hd || false,
    openMode: game?.o || "",
    productId: game?.p || "-",
    providerId: game?.s || "-",
    providerName: game?.sn || "",
    subproviderId: game?.ss || "",
    subproviderName: game?.sp || "",
  };
  return temp;
}
