import {
  HeartFilled,
  HeartOutlined,
  InfoCircleFilled,
  StarFilled,
} from "@ant-design/icons";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { saveActivity } from "../../../Components/ActivityTracking/activityTrackingService";
import { loginModal } from "../../../Components/Login/actions";
import CasinoCardInfoModel from "../../../Components/Models/CasinoCardInfoModel";
import CasinoCardGameModal from "../../../Components/Models/CasinoCardGameModal";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import {
  checkLoginStatus,
  gameMarkFavourite,
  playNowGame,
} from "../../Games/actions";
import "../Casino.scss";
import { useMediaQuery } from "react-responsive";
import { apiVars } from "../../../api";

const GameCards = (props) => {
  const [infoModal, setinfoModal] = useState(false);
  const [gamemodel, setgamemodel] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const isMobile = useMediaQuery({ maxWidth: 767 });


  // const screenWidth = window.innerWidth;

  // eslint-disable-next-line
  const {
    gameMarkFavourite,
    loginModal,
    playNowGame,
    checkLoginStatus,
    isGamesPage,
  } = props;
  const { gameImage, name, rating, favourite, productId } = props?.data;

  let PartnerId = getItemFromLocalStorage("PartnerId");
  let ClientId = getItemFromLocalStorage("Id");
  let Token = getItemFromLocalStorage("Token");
  const LanguageId = getItemFromLocalStorage("i18nextLng");
  const [gameUrl, setgameUrl] = useState("");

  const showgameUrl = useSelector(
    (state) => state?.gamesReducer?.playNowGame?.data
  );

  const handlehostname = () => {
    if (showgameUrl) {
      const hostName = window?.location?.host;
      let newurl = hostName.replace("www.", "");
      showgameUrl.ResponseObject = showgameUrl?.ResponseObject?.replace(
        newurl,
				`${apiVars.currentHost}.casino`
      );
      setgameUrl(showgameUrl);
    }
  };
  useEffect(() => {
    if (showgameUrl) {
      handlehostname();
    }
    //eslint-disable-next-line
  }, [showgameUrl]);

  const markGameFavourite = async (method) => {
    if (Token) {
      let payload = {
        Loader: true,
        PartnerId: PartnerId,
        TimeZone: 0,
        LanguageId: LanguageId,
        Method: method,
        Controller: "Product",
        Index: null,
        ActivationKey: null,
        MobileNumber: null,
        Code: null,
        RequestData: productId,
        ClientId: ClientId,
        Token: Token,
        p: productId,
      };
      await gameMarkFavourite(payload);
      setTimeout(() => {
        props?.handleFavoruiteStatus();
      }, 1000);
    } else {
      await checkLoginStatus(true);
    }
  };

  const handlePlaybtn = async () => {
    if (Token) {
      setgamemodel(true);
      handlePlayNow();
    } else {
      await checkLoginStatus(true);
      await setinfoModal(false);
    }
  };

  const handlePlayNow = async () => {
    saveActivity({
      EventPage: "Casino",
      EventType: "GameLaunch",
      attribute2: name || "",
    });
    if (Token) {
      let payload = {
        Loader: true,
        PartnerId: PartnerId,
        TimeZone: 0,
        LanguageId: LanguageId,
        ProductId: productId,
        Method: "GetProductUrl",
        Controller: "Main",
        CategoryId: null,
        PageIndex: 0,
        PageSize: 100,
        ProviderIds: [],
        Index: null,
        ActivationKey: null,
        MobileNumber: null,
        Code: null,
        RequestData: "{}",
        IsForDemo: false,
        IsForMobile: isMobile ? true : false,
        Position: "",
        DeviceType: 1,
        ClientId: ClientId,
        Token: Token,
      };
      await playNowGame(payload);
    } else {
      await loginModal(true);
    }
  };

  const handledemobtn = async () => {
    setgamemodel(true);
    handleDemo();
  };
  const handleDemo = async () => {
    let payload = {
      Loader: true,
      TimeZone: 0,
      Controller: "Main",
      LanguageId: "en",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Email: null,
      Code: null,
      RequestData: "{}",
      PartnerId: 74,
      ProductId: productId,
      Method: "GetProductUrl",
      IsForDemo: true,
      IsForMobile: isMobile ? true : false,
      Position: "",
      DeviceType: 1,
    };
    await playNowGame(payload);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <>
      <div
        className="casinoGame"
        onClick={() => windowSize < 992 && setinfoModal(true)}
      >
        <div className="casinoGame-img">
          <img src={gameImage} alt={name} />
        </div>

        <div className="casinoGame-footer">
          <div className="casinoGame-footer-row">
            <div className="casinoGame-footer-content">
              {!isGamesPage ? (
                favourite ? (
                  <HeartFilled
                    className="casinoGame-heart favorite"
                    onClick={() =>
                      markGameFavourite("RemoveClientFavoriteProduct")
                    }
                  />
                ) : (
                  <HeartOutlined
                    className="casinoGame-heart"
                    onClick={() => markGameFavourite("AddToFavoriteList")}
                  />
                )
              ) : null}

              <p className="casinoGame-name">{name}</p>
            </div>
            {!isGamesPage && (
              <div className="casinoGame-rating">
                <StarFilled className="text-warning" />
                <p>{rating}</p>
              </div>
            )}
          </div>
          <div className="casinoGame-btn">
            <button onClick={() => handlePlaybtn()}>{t("Play Now")}</button>
          </div>
          <div className="casinoGame-info">
            <button onClick={() => handledemobtn()} className="casinoGame-demo">
              {t("Demo")}
            </button>
            <button
              className="casinoGame-info-btn"
              onClick={() => setinfoModal(true)}
            >
              <InfoCircleFilled />
              <p>{t("Info")}</p>
            </button>
          </div>
        </div>
      </div>
      <CasinoCardInfoModel
        infoModal={infoModal}
        setinfoModal={setinfoModal}
        data={props?.data}
        handlePlaybtn={handlePlaybtn}
        handledemobtn={handledemobtn}
      />
      <CasinoCardGameModal
        gamemodel={gamemodel}
        setgamemodel={setgamemodel}
        result={props?.data}
        showgameUrl={gameUrl}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isloggedIn: state.loginReducer?.isloggedIn,
  };
};

export default connect(mapStateToProps, {
  gameMarkFavourite,
  loginModal,
  playNowGame,
  checkLoginStatus,
})(GameCards);

function getWindowSize() {
  const windowWidth = window.innerWidth;
  return windowWidth;
}
