import React from "react";
import CommonFooterTermsAndConditions from "./CommonFooterTermsAndConditions";
import { useTranslation } from "react-i18next";

const AmlPage = () => {
  const { t } = useTranslation();
	
  return <CommonFooterTermsAndConditions 
		title={t("AML")} 
		data={t("aml_policy_text")} 
	/>;
};

export default AmlPage;