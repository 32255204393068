import browser from "browser-detect";
import React, { useEffect } from "react";
import { saveActivity } from "../../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../Components/Common/getIp";
import BasicLayout from "../../BasicLayout";
import Banner from "./components/Banner";
import GameSlider from "./components/GamesSlider";
import {
  Megaways,
  SweetTreat,
  TameDragon,
  MagicMoments,
  NewGame,
  BookClub,
  Top10games,
	LiveCasino
} from "./data/constantGamesData";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import { addItemToLocalStorage, getItemFromLocalStorage } from "../../utils/localStorage";


const browserInfo = browser();

export const Home = () => {
  const { t } = useTranslation();
  const token = getItemFromLocalStorage("Token");
  // this method for extracting the search parameters
  const extractParams = (url) => {
     var urlObject = new URL(url);
     var paramsObject = {};
     urlObject?.searchParams?.forEach((value, key) => {
      paramsObject[key] = value;
     });
 
     return paramsObject;
}

const isObjectEmpty = (objectName) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};

  useEffect(() => {
    const hasParams = extractParams(window?.location?.href);
    if (token) return; else if (!isObjectEmpty(hasParams)) {
      addItemToLocalStorage('searchParams', JSON.stringify(hasParams))
    }
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    let deviceIp;
    async function fetchIp() {
      deviceIp = await getDeviceIP();
    }
    fetchIp();
    saveActivity({
      EventPage: "HomePage",
      EventType: "Load",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: "",
      attribute3: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
  }, []);
	
  return (
    <BasicLayout>
      <div className="homepage" id="home-page-container">
        <div className="content-px banner-px">
          <Banner language={i18n.language} />
        </div>
        <div className="content-px">
          <GameSlider
            gameHeading={t("New Games")}
            GamesData={NewGame}
            isRoundImage={true}
          />
          <GameSlider
            gameHeading={t("TOP 10's")}
            GamesData={Top10games}
            Top10Number={true}
          />
          <GameSlider
            gameHeading={"Megaways"}
            GamesData={Megaways}
            isRoundImage={true}
          />
					<GameSlider
            gameHeading={t("Live Casino")}
            GamesData={LiveCasino}
            isLiveCasino={true}
          />
          <GameSlider
            gameHeading={"Sweet Treat"}
            GamesData={SweetTreat}
            isRoundImage={true}
          />
          <GameSlider gameHeading={"BookClub"} GamesData={BookClub} />
          <GameSlider
            gameHeading={"Tame the Dragon"}
            GamesData={TameDragon}
            isRoundImage={true}
          />
          <GameSlider
            gameHeading={"Magic Moments"}
            GamesData={MagicMoments}
            isRoundImage={true}
          />
        </div>
      </div>
    </BasicLayout>
  );
};
export default Home;
