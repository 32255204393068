import React from "react";
import OPLayout from "../BetLayout";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const BasicLayout = (props) => {
  const { history } = props;
	const location = useLocation();
  return <OPLayout
		{...props}
		history={history}
		isLandingPage={location.pathname === "/"}
	/>;
};

export default BasicLayout;
