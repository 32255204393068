import { Images } from "../../Images";

export const promos = [
  {
    slug: "sports-welcome-bonus",
    imgs: [Images.sportsWelcomeBannerEn, Images.sportsWelcomeBannerTr],
    imgsLoggedIn: [Images.sportsWelcomeBannerEnPlay, Images.sportsWelcomeBannerTrPlay],
    category: "sports",
    content: "promo1_content",
  },
  {
    slug: "casino-welcome-bonus",
    imgs: [Images.casinoWelcomBannerEn, Images.casinoWelcomBannerTr],
    imgsLoggedIn: [Images.casinoWelcomBannerEnPlay, Images.casinoWelcomBannerTrPlay],
    category: "casino",
    content: "promo2_content",
  },
  {
    slug: "slot-weekly-bonus",
    imgs: [Images.slotsBonusEn, Images.slotsBonusTr],
    category: "casino",
    content: "promo3_content",
  },
  {
    slug: "champions-league-bonus",
    imgs: [Images.championsBonusEn, Images.championsBonusTr],
    category: "sports",
    content: "promo4_content",
  },
];
