import React, { useState } from "react";
import { Images } from "../../Images";
import BasicLayout from "../../BasicLayout";
import { useTranslation } from "react-i18next";
import MobileNavbar from "../../Components/MobileNavbar/MobileNavbar";
import { promos } from "./data";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { t } from "i18next";
import { getPromoImage } from "./helper";

const promotionCategories = [
  {
    categoryName: "Promos",
    categories: promos,
  },
  {
    categoryName: "Sports",
    categories: promos.filter((x) => x.category === "sports"),
  },
  {
    category: "Casino bonuses",
    categories: promos.filter((x) => x.category === "casino"),
  },
];

const Promotions = () => {
  const { i18n } = useTranslation();
  const activeLang = i18n.language;

  const [activeCategory, setActiveCategory] = useState(0);
  const activePromos = promotionCategories[activeCategory].categories;

  return (
    <BasicLayout pageTitle="Promotions">
      <div className="casino-page promo uw-content">
        <div className="casino-header content-px">
          <MobileNavbar />
        </div>
        <div className="promo__banner-px content-px">
          <div
            className="banner promo__banner"
            style={{ backgroundImage: `url('${Images.promoBanner}')` }}>
            <div className="promo__banner-content">
              <p className="promo__banner-title">{t("promotions")}</p>
              <p className="promo__banner-line"></p>
              <p className="promo__banner-text">{t("promo_banner_text")}</p>
            </div>
          </div>
        </div>
        <div className="content-px">
          <div className="casino-nav">
            <div className="casino-nav-menu">
              <button
                onClick={() => setActiveCategory(0)}
                className={`casino-category ${activeCategory === 0 ? "active" : ""}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none">
                  <ellipse
                    cx="34.8165"
                    cy="9.34441"
                    rx="0.683662"
                    ry="0.692069"
                    fill="white"
                  />
                  <path
                    d="M12.3192 14.5486L10.6387 15.6302L12.6908 18.8974L16.1553 16.6676L14.1031 13.4004L12.3464 14.5312C12.3376 14.5376 12.3285 14.5431 12.3192 14.5486Z"
                    fill="white"
                  />
                  <path
                    d="M6.46177 14.7401C6.29236 15.5105 6.77192 16.274 7.53294 16.4456L7.80897 16.5078L9.90741 15.1762C9.93074 15.1615 9.95558 15.1494 9.98149 15.14L11.2141 14.3468L8.14663 13.6557C7.38574 13.4851 6.63202 13.9702 6.46177 14.7401Z"
                    fill="white"
                  />
                  <path
                    d="M15.3341 7.97978C14.5731 7.80825 13.8188 8.29372 13.6493 9.06412L12.6973 13.3928L13.9462 12.589C13.9657 12.569 13.9872 12.5513 14.0104 12.5361L16.0926 11.1993L16.4043 9.69022C16.5752 8.91877 16.0961 8.15305 15.3341 7.97978Z"
                    fill="white"
                  />
                  <path
                    d="M20.585 9.76715C20.4746 9.7422 20.3618 9.72957 20.2487 9.72949C19.958 9.72967 19.6735 9.81398 19.4287 9.97248L14.7441 12.9876L16.7963 16.2548L22.775 12.4066L21.5458 10.4497C21.3284 10.1014 20.9825 9.85567 20.585 9.76715Z"
                    fill="white"
                  />
                  <path
                    d="M9.99744 16.0425L5.21035 19.1236C4.49637 19.5844 4.28552 20.5432 4.73902 21.267L5.9681 23.224L12.0496 19.3097L9.99744 16.0425Z"
                    fill="white"
                  />
                  <path
                    d="M18.9141 34.5H23.8277C24.6744 34.499 25.3605 33.8044 25.3615 32.9472V22.9248H18.9141L18.9141 34.5Z"
                    fill="white"
                  />
                  <path
                    d="M8.14863 32.9472C8.1496 33.8044 8.83576 34.499 9.68247 34.5H13.33V22.9248H8.14844L8.14863 32.9472Z"
                    fill="white"
                  />
                  <path
                    d="M14.0879 22.9248H18.1537V34.4999H14.0879L14.0879 22.9248Z"
                    fill="white"
                  />
                  <path
                    d="M29.9848 18.6646L30.1664 17.5928C30.1908 17.4487 30.1323 17.303 30.0154 17.217C29.8985 17.131 29.7435 17.1197 29.6156 17.1877L28.6648 17.6937L27.7141 17.1877C27.5862 17.1196 27.4311 17.1309 27.3142 17.2169C27.1973 17.3029 27.1387 17.4486 27.1632 17.5928L27.3448 18.6646L26.5756 19.4236C26.4721 19.5257 26.4349 19.6784 26.4795 19.8175C26.5242 19.9566 26.643 20.058 26.786 20.079L27.8489 20.2354L28.3243 21.2105C28.3882 21.3417 28.5202 21.4247 28.6647 21.4247C28.8092 21.4247 28.9412 21.3417 29.0051 21.2105L29.4805 20.2354L30.5434 20.079C30.6863 20.0579 30.8051 19.9566 30.8498 19.8175C30.8944 19.6783 30.8572 19.5256 30.7537 19.4236L29.9848 18.6646Z"
                    fill="white"
                  />
                  <path
                    d="M34.3517 13.3708L33.3078 13.114L32.925 12.098C32.8735 11.9613 32.7498 11.8661 32.6059 11.8523C32.4621 11.8385 32.323 11.9087 32.2471 12.0332L31.6833 12.9588L30.6105 13.0134C30.4662 13.0208 30.3386 13.1104 30.2812 13.2446C30.2238 13.3789 30.2467 13.5344 30.3402 13.6459L31.0356 14.4748L30.7558 15.5244C30.7181 15.6656 30.7629 15.8163 30.8713 15.913C30.9797 16.0097 31.1329 16.0358 31.2666 15.9802L32.2599 15.5668L33.1596 16.161C33.2806 16.2409 33.436 16.2443 33.5603 16.1698C33.6847 16.0953 33.7565 15.9558 33.7455 15.81L33.6642 14.7258L34.5003 14.0432C34.6127 13.9514 34.6639 13.803 34.6324 13.6603C34.6009 13.5176 34.4921 13.4053 34.3517 13.3708Z"
                    fill="white"
                  />
                  <path
                    d="M32.3649 6.3097C32.3334 6.16695 32.2245 6.0547 32.0841 6.02015L31.0402 5.76333L30.6574 4.74739C30.606 4.61069 30.4823 4.51545 30.3384 4.50171C30.1945 4.48796 30.0554 4.55811 29.9796 4.68264L29.4159 5.60828L28.343 5.66284C28.1987 5.67017 28.071 5.7598 28.0136 5.89404C27.9563 6.02828 27.9791 6.18386 28.0727 6.29535L28.7684 7.12416L28.4882 8.17391C28.4505 8.31512 28.4953 8.46574 28.6037 8.56246C28.7121 8.65917 28.8653 8.6852 28.999 8.62959L29.9922 8.21625L30.892 8.81052C31.013 8.89044 31.1683 8.89388 31.2927 8.81939C31.417 8.7449 31.4888 8.6054 31.4779 8.45955L31.3965 7.37521L32.2327 6.69268C32.3451 6.6009 32.3964 6.45243 32.3649 6.3097Z"
                    fill="white"
                  />
                  <path
                    d="M25.8319 18.179C23.9926 17.7183 22.6685 18.2192 21.8816 18.7206C21.0249 19.2666 20.6059 19.9282 20.5884 19.9561C20.4761 20.1354 20.5286 20.3728 20.7057 20.4864C20.8828 20.6001 21.1173 20.5469 21.2296 20.3677C21.2865 20.278 22.6599 18.1764 25.6493 18.925C25.7815 18.9594 25.9217 18.9194 26.0166 18.8202C26.1116 18.7209 26.1466 18.5778 26.1083 18.4452C26.0701 18.3126 25.9645 18.2109 25.8317 18.179H25.8319Z"
                    fill="white"
                  />
                  <path
                    d="M21.6483 16.8265C21.5868 16.9496 21.5952 17.0966 21.6703 17.2117C21.7455 17.3268 21.8758 17.3923 22.0118 17.3833C22.1477 17.3743 22.2685 17.2922 22.3282 17.1682C22.7466 16.3967 23.3157 15.7196 24.001 15.1781C25.4744 14.0299 27.1851 13.8523 29.0852 14.6509C29.2105 14.7035 29.354 14.6845 29.4616 14.601C29.5693 14.5175 29.6247 14.3822 29.6071 14.2461C29.5895 14.1099 29.5015 13.9937 29.3762 13.941C26.6739 12.8057 24.7098 13.6559 23.538 14.5691C22.7606 15.1811 22.1171 15.9498 21.6483 16.8265Z"
                    fill="white"
                  />
                  <path
                    d="M25.2972 11.5567C25.0977 11.4914 24.8837 11.6023 24.8193 11.8043C24.7548 12.0062 24.8643 12.2228 25.0638 12.2881C25.5864 12.4439 26.1284 12.5232 26.6732 12.5235C27.308 12.5235 28.0412 12.4074 28.6987 12.013C29.5591 11.4969 30.1109 10.6176 30.3395 9.39995C30.3786 9.19146 30.2434 8.99031 30.0374 8.95068C29.8315 8.91104 29.6328 9.04793 29.5936 9.25643C29.4077 10.2466 28.9776 10.9511 28.3149 11.3501C27.0545 12.1088 25.3245 11.5655 25.2972 11.5567Z"
                    fill="white"
                  />
                  <ellipse
                    cx="19.8008"
                    cy="21.0427"
                    rx="0.683662"
                    ry="0.692069"
                    fill="white"
                  />
                  <ellipse
                    cx="25.1798"
                    cy="8.48846"
                    rx="0.683662"
                    ry="0.692069"
                    fill="white"
                  />
                  <ellipse
                    cx="18.838"
                    cy="17.8356"
                    rx="0.683662"
                    ry="0.692069"
                    fill="white"
                  />
                  <ellipse
                    cx="33.6895"
                    cy="18.9631"
                    rx="0.683662"
                    ry="0.692069"
                    fill="white"
                  />
                </svg>
                <p>{t("promos")}</p>
              </button>
              <button
                onClick={() => setActiveCategory(1)}
                className={`casino-category ${activeCategory === 1 ? "active" : ""}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none">
                  <path
                    d="M10.4953 25.8309C10.2597 25.943 10.1123 26.1834 10.1194 26.4443V33.0395C10.117 33.3749 10.3667 33.6588 10.6998 33.699H17.3741V25.7847H10.6998C10.6289 25.7835 10.5588 25.7993 10.4953 25.8309Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.3248 25.7866C29.4026 25.7868 29.4793 25.8049 29.549 25.8394C29.7747 25.9561 29.9132 26.1922 29.9051 26.4461V33.0414C29.9076 33.3768 29.6578 33.6606 29.3248 33.7009H22.6504V25.7866H29.3248ZM27.9263 32.3808C28.2905 32.3808 28.5858 32.0856 28.5858 31.7213V29.0832C28.5858 28.719 28.2905 28.4237 27.9263 28.4237C27.5621 28.4237 27.2668 28.719 27.2668 29.0832V31.0618H25.2882C24.924 31.0618 24.6287 31.3571 24.6287 31.7213C24.6287 32.0856 24.924 32.3808 25.2882 32.3808H27.9263Z"
                    fill="white"
                  />
                  <path
                    d="M6.63048 20.7008C6.37187 20.4422 5.95257 20.4422 5.69396 20.7008C5.43535 20.9594 5.43535 21.3787 5.69396 21.6373L9.23559 25.179C9.37786 25.01 9.54458 24.8633 9.73024 24.7437C9.9305 24.6157 10.155 24.5303 10.3898 24.493L6.63048 20.7008Z"
                    fill="white"
                  />
                  <path
                    d="M34.3316 20.7019C34.2077 20.5771 34.0391 20.5068 33.8633 20.5068C33.6874 20.5068 33.5189 20.5771 33.395 20.7019L29.6094 24.4678C29.8446 24.5032 30.0695 24.5886 30.2689 24.7184C30.4545 24.838 30.6213 24.9848 30.7635 25.1537L34.3052 21.6121C34.5556 21.3644 34.5672 20.9637 34.3316 20.7019Z"
                    fill="white"
                  />
                  <path
                    d="M20.013 9.29761C20.3773 9.29761 20.6726 9.00233 20.6726 8.63809V6.65952C20.6726 6.29528 20.3773 6 20.013 6C19.6488 6 19.3535 6.29528 19.3535 6.65952V8.63809C19.3535 9.00233 19.6488 9.29761 20.013 9.29761Z"
                    fill="white"
                  />
                  <path
                    d="M13.4827 11.6128C13.606 11.7386 13.7747 11.8099 13.9509 11.8106C14.2177 11.8095 14.4575 11.6478 14.5585 11.401C14.6596 11.1541 14.602 10.8707 14.4126 10.6829L13.0144 9.29785C12.7525 9.11863 12.4 9.15138 12.1756 9.3758C11.9512 9.60022 11.9184 9.95266 12.0977 10.2146L13.4827 11.6128Z"
                    fill="white"
                  />
                  <path
                    d="M26.0728 11.8106C26.249 11.8099 26.4177 11.7386 26.5411 11.6128L27.926 10.2146C28.1053 9.95266 28.0725 9.60022 27.8481 9.3758C27.6237 9.15138 27.2712 9.11863 27.0093 9.29785L25.6111 10.6829C25.4217 10.8707 25.3641 11.1541 25.4652 11.401C25.5662 11.6478 25.8061 11.8095 26.0728 11.8106Z"
                    fill="white"
                  />
                  <path
                    d="M18.6934 25.7847H21.3314V33.6989H18.6934V25.7847Z"
                    fill="white"
                  />
                  <path
                    d="M17.8165 18.036L16.4579 22.2174L20.0128 19.632L23.5676 22.2174L22.209 18.036L25.7638 15.4572H21.3714L20.0128 11.2759L18.6541 15.4572H14.2617L17.8165 18.036Z"
                    fill="white"
                  />
                </svg>
                <p>{t("sports")}</p>
              </button>
              <button
                onClick={() => setActiveCategory(2)}
                className={`casino-category ${activeCategory === 2 ? "active" : ""}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none">
                  <path
                    d="M28.4339 22.5908C27.9575 22.5908 27.5703 22.978 27.5703 23.4545C27.5703 23.931 27.9575 24.3181 28.4339 24.3181C28.9104 24.3181 29.2976 23.931 29.2976 23.4545C29.2976 22.978 28.9104 22.5908 28.4339 22.5908Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.708 10.5H33.6152C34.0925 10.5 34.4788 10.8863 34.4788 11.3636V28.6364C34.4788 29.1137 34.0925 29.5 33.6152 29.5H13.6364V26.9091C13.6364 26.4317 13.2501 26.0455 12.7727 26.0455C12.2954 26.0455 11.9091 26.4317 11.9091 26.9091V29.5H5.86364C5.38628 29.5 5 29.1137 5 28.6364V23.4545C5 22.9772 5.38628 22.5909 5.86364 22.5909C7.29232 22.5909 8.45455 21.4287 8.45455 20C8.45455 18.5713 7.29232 17.4091 5.86364 17.4091C5.38628 17.4091 5 17.0228 5 16.5455V11.3636C5 10.8863 5.38628 10.5 5.86364 10.5H11.9808V13.0909C11.9808 13.5683 12.367 13.9545 12.8444 13.9545C13.3218 13.9545 13.708 13.5683 13.708 13.0909V10.5ZM12.7718 24.3183C13.2492 24.3183 13.6355 23.932 13.6355 23.4547V21.7274C13.6355 21.25 13.2492 20.8638 12.7718 20.8638C12.2945 20.8638 11.9082 21.25 11.9082 21.7274V23.4547C11.9082 23.932 12.2945 24.3183 12.7718 24.3183ZM13.6355 18.2725C13.6355 18.7499 13.2492 19.1362 12.7718 19.1362C12.2945 19.1362 11.9082 18.7499 11.9082 18.2725V16.5453C11.9082 16.0679 12.2945 15.6816 12.7718 15.6816C13.2492 15.6816 13.6355 16.0679 13.6355 16.5453V18.2725ZM21.5245 13.9546C20.0958 13.9546 18.9336 15.1168 18.9336 16.5455C18.9336 17.9742 20.0958 19.1364 21.5245 19.1364C22.9532 19.1364 24.1154 17.9742 24.1154 16.5455C24.1154 15.1168 22.9532 13.9546 21.5245 13.9546ZM21.045 25.9003C20.6478 25.6355 20.5407 25.1 20.8055 24.7027L27.7146 14.3391C27.9786 13.9419 28.5142 13.8356 28.9122 14.0996C29.3094 14.3644 29.4166 14.8999 29.1517 15.2972L22.2427 25.6608C21.9821 26.0522 21.4486 26.1678 21.045 25.9003ZM25.8418 23.4547C25.8418 24.8834 27.004 26.0456 28.4327 26.0456C29.8614 26.0456 31.0236 24.8834 31.0236 23.4547C31.0236 22.026 29.8614 20.8638 28.4327 20.8638C27.004 20.8638 25.8418 22.026 25.8418 23.4547Z"
                    fill="white"
                  />
                  <path
                    d="M21.5238 15.6816C21.0473 15.6816 20.6602 16.0688 20.6602 16.5453C20.6602 17.0218 21.0473 17.4089 21.5238 17.4089C22.0003 17.4089 22.3874 17.0218 22.3874 16.5453C22.3874 16.0688 22.0003 15.6816 21.5238 15.6816Z"
                    fill="white"
                  />
                </svg>
                <p>{t("casino_bonuses")}</p>
              </button>
            </div>
          </div>
          <div className="promo__content ">
            {activePromos.map((x) => {
              return (
                <NavLink
                  exact
                  activeClassName="active"
                  key={x.slug}
                  to={`/promotions/${x.slug}`}
                  className="promo__content-link">
                  <img
                    src={getPromoImage(x, activeLang)}
                    alt="promotion"
                  />
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default Promotions;
