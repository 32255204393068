import { Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Images } from "../Images";
import AuthModalImg from "../Components/Models/AuthModalImg";
import { useTranslation } from "react-i18next";
import { getItemFromLocalStorage } from "../utils/localStorage";
import { useHistory } from "react-router-dom";
import BasicLayout from "../BasicLayout";
import axios from "axios";
import { apiVars } from "../api";
import { openNotificationWithIcon } from "../utils/helper";
import { checkLoginStatus } from "../modules/Games/actions";
import { Alert } from "antd";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { loginModal } from "../Components/Login/actions";

const ChangePassword = (props) => {
  const { ResponseCode, Description } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const recoveryToken = queryParams.get("RecoveryCode");

  const [isLoading, setIsLoading] = useState(false);
  const LanguageId = getItemFromLocalStorage("i18nextLng");

  const handleForgotPassword = (values) => {
    const { Confirmpassword, Newpassword } = values;
    const payload = {
      RecoveryToken: recoveryToken,
      Password: Newpassword,
      NewPassword: Confirmpassword,
      LanguageId: LanguageId,
      TimeZone: 0,
    };
    setIsLoading(true);
    axios.post(`${apiVars.baseApiUrl}/Main/RecoverPassword`, payload).then((res) => {
      if (res?.data?.ResponseCode === 0) {
        openNotificationWithIcon("success", "Success", "Password has been changed successfully");
        history.push("/home");
        dispatch(loginModal(true));
      } else {
        openNotificationWithIcon("error", "Error", `${res?.data?.Description} , Please try again`);
      }
    });
    setIsLoading(false);
    dispatch(checkLoginStatus(false));
  };

  useEffect(() => {
    if (!recoveryToken) {
      history.push("/home");
    }
  }, [recoveryToken, history]);

  return (
    <BasicLayout>
      <div className="uw-content content-px content-py">
        {recoveryToken && (
          <div className="signUp authModal-row">
            <AuthModalImg />
            <div className="theme-modal-body authModal-body">
              <div className="theme-modal-header authModal-header">
                <p className="theme-modal-heading">{t("Forgot Password")}</p>
              </div>
              <div className="authModal-reg">
                {ResponseCode && ResponseCode !== 0 && (
                  <Alert
                    message={Description || t("Something Went wrong")}
                    type="error"
                    style={{ margin: "10px 0px" }}
                    showIcon
                  />
                )}
                <Form onFinish={handleForgotPassword}>
                  <Form.Item
                    name="Newpassword"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}>
                    <Input
                      placeholder={t("New-password")}
                      name="Newpassword"
                      type="password"
                      className="theme-input"
                      prefix={
                        <img
                          width="18"
                          height="18"
                          src={Images.passwordIcon}
                          alt="icon"
                          loading="lazy"
                        />
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="Confirmpassword"
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("Newpassword") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error("Passwords do not match!"));
                        },
                      }),
                    ]}>
                    <Input
                      placeholder={t("Confirm-password")}
                      name="Confirmpassword"
                      className="theme-input"
                      type="password"
                      prefix={
                        <img
                          width="18"
                          height="18"
                          src={Images.passwordIcon}
                          alt="icon"
                          loading="lazy"
                        />
                      }
                    />
                  </Form.Item>
                  <Form.Item>
                    <button
                      type="submit"
                      disabled={isLoading}
                      className={`authModal-recoveryBtn theme-btn full-width sqr-btn no-mobile`}>
                      {isLoading ? "Loading..." : t("Change-password")}
                    </button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        )}
      </div>
    </BasicLayout>
  );
};

export default ChangePassword;
