export default function LogoSportColor() {
  return (
    <svg
      width="268"
      height="55"
      viewBox="0 0 268 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.898 33.8096C20.3908 33.8096 24.5327 24.3428 24.5327 16.1009C24.5327 11.661 22.5191 10.3213 20.2124 10.3213C19.095 10.3175 17.9962 10.6079 17.0263 11.1634C16.5166 11.4441 16.2999 11.6099 15.9686 11.6099C15.6372 11.6099 15.4588 11.3803 15.4588 10.9975C15.9156 9.19448 17.0592 7.64204 18.6448 6.6724C20.3018 5.45873 22.3146 4.83045 24.367 4.88623C28.5599 4.88623 31.644 7.7441 31.644 13.6895C31.644 25.9121 23.1308 38.6322 12.8207 38.6322C5.54378 38.6322 0 34.0902 0 24.9552C0 7.96102 12.6041 0.841797 21.4486 0.841797C24.9787 0.841797 26.5463 1.63282 26.5463 2.47487C26.5463 2.80659 26.3169 3.03624 25.8198 3.03624C25.3228 3.03624 24.5454 2.92142 23.1308 2.92142C15.9048 2.92142 9.74937 10.5765 9.74937 24.1642C9.74937 30.9006 11.9924 33.8096 14.8471 33.8096"
        fill="#FF6767"
      />
      <path
        d="M14.898 33.8096C20.3908 33.8096 24.5327 24.3428 24.5327 16.1009C24.5327 11.661 22.5191 10.3213 20.2124 10.3213C19.095 10.3175 17.9962 10.6079 17.0263 11.1634C16.5166 11.4441 16.2999 11.6099 15.9686 11.6099C15.6372 11.6099 15.4588 11.3803 15.4588 10.9975C15.9156 9.19448 17.0592 7.64204 18.6448 6.6724C20.3018 5.45873 22.3146 4.83045 24.367 4.88623C28.5599 4.88623 31.644 7.7441 31.644 13.6895C31.644 25.9121 23.1308 38.6322 12.8207 38.6322C5.54378 38.6322 0 34.0902 0 24.9552C0 7.96102 12.6041 0.841797 21.4486 0.841797C24.9787 0.841797 26.5463 1.63282 26.5463 2.47487C26.5463 2.80659 26.3169 3.03624 25.8198 3.03624C25.3228 3.03624 24.5454 2.92142 23.1308 2.92142C15.9048 2.92142 9.74937 10.5765 9.74937 24.1642C9.74937 30.9006 11.9924 33.8096 14.8471 33.8096"
        fill="url(#paint0_linear_3_85550)"
      />
      <path
        d="M50.4544 32.5837C50.8494 32.1882 51.0788 32.0224 51.4102 32.0224C51.6166 32.0255 51.8138 32.1082 51.9609 32.2531C52.108 32.398 52.1938 32.5941 52.2003 32.8007C52.1119 33.4859 51.7927 34.1205 51.2955 34.5996C50.5032 35.6859 49.479 36.5817 48.2974 37.2218C47.1157 37.8618 45.8063 38.23 44.4646 38.2995C39.5963 38.2995 39.2649 30.8358 38.7042 30.8358C38.1377 32.811 37.6867 34.8174 37.3533 36.845C37.3533 37.7892 37.0219 38.0188 36.1808 38.0188C34.4986 38.0188 32.2556 38.2995 31.3635 38.2995C30.688 38.2995 30.293 38.0188 30.293 37.0237C30.293 32.15 35.225 20.374 35.225 12.0172C35.225 10.1672 34.2691 9.27415 33.0967 9.27415C32.5997 9.27415 32.2556 9.0445 32.2556 8.59796C32.2556 7.42418 35.225 5.57422 38.4238 5.57422C41.3805 5.57422 43.1774 7.14349 43.1774 9.95034C43.1774 13.4844 39.2012 25.758 39.2012 28.0035C40.0426 27.9106 40.8295 27.5408 41.4384 26.952C42.0473 26.3632 42.4438 25.5888 42.5657 24.7501C42.5657 24.1249 42.1706 24.0229 42.1706 23.4743C42.1706 22.2877 45.637 20.5015 48.2241 20.5015C49.3329 20.5015 49.8426 20.9481 49.8426 21.7774C49.8426 24.1249 47.4339 27.1614 43.2921 29.4324C43.9038 31.3972 45.8409 33.6937 48.1604 33.6937C48.6059 33.6813 49.043 33.5695 49.4399 33.3664C49.8368 33.1633 50.1833 32.8742 50.4544 32.52"
        fill="#FF6767"
      />
      <path
        d="M50.4544 32.5837C50.8494 32.1882 51.0788 32.0224 51.4102 32.0224C51.6166 32.0255 51.8138 32.1082 51.9609 32.2531C52.108 32.398 52.1938 32.5941 52.2003 32.8007C52.1119 33.4859 51.7927 34.1205 51.2955 34.5996C50.5032 35.6859 49.479 36.5817 48.2974 37.2218C47.1157 37.8618 45.8063 38.23 44.4646 38.2995C39.5963 38.2995 39.2649 30.8358 38.7042 30.8358C38.1377 32.811 37.6867 34.8174 37.3533 36.845C37.3533 37.7892 37.0219 38.0188 36.1808 38.0188C34.4986 38.0188 32.2556 38.2995 31.3635 38.2995C30.688 38.2995 30.293 38.0188 30.293 37.0237C30.293 32.15 35.225 20.374 35.225 12.0172C35.225 10.1672 34.2691 9.27415 33.0967 9.27415C32.5997 9.27415 32.2556 9.0445 32.2556 8.59796C32.2556 7.42418 35.225 5.57422 38.4238 5.57422C41.3805 5.57422 43.1774 7.14349 43.1774 9.95034C43.1774 13.4844 39.2012 25.758 39.2012 28.0035C40.0426 27.9106 40.8295 27.5408 41.4384 26.952C42.0473 26.3632 42.4438 25.5888 42.5657 24.7501C42.5657 24.1249 42.1706 24.0229 42.1706 23.4743C42.1706 22.2877 45.637 20.5015 48.2241 20.5015C49.3329 20.5015 49.8426 20.9481 49.8426 21.7774C49.8426 24.1249 47.4339 27.1614 43.2921 29.4324C43.9038 31.3972 45.8409 33.6937 48.1604 33.6937C48.6059 33.6813 49.043 33.5695 49.4399 33.3664C49.8368 33.1633 50.1833 32.8742 50.4544 32.52"
        fill="url(#paint1_linear_3_85550)"
      />
      <path
        d="M52.6522 22.5942C52.6522 21.3184 55.6726 20.5273 58.8586 20.5273C60.133 20.5273 60.7702 21.0249 60.7702 21.9818C60.7702 24.4442 58.8076 28.6034 58.8076 32.0737C58.783 32.2842 58.8015 32.4976 58.8622 32.7007C58.9228 32.9039 59.0243 33.0924 59.1603 33.2549C59.2963 33.4174 59.4641 33.5503 59.6533 33.6456C59.8424 33.7408 60.0491 33.7964 60.2605 33.8088C60.9856 33.7031 61.6783 33.4377 62.2888 33.0317C62.8992 32.6258 63.4121 32.0894 63.7906 31.4613C64.895 29.2711 65.4546 26.8463 65.4219 24.3931C65.4219 23.4363 65.0268 23.3214 65.0268 22.5942C65.0268 21.3184 68.0472 20.5273 71.246 20.5273C72.5204 20.5273 73.1449 21.0249 73.1449 21.9818C73.1449 23.3852 72.5332 23.9976 70.9656 33.1964C69.5001 41.7701 67.7158 54.1585 57.5714 54.1585C53.4933 54.1585 49.9249 51.811 49.9249 47.8814C49.8221 46.4071 50.2598 44.9459 51.156 43.7716C52.0522 42.5973 53.3454 41.7904 54.7932 41.5022C55.1883 41.5022 55.4049 41.668 55.4049 41.9997C55.4049 42.5611 54.9079 42.8418 54.9079 44.0793C54.9079 45.6997 56.1823 46.7076 58.094 46.7076C62.1849 46.7076 63.6377 40.3284 64.4661 34.8805C62.1848 37.086 59.1239 38.2971 55.9529 38.2487C55.3495 38.2975 54.7425 38.2227 54.1688 38.029C53.5952 37.8354 53.0669 37.5268 52.6162 37.1221C52.1655 36.7175 51.8018 36.2253 51.5473 35.6754C51.2927 35.1255 51.1527 34.5295 51.1356 33.9237C51.1356 30.1727 52.9835 26.3579 52.9835 24.3421C52.9835 23.3852 52.6012 23.2704 52.6012 22.5432"
        fill="#FF6767"
      />
      <path
        d="M52.6522 22.5942C52.6522 21.3184 55.6726 20.5273 58.8586 20.5273C60.133 20.5273 60.7702 21.0249 60.7702 21.9818C60.7702 24.4442 58.8076 28.6034 58.8076 32.0737C58.783 32.2842 58.8015 32.4976 58.8622 32.7007C58.9228 32.9039 59.0243 33.0924 59.1603 33.2549C59.2963 33.4174 59.4641 33.5503 59.6533 33.6456C59.8424 33.7408 60.0491 33.7964 60.2605 33.8088C60.9856 33.7031 61.6783 33.4377 62.2888 33.0317C62.8992 32.6258 63.4121 32.0894 63.7906 31.4613C64.895 29.2711 65.4546 26.8463 65.4219 24.3931C65.4219 23.4363 65.0268 23.3214 65.0268 22.5942C65.0268 21.3184 68.0472 20.5273 71.246 20.5273C72.5204 20.5273 73.1449 21.0249 73.1449 21.9818C73.1449 23.3852 72.5332 23.9976 70.9656 33.1964C69.5001 41.7701 67.7158 54.1585 57.5714 54.1585C53.4933 54.1585 49.9249 51.811 49.9249 47.8814C49.8221 46.4071 50.2598 44.9459 51.156 43.7716C52.0522 42.5973 53.3454 41.7904 54.7932 41.5022C55.1883 41.5022 55.4049 41.668 55.4049 41.9997C55.4049 42.5611 54.9079 42.8418 54.9079 44.0793C54.9079 45.6997 56.1823 46.7076 58.094 46.7076C62.1849 46.7076 63.6377 40.3284 64.4661 34.8805C62.1848 37.086 59.1239 38.2971 55.9529 38.2487C55.3495 38.2975 54.7425 38.2227 54.1688 38.029C53.5952 37.8354 53.0669 37.5268 52.6162 37.1221C52.1655 36.7175 51.8018 36.2253 51.5473 35.6754C51.2927 35.1255 51.1527 34.5295 51.1356 33.9237C51.1356 30.1727 52.9835 26.3579 52.9835 24.3421C52.9835 23.3852 52.6012 23.2704 52.6012 22.5432"
        fill="url(#paint2_linear_3_85550)"
      />
      <path
        d="M90.7756 38.2998C89.8377 38.3158 88.9182 38.0378 88.1458 37.5048C87.3734 36.9718 86.7868 36.2103 86.4681 35.3271C84.5446 37.13 82.0402 38.1844 79.4078 38.2998C76.107 38.2998 73.418 36.0543 73.418 31.742C73.418 26.192 76.7825 20.5273 84.7858 20.5273C87.7553 20.5273 88.6091 21.5863 89.4884 21.5863C90.3678 21.5863 92.8912 20.5528 94.1656 20.5528C94.28 20.5356 94.3969 20.5455 94.5068 20.5817C94.6168 20.6179 94.7167 20.6794 94.7985 20.7613C94.8803 20.8432 94.9418 20.9432 94.9779 21.0533C95.0141 21.1633 95.024 21.2804 95.0067 21.3949C95.0067 22.7345 93.0441 27.1107 93.0441 31.4868C93.0087 31.7641 93.0305 32.0456 93.1083 32.314C93.186 32.5825 93.3179 32.8321 93.4959 33.0474C93.674 33.2627 93.8943 33.4391 94.1432 33.5657C94.3921 33.6923 94.6643 33.7664 94.943 33.7833C95.8988 33.7833 96.3449 33.3368 97.0713 32.6096C97.4663 32.214 97.6957 32.0482 98.0271 32.0482C98.2322 32.0515 98.4281 32.1345 98.5732 32.2798C98.7183 32.425 98.8012 32.621 98.8045 32.8264C98.7216 33.5106 98.4067 34.1456 97.9124 34.6254C97.0845 35.7411 96.0142 36.654 94.7822 37.2949C93.5503 37.9359 92.189 38.2882 90.8011 38.3253M85.3083 23.0152C82.1223 23.0152 80.7204 28.9096 80.7204 31.9461C80.7204 33.4006 81.3959 34.5871 82.6321 34.5871C83.8683 34.5871 84.8113 33.6302 85.5378 31.9461C86.4144 29.5953 86.905 27.1178 86.9906 24.61C87.019 24.3843 86.9947 24.155 86.9194 23.9403C86.8441 23.7256 86.72 23.5314 86.5568 23.373C86.3936 23.2145 86.1959 23.0963 85.9792 23.0276C85.7625 22.9589 85.5328 22.9415 85.3083 22.9769"
        fill="#FF6767"
      />
      <path
        d="M90.7756 38.2998C89.8377 38.3158 88.9182 38.0378 88.1458 37.5048C87.3734 36.9718 86.7868 36.2103 86.4681 35.3271C84.5446 37.13 82.0402 38.1844 79.4078 38.2998C76.107 38.2998 73.418 36.0543 73.418 31.742C73.418 26.192 76.7825 20.5273 84.7858 20.5273C87.7553 20.5273 88.6091 21.5863 89.4884 21.5863C90.3678 21.5863 92.8912 20.5528 94.1656 20.5528C94.28 20.5356 94.3969 20.5455 94.5068 20.5817C94.6168 20.6179 94.7167 20.6794 94.7985 20.7613C94.8803 20.8432 94.9418 20.9432 94.9779 21.0533C95.0141 21.1633 95.024 21.2804 95.0067 21.3949C95.0067 22.7345 93.0441 27.1107 93.0441 31.4868C93.0087 31.7641 93.0305 32.0456 93.1083 32.314C93.186 32.5825 93.3179 32.8321 93.4959 33.0474C93.674 33.2627 93.8943 33.4391 94.1432 33.5657C94.3921 33.6923 94.6643 33.7664 94.943 33.7833C95.8988 33.7833 96.3449 33.3368 97.0713 32.6096C97.4663 32.214 97.6957 32.0482 98.0271 32.0482C98.2322 32.0515 98.4281 32.1345 98.5732 32.2798C98.7183 32.425 98.8012 32.621 98.8045 32.8264C98.7216 33.5106 98.4067 34.1456 97.9124 34.6254C97.0845 35.7411 96.0142 36.654 94.7822 37.2949C93.5503 37.9359 92.189 38.2882 90.8011 38.3253M85.3083 23.0152C82.1223 23.0152 80.7204 28.9096 80.7204 31.9461C80.7204 33.4006 81.3959 34.5871 82.6321 34.5871C83.8683 34.5871 84.8113 33.6302 85.5378 31.9461C86.4144 29.5953 86.905 27.1178 86.9906 24.61C87.019 24.3843 86.9947 24.155 86.9194 23.9403C86.8441 23.7256 86.72 23.5314 86.5568 23.373C86.3936 23.2145 86.1959 23.0963 85.9792 23.0276C85.7625 22.9589 85.5328 22.9415 85.3083 22.9769"
        fill="url(#paint3_linear_3_85550)"
      />
      <path
        d="M109.37 33.9758C109.37 30.5566 111.155 28.3239 111.155 26.1294C111.155 25.2363 110.658 24.5602 109.88 24.5602C109.103 24.5602 107.586 25.517 106.363 27.9794C105.086 30.7809 104.273 33.7712 103.954 36.8338C103.954 37.7906 103.559 38.0203 102.68 38.0203C100.998 38.0203 98.8565 38.301 97.9771 38.301C97.0978 38.301 96.8047 38.0203 96.8047 37.0251C96.8047 34.9455 99.1496 27.3798 99.1496 24.356C99.1496 23.3992 98.7546 23.2843 98.7546 22.5571C98.7546 21.2812 101.788 20.4902 105.037 20.4902C106.312 20.4902 106.936 20.9878 106.936 21.9447C106.872 22.8078 106.722 23.6625 106.49 24.4964C107.497 23.2205 110.798 20.5157 114.137 20.5157C117.221 20.5157 118.457 21.9191 118.457 24.0498C118.457 26.6015 116.89 29.4211 116.89 31.7048C116.89 33.1593 117.667 33.7207 118.674 33.7207C119.681 33.7207 120.076 33.2741 120.802 32.5469C121.197 32.1514 121.427 31.9855 121.758 31.9855C121.963 31.9888 122.159 32.0719 122.304 32.2171C122.449 32.3624 122.532 32.5584 122.535 32.7638C122.452 33.448 122.137 34.083 121.643 34.5628C120.815 35.6785 119.745 36.5913 118.513 37.2323C117.281 37.8733 115.92 38.2256 114.532 38.2627C111.346 38.2627 109.434 36.3489 109.434 33.9376"
        fill="#FF6767"
      />
      <path
        d="M109.37 33.9758C109.37 30.5566 111.155 28.3239 111.155 26.1294C111.155 25.2363 110.658 24.5602 109.88 24.5602C109.103 24.5602 107.586 25.517 106.363 27.9794C105.086 30.7809 104.273 33.7712 103.954 36.8338C103.954 37.7906 103.559 38.0203 102.68 38.0203C100.998 38.0203 98.8565 38.301 97.9771 38.301C97.0978 38.301 96.8047 38.0203 96.8047 37.0251C96.8047 34.9455 99.1496 27.3798 99.1496 24.356C99.1496 23.3992 98.7546 23.2843 98.7546 22.5571C98.7546 21.2812 101.788 20.4902 105.037 20.4902C106.312 20.4902 106.936 20.9878 106.936 21.9447C106.872 22.8078 106.722 23.6625 106.49 24.4964C107.497 23.2205 110.798 20.5157 114.137 20.5157C117.221 20.5157 118.457 21.9191 118.457 24.0498C118.457 26.6015 116.89 29.4211 116.89 31.7048C116.89 33.1593 117.667 33.7207 118.674 33.7207C119.681 33.7207 120.076 33.2741 120.802 32.5469C121.197 32.1514 121.427 31.9855 121.758 31.9855C121.963 31.9888 122.159 32.0719 122.304 32.2171C122.449 32.3624 122.532 32.5584 122.535 32.7638C122.452 33.448 122.137 34.083 121.643 34.5628C120.815 35.6785 119.745 36.5913 118.513 37.2323C117.281 37.8733 115.92 38.2256 114.532 38.2627C111.346 38.2627 109.434 36.3489 109.434 33.9376"
        fill="url(#paint4_linear_3_85550)"
      />
      <path
        d="M133.678 34.8806C131.367 37.0047 128.365 38.2193 125.229 38.2998C124.649 38.33 124.069 38.2396 123.525 38.0343C122.982 37.8289 122.487 37.5131 122.071 37.1068C121.656 36.7005 121.329 36.2124 121.111 35.6734C120.894 35.1343 120.79 34.5559 120.806 33.9747C120.806 30.5044 122.705 26.409 122.705 24.3931C122.705 23.4363 122.31 23.3214 122.31 22.5942C122.31 21.3184 125.343 20.5273 128.58 20.5273C129.855 20.5273 130.492 21.0249 130.492 21.9818C130.492 24.5335 128.415 28.361 128.415 32.4054C128.396 32.59 128.417 32.7765 128.478 32.9519C128.538 33.1273 128.636 33.2874 128.765 33.421C128.893 33.5546 129.05 33.6585 129.222 33.7255C129.395 33.7925 129.581 33.821 129.766 33.8089C130.473 33.7417 131.152 33.4957 131.738 33.094C132.324 32.6923 132.799 32.148 133.117 31.5123C134.325 29.328 134.997 26.8882 135.08 24.3931C135.08 23.4363 134.685 23.3214 134.685 22.5942C134.685 21.3184 137.718 20.5273 140.968 20.5273C142.242 20.5273 142.867 21.0249 142.867 21.9818C142.867 24.1635 140.904 28.4886 140.904 31.4613C140.904 32.9157 141.694 33.7578 142.701 33.7578C143.708 33.7578 144.103 33.3112 144.829 32.584C145.224 32.1885 145.441 32.0226 145.785 32.0226C145.99 32.0259 146.186 32.109 146.331 32.2542C146.476 32.3995 146.559 32.5955 146.563 32.8009C146.48 33.4851 146.165 34.1201 145.67 34.5999C144.852 35.7138 143.791 36.6261 142.568 37.2673C141.344 37.9085 139.991 38.2616 138.61 38.2998C134.188 38.2998 133.678 34.8806 133.678 34.8806Z"
        fill="#FF6767"
      />
      <path
        d="M133.678 34.8806C131.367 37.0047 128.365 38.2193 125.229 38.2998C124.649 38.33 124.069 38.2396 123.525 38.0343C122.982 37.8289 122.487 37.5131 122.071 37.1068C121.656 36.7005 121.329 36.2124 121.111 35.6734C120.894 35.1343 120.79 34.5559 120.806 33.9747C120.806 30.5044 122.705 26.409 122.705 24.3931C122.705 23.4363 122.31 23.3214 122.31 22.5942C122.31 21.3184 125.343 20.5273 128.58 20.5273C129.855 20.5273 130.492 21.0249 130.492 21.9818C130.492 24.5335 128.415 28.361 128.415 32.4054C128.396 32.59 128.417 32.7765 128.478 32.9519C128.538 33.1273 128.636 33.2874 128.765 33.421C128.893 33.5546 129.05 33.6585 129.222 33.7255C129.395 33.7925 129.581 33.821 129.766 33.8089C130.473 33.7417 131.152 33.4957 131.738 33.094C132.324 32.6923 132.799 32.148 133.117 31.5123C134.325 29.328 134.997 26.8882 135.08 24.3931C135.08 23.4363 134.685 23.3214 134.685 22.5942C134.685 21.3184 137.718 20.5273 140.968 20.5273C142.242 20.5273 142.867 21.0249 142.867 21.9818C142.867 24.1635 140.904 28.4886 140.904 31.4613C140.904 32.9157 141.694 33.7578 142.701 33.7578C143.708 33.7578 144.103 33.3112 144.829 32.584C145.224 32.1885 145.441 32.0226 145.785 32.0226C145.99 32.0259 146.186 32.109 146.331 32.2542C146.476 32.3995 146.559 32.5955 146.563 32.8009C146.48 33.4851 146.165 34.1201 145.67 34.5999C144.852 35.7138 143.791 36.6261 142.568 37.2673C141.344 37.9085 139.991 38.2616 138.61 38.2998C134.188 38.2998 133.678 34.8806 133.678 34.8806Z"
        fill="url(#paint5_linear_3_85550)"
      />
      <path
        d="M149.349 32.7493C149.349 34.14 149.859 35.0969 150.866 35.0969C151.643 35.0969 152.14 34.5993 152.14 33.5914C152.14 30.2742 146.597 29.7638 146.597 25.5663C146.597 22.0322 150.114 20.2461 154.32 20.2461C158.525 20.2461 160.258 21.8154 160.258 24.0736C160.303 24.4411 160.267 24.814 160.154 25.1663C160.04 25.5186 159.851 25.842 159.6 26.1139C159.349 26.3858 159.042 26.5998 158.7 26.741C158.358 26.8822 157.99 26.9472 157.62 26.9315C157.104 26.9386 156.605 26.7442 156.229 26.3893C155.853 26.0345 155.63 25.5472 155.607 25.0305C155.607 24.1884 156.053 23.844 156.053 23.3464C156.053 22.8488 155.607 22.5554 154.995 22.5554C154.543 22.6087 154.127 22.827 153.826 23.1683C153.525 23.5096 153.361 23.95 153.364 24.4053C153.364 27.7225 159.532 28.1691 159.532 32.2518C159.532 35.9007 156.614 38.363 151.682 38.363C147.489 38.363 144.405 36.5131 144.405 33.4893C144.38 33.1332 144.428 32.7759 144.543 32.4383C144.659 32.1008 144.841 31.7897 145.078 31.5235C145.316 31.2573 145.604 31.0412 145.926 30.888C146.248 30.7349 146.597 30.6476 146.953 30.6314C148.47 30.6314 149.362 31.3587 149.362 32.711"
        fill="#FF6767"
      />
      <path
        d="M149.349 32.7493C149.349 34.14 149.859 35.0969 150.866 35.0969C151.643 35.0969 152.14 34.5993 152.14 33.5914C152.14 30.2742 146.597 29.7638 146.597 25.5663C146.597 22.0322 150.114 20.2461 154.32 20.2461C158.525 20.2461 160.258 21.8154 160.258 24.0736C160.303 24.4411 160.267 24.814 160.154 25.1663C160.04 25.5186 159.851 25.842 159.6 26.1139C159.349 26.3858 159.042 26.5998 158.7 26.741C158.358 26.8822 157.99 26.9472 157.62 26.9315C157.104 26.9386 156.605 26.7442 156.229 26.3893C155.853 26.0345 155.63 25.5472 155.607 25.0305C155.607 24.1884 156.053 23.844 156.053 23.3464C156.053 22.8488 155.607 22.5554 154.995 22.5554C154.543 22.6087 154.127 22.827 153.826 23.1683C153.525 23.5096 153.361 23.95 153.364 24.4053C153.364 27.7225 159.532 28.1691 159.532 32.2518C159.532 35.9007 156.614 38.363 151.682 38.363C147.489 38.363 144.405 36.5131 144.405 33.4893C144.38 33.1332 144.428 32.7759 144.543 32.4383C144.659 32.1008 144.841 31.7897 145.078 31.5235C145.316 31.2573 145.604 31.0412 145.926 30.888C146.248 30.7349 146.597 30.6476 146.953 30.6314C148.47 30.6314 149.362 31.3587 149.362 32.711"
        fill="url(#paint6_linear_3_85550)"
      />
      <path
        d="M211.162 40.4077C210.227 40.4208 209.31 40.1432 208.539 39.6131C207.767 39.083 207.179 38.3265 206.855 37.4478C204.928 39.2441 202.425 40.2936 199.794 40.4077C196.494 40.4077 193.805 38.175 193.805 33.8499C193.805 28.3 197.169 22.6352 205.173 22.6352C208.142 22.6352 208.996 23.707 209.875 23.707C210.755 23.707 213.24 22.6352 214.527 22.6352C214.641 22.6179 214.758 22.6278 214.868 22.664C214.978 22.7002 215.078 22.7618 215.16 22.8437C215.242 22.9256 215.303 23.0257 215.339 23.1357C215.375 23.2457 215.385 23.3627 215.368 23.4773C215.368 24.8297 213.405 29.2058 213.405 33.5692C213.368 33.8484 213.389 34.1324 213.466 34.4031C213.544 34.6739 213.677 34.9257 213.856 35.1426C214.036 35.3595 214.258 35.5369 214.51 35.6635C214.761 35.7902 215.036 35.8633 215.317 35.8785C216.26 35.8785 216.706 35.4192 217.445 34.6919C217.828 34.3092 218.057 34.1306 218.388 34.1306C218.597 34.1338 218.796 34.2182 218.943 34.3659C219.091 34.5135 219.175 34.7128 219.178 34.9216C219.178 35.534 218.834 35.9806 218.274 36.7078C217.448 37.8256 216.378 38.74 215.145 39.3811C213.913 40.0223 212.551 40.3735 211.162 40.4077ZM205.682 25.0976C202.484 25.0976 201.082 30.9793 201.082 34.0285C201.082 35.483 201.757 36.6567 202.993 36.6567C204.229 36.6567 205.173 35.7126 205.899 34.0285C206.781 31.6744 207.276 29.1925 207.365 26.6797C207.391 26.4549 207.365 26.2271 207.289 26.0141C207.212 25.801 207.088 25.6085 206.925 25.4516C206.762 25.2947 206.565 25.1777 206.35 25.1096C206.134 25.0416 205.906 25.0244 205.682 25.0594"
        fill="#FF6767"
      />
      <path
        d="M211.162 40.4077C210.227 40.4208 209.31 40.1432 208.539 39.6131C207.767 39.083 207.179 38.3265 206.855 37.4478C204.928 39.2441 202.425 40.2936 199.794 40.4077C196.494 40.4077 193.805 38.175 193.805 33.8499C193.805 28.3 197.169 22.6352 205.173 22.6352C208.142 22.6352 208.996 23.707 209.875 23.707C210.755 23.707 213.24 22.6352 214.527 22.6352C214.641 22.6179 214.758 22.6278 214.868 22.664C214.978 22.7002 215.078 22.7618 215.16 22.8437C215.242 22.9256 215.303 23.0257 215.339 23.1357C215.375 23.2457 215.385 23.3627 215.368 23.4773C215.368 24.8297 213.405 29.2058 213.405 33.5692C213.368 33.8484 213.389 34.1324 213.466 34.4031C213.544 34.6739 213.677 34.9257 213.856 35.1426C214.036 35.3595 214.258 35.5369 214.51 35.6635C214.761 35.7902 215.036 35.8633 215.317 35.8785C216.26 35.8785 216.706 35.4192 217.445 34.6919C217.828 34.3092 218.057 34.1306 218.388 34.1306C218.597 34.1338 218.796 34.2182 218.943 34.3659C219.091 34.5135 219.175 34.7128 219.178 34.9216C219.178 35.534 218.834 35.9806 218.274 36.7078C217.448 37.8256 216.378 38.74 215.145 39.3811C213.913 40.0223 212.551 40.3735 211.162 40.4077ZM205.682 25.0976C202.484 25.0976 201.082 30.9793 201.082 34.0285C201.082 35.483 201.757 36.6567 202.993 36.6567C204.229 36.6567 205.173 35.7126 205.899 34.0285C206.781 31.6744 207.276 29.1925 207.365 26.6797C207.391 26.4549 207.365 26.2271 207.289 26.0141C207.212 25.801 207.088 25.6085 206.925 25.4516C206.762 25.2947 206.565 25.1777 206.35 25.1096C206.134 25.0416 205.906 25.0244 205.682 25.0594"
        fill="url(#paint7_linear_3_85550)"
      />
      <path
        d="M229.47 36.0926C229.47 33.0689 231.267 30.1472 231.267 28.0166C231.276 27.8371 231.248 27.6577 231.183 27.4901C231.118 27.3225 231.019 27.1704 230.891 27.0439C230.764 26.9175 230.611 26.8194 230.443 26.7562C230.275 26.6929 230.095 26.6659 229.916 26.6769C228.744 26.6769 227.507 27.9528 226.399 30.1472C225.254 32.9734 224.462 35.9302 224.041 38.9505C224.041 39.9074 223.646 40.137 222.869 40.137C221.123 40.137 218.892 40.405 217.987 40.405C217.325 40.405 216.93 40.1371 216.93 39.1292C216.93 34.3575 221.862 21.9691 221.862 14.1227C221.862 12.2727 220.906 11.3796 219.733 11.3796C219.224 11.3796 218.892 11.15 218.892 10.7034C218.892 9.52965 221.862 7.67969 225.048 7.67969C228.017 7.67969 229.814 9.24895 229.814 12.043C229.814 15.1944 227.342 23.6022 226.564 26.0773C227.469 25.0367 228.577 24.1922 229.82 23.5954C231.062 22.9987 232.413 22.6623 233.79 22.607C236.862 22.607 238.544 23.9977 238.544 26.4345C238.544 29.2414 236.862 31.4231 236.862 33.9492C236.832 34.1935 236.856 34.4413 236.931 34.6757C237.006 34.91 237.13 35.1254 237.296 35.307C237.462 35.4887 237.665 35.6325 237.891 35.7284C238.118 35.8244 238.362 35.8703 238.608 35.863C239.551 35.863 239.997 35.4037 240.723 34.6764C241.118 34.2937 241.348 34.1151 241.679 34.1151C241.888 34.1184 242.087 34.2028 242.234 34.3504C242.382 34.498 242.466 34.6974 242.469 34.9061C242.469 35.5185 242.125 35.9651 241.564 36.7051C240.736 37.8192 239.665 38.7301 238.433 39.3688C237.201 40.0076 235.84 40.3576 234.453 40.3922C231.038 40.3922 229.47 38.6571 229.47 36.0799"
        fill="#FF6767"
      />
      <path
        d="M229.47 36.0926C229.47 33.0689 231.267 30.1472 231.267 28.0166C231.276 27.8371 231.248 27.6577 231.183 27.4901C231.118 27.3225 231.019 27.1704 230.891 27.0439C230.764 26.9175 230.611 26.8194 230.443 26.7562C230.275 26.6929 230.095 26.6659 229.916 26.6769C228.744 26.6769 227.507 27.9528 226.399 30.1472C225.254 32.9734 224.462 35.9302 224.041 38.9505C224.041 39.9074 223.646 40.137 222.869 40.137C221.123 40.137 218.892 40.405 217.987 40.405C217.325 40.405 216.93 40.1371 216.93 39.1292C216.93 34.3575 221.862 21.9691 221.862 14.1227C221.862 12.2727 220.906 11.3796 219.733 11.3796C219.224 11.3796 218.892 11.15 218.892 10.7034C218.892 9.52965 221.862 7.67969 225.048 7.67969C228.017 7.67969 229.814 9.24895 229.814 12.043C229.814 15.1944 227.342 23.6022 226.564 26.0773C227.469 25.0367 228.577 24.1922 229.82 23.5954C231.062 22.9987 232.413 22.6623 233.79 22.607C236.862 22.607 238.544 23.9977 238.544 26.4345C238.544 29.2414 236.862 31.4231 236.862 33.9492C236.832 34.1935 236.856 34.4413 236.931 34.6757C237.006 34.91 237.13 35.1254 237.296 35.307C237.462 35.4887 237.665 35.6325 237.891 35.7284C238.118 35.8244 238.362 35.8703 238.608 35.863C239.551 35.863 239.997 35.4037 240.723 34.6764C241.118 34.2937 241.348 34.1151 241.679 34.1151C241.888 34.1184 242.087 34.2028 242.234 34.3504C242.382 34.498 242.466 34.6974 242.469 34.9061C242.469 35.5185 242.125 35.9651 241.564 36.7051C240.736 37.8192 239.665 38.7301 238.433 39.3688C237.201 40.0076 235.84 40.3576 234.453 40.3922C231.038 40.3922 229.47 38.6571 229.47 36.0799"
        fill="url(#paint8_linear_3_85550)"
      />
      <path
        d="M240.793 35.7619C240.793 32.1768 242.641 28.5279 242.641 26.512C242.641 25.5551 242.297 25.4403 242.297 24.7131C242.297 23.4372 245.266 22.6462 248.516 22.6462C249.79 22.6462 250.428 23.1438 250.428 24.1007C250.428 26.3462 248.631 29.8675 248.631 33.5674C248.631 35.0346 249.472 35.8767 250.479 35.8767C251.485 35.8767 251.88 35.4174 252.607 34.6902C253.002 34.3074 253.219 34.1288 253.563 34.1288C253.769 34.1353 253.965 34.2211 254.11 34.3684C254.254 34.5157 254.337 34.7132 254.34 34.9198C254.34 35.5322 254.009 35.9788 253.448 36.7188C252.62 37.8345 251.55 38.7474 250.318 39.3883C249.086 40.0293 247.725 40.3816 246.337 40.4187C242.641 40.4187 240.793 38.3391 240.793 35.7619ZM248.236 12.044C248.643 12.0055 249.053 12.0546 249.44 12.188C249.827 12.3215 250.18 12.5362 250.477 12.8177C250.774 13.0993 251.008 13.4412 251.162 13.8204C251.316 14.1997 251.387 14.6076 251.371 15.0167C251.371 17.2622 249.357 19.6735 247.229 19.6735C246.824 19.6803 246.421 19.6071 246.045 19.4581C245.668 19.3092 245.324 19.0874 245.033 18.8054C244.742 18.5235 244.509 18.1868 244.348 17.8148C244.187 17.4427 244.1 17.0425 244.094 16.637C244.116 15.5005 244.547 14.4102 245.308 13.5664C246.069 12.7226 247.109 12.182 248.236 12.044Z"
        fill="#FF6767"
      />
      <path
        d="M240.793 35.7619C240.793 32.1768 242.641 28.5279 242.641 26.512C242.641 25.5551 242.297 25.4403 242.297 24.7131C242.297 23.4372 245.266 22.6462 248.516 22.6462C249.79 22.6462 250.428 23.1438 250.428 24.1007C250.428 26.3462 248.631 29.8675 248.631 33.5674C248.631 35.0346 249.472 35.8767 250.479 35.8767C251.485 35.8767 251.88 35.4174 252.607 34.6902C253.002 34.3074 253.219 34.1288 253.563 34.1288C253.769 34.1353 253.965 34.2211 254.11 34.3684C254.254 34.5157 254.337 34.7132 254.34 34.9198C254.34 35.5322 254.009 35.9788 253.448 36.7188C252.62 37.8345 251.55 38.7474 250.318 39.3883C249.086 40.0293 247.725 40.3816 246.337 40.4187C242.641 40.4187 240.793 38.3391 240.793 35.7619ZM248.236 12.044C248.643 12.0055 249.053 12.0546 249.44 12.188C249.827 12.3215 250.18 12.5362 250.477 12.8177C250.774 13.0993 251.008 13.4412 251.162 13.8204C251.316 14.1997 251.387 14.6076 251.371 15.0167C251.371 17.2622 249.357 19.6735 247.229 19.6735C246.824 19.6803 246.421 19.6071 246.045 19.4581C245.668 19.3092 245.324 19.0874 245.033 18.8054C244.742 18.5235 244.509 18.1868 244.348 17.8148C244.187 17.4427 244.1 17.0425 244.094 16.637C244.116 15.5005 244.547 14.4102 245.308 13.5664C246.069 12.7226 247.109 12.182 248.236 12.044Z"
        fill="url(#paint9_linear_3_85550)"
      />
      <path
        d="M256.526 34.8557C256.526 36.2592 257.023 37.216 258.03 37.216C258.82 37.216 259.304 36.7057 259.304 35.6978C259.304 32.3934 253.761 31.8703 253.761 27.6855C253.761 24.1514 257.291 22.3652 261.484 22.3652C265.677 22.3652 267.422 23.9345 267.422 26.1928C267.467 26.5603 267.431 26.9332 267.318 27.2855C267.204 27.6378 267.015 27.9611 266.764 28.2331C266.513 28.505 266.206 28.7189 265.864 28.8601C265.522 29.0013 265.154 29.0663 264.784 29.0507C264.266 29.0577 263.765 28.8615 263.389 28.5039C263.012 28.1464 262.791 27.6558 262.771 27.1369C262.771 26.3076 263.217 25.9631 263.217 25.4655C263.217 24.9679 262.771 24.6745 262.159 24.6745C261.709 24.7281 261.294 24.9468 260.995 25.2884C260.696 25.63 260.534 26.0704 260.541 26.5245C260.541 29.8289 266.696 30.2755 266.696 34.3709C266.696 38.0198 263.778 40.4822 258.858 40.4822C254.653 40.4822 251.569 38.6322 251.569 35.6085C251.545 35.2524 251.592 34.8951 251.707 34.5575C251.823 34.2199 252.005 33.9089 252.242 33.6427C252.48 33.3764 252.768 33.1604 253.09 33.0072C253.412 32.854 253.761 32.7668 254.117 32.7506C255.621 32.7506 256.526 33.4778 256.526 34.8175"
        fill="#FF6767"
      />
      <path
        d="M256.526 34.8557C256.526 36.2592 257.023 37.216 258.03 37.216C258.82 37.216 259.304 36.7057 259.304 35.6978C259.304 32.3934 253.761 31.8703 253.761 27.6855C253.761 24.1514 257.291 22.3652 261.484 22.3652C265.677 22.3652 267.422 23.9345 267.422 26.1928C267.467 26.5603 267.431 26.9332 267.318 27.2855C267.204 27.6378 267.015 27.9611 266.764 28.2331C266.513 28.505 266.206 28.7189 265.864 28.8601C265.522 29.0013 265.154 29.0663 264.784 29.0507C264.266 29.0577 263.765 28.8615 263.389 28.5039C263.012 28.1464 262.791 27.6558 262.771 27.1369C262.771 26.3076 263.217 25.9631 263.217 25.4655C263.217 24.9679 262.771 24.6745 262.159 24.6745C261.709 24.7281 261.294 24.9468 260.995 25.2884C260.696 25.63 260.534 26.0704 260.541 26.5245C260.541 29.8289 266.696 30.2755 266.696 34.3709C266.696 38.0198 263.778 40.4822 258.858 40.4822C254.653 40.4822 251.569 38.6322 251.569 35.6085C251.545 35.2524 251.592 34.8951 251.707 34.5575C251.823 34.2199 252.005 33.9089 252.242 33.6427C252.48 33.3764 252.768 33.1604 253.09 33.0072C253.412 32.854 253.761 32.7668 254.117 32.7506C255.621 32.7506 256.526 33.4778 256.526 34.8175"
        fill="url(#paint10_linear_3_85550)"
      />
      <path
        d="M184.101 4.72435C189.199 4.60952 193.787 7.21222 193.787 11.9201C193.787 17.0234 189.569 20.1875 185.146 20.7234C188.167 20.9913 192.869 24.5509 192.869 29.0546C192.869 37.7559 186.166 40.2948 180.775 40.2948C176.888 40.2948 174.237 38.3428 173.485 38.3428C172.211 38.3428 169.484 40.2948 167.98 40.2948C167.432 40.2948 167.113 40.0268 167.113 39.5931C167.113 38.8913 167.916 38.5596 168.897 35.9186C169.751 33.5328 172.351 18.6693 173.855 12.8387C174.426 10.5519 175.735 8.51759 177.578 7.05067C179.421 5.58375 181.696 4.76612 184.05 4.72435M179.322 21.693C179.322 21.693 177.105 33.4818 177.105 34.9362C177.091 35.257 177.142 35.5772 177.255 35.8777C177.368 36.1782 177.54 36.4528 177.761 36.6851C177.983 36.9174 178.248 37.1025 178.543 37.2295C178.837 37.3565 179.155 37.4227 179.475 37.4242C181.693 37.4242 184.764 34.6173 184.764 27.0516C184.802 26.3302 184.686 25.6091 184.425 24.9358C184.164 24.2625 183.762 23.6525 183.248 23.1459C182.734 22.6394 182.118 22.248 181.441 21.9975C180.764 21.7471 180.042 21.6433 179.322 21.693ZM186.599 10.7846C186.599 8.61566 183.235 8.43704 182.075 10.5805C180.932 13.2549 180.239 16.1001 180.023 19.001C183.846 19.001 186.599 14.778 186.599 10.7846Z"
        fill="#FF6767"
      />
      <path
        d="M184.101 4.72435C189.199 4.60952 193.787 7.21222 193.787 11.9201C193.787 17.0234 189.569 20.1875 185.146 20.7234C188.167 20.9913 192.869 24.5509 192.869 29.0546C192.869 37.7559 186.166 40.2948 180.775 40.2948C176.888 40.2948 174.237 38.3428 173.485 38.3428C172.211 38.3428 169.484 40.2948 167.98 40.2948C167.432 40.2948 167.113 40.0268 167.113 39.5931C167.113 38.8913 167.916 38.5596 168.897 35.9186C169.751 33.5328 172.351 18.6693 173.855 12.8387C174.426 10.5519 175.735 8.51759 177.578 7.05067C179.421 5.58375 181.696 4.76612 184.05 4.72435M179.322 21.693C179.322 21.693 177.105 33.4818 177.105 34.9362C177.091 35.257 177.142 35.5772 177.255 35.8777C177.368 36.1782 177.54 36.4528 177.761 36.6851C177.983 36.9174 178.248 37.1025 178.543 37.2295C178.837 37.3565 179.155 37.4227 179.475 37.4242C181.693 37.4242 184.764 34.6173 184.764 27.0516C184.802 26.3302 184.686 25.6091 184.425 24.9358C184.164 24.2625 183.762 23.6525 183.248 23.1459C182.734 22.6394 182.118 22.248 181.441 21.9975C180.764 21.7471 180.042 21.6433 179.322 21.693ZM186.599 10.7846C186.599 8.61566 183.235 8.43704 182.075 10.5805C180.932 13.2549 180.239 16.1001 180.023 19.001C183.846 19.001 186.599 14.778 186.599 10.7846Z"
        fill="url(#paint11_linear_3_85550)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3_85550"
          x1="16.3494"
          y1="8.71468"
          x2="14.721"
          y2="39.4779"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7C7C" />
          <stop
            offset="1"
            stopColor="#FF7C7C"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3_85550"
          x1="41.6118"
          y1="12.3919"
          x2="39.8506"
          y2="38.9899"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7C7C" />
          <stop
            offset="1"
            stopColor="#FF7C7C"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3_85550"
          x1="64.0597"
          y1="25.0973"
          x2="60.0289"
          y2="54.7453"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7C7C" />
          <stop
            offset="1"
            stopColor="#FF7C7C"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3_85550"
          x1="86.5344"
          y1="24.2352"
          x2="86.0833"
          y2="38.7501"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7C7C" />
          <stop
            offset="1"
            stopColor="#FF7C7C"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3_85550"
          x1="110.099"
          y1="24.2007"
          x2="109.653"
          y2="38.7265"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7C7C" />
          <stop
            offset="1"
            stopColor="#FF7C7C"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3_85550"
          x1="134.113"
          y1="24.2311"
          x2="133.669"
          y2="38.7303"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7C7C" />
          <stop
            offset="1"
            stopColor="#FF7C7C"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3_85550"
          x1="152.602"
          y1="24.0204"
          x2="151.856"
          y2="38.772"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7C7C" />
          <stop
            offset="1"
            stopColor="#FF7C7C"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3_85550"
          x1="206.914"
          y1="26.3313"
          x2="206.464"
          y2="40.8326"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7C7C" />
          <stop
            offset="1"
            stopColor="#FF7C7C"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint8_linear_3_85550"
          x1="230.125"
          y1="14.4973"
          x2="228.613"
          y2="41.126"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7C7C" />
          <stop
            offset="1"
            stopColor="#FF7C7C"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint9_linear_3_85550"
          x1="247.792"
          y1="17.9452"
          x2="245.658"
          y2="40.9205"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7C7C" />
          <stop
            offset="1"
            stopColor="#FF7C7C"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint10_linear_3_85550"
          x1="259.766"
          y1="26.1395"
          x2="259.02"
          y2="40.8912"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7C7C" />
          <stop
            offset="1"
            stopColor="#FF7C7C"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint11_linear_3_85550"
          x1="180.895"
          y1="12.1319"
          x2="179.184"
          y2="41.0709"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7C7C" />
          <stop
            offset="1"
            stopColor="#FF7C7C"
            stopOpacity="0"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
