export const links = [
  "Genel tanımlar.",
  "Hesabınızı açma",
  "Okyanusbahis hesabının yönetimi",
  "Terminal",
  "Bir Okyanusbahis hesabının kapatılması",
  "Ödeme Kuralları",
  "Sorumluluk sınırlaması",
  "Çözme, hile, sahtekarlık ve suç faaliyeti",
  "Hesabınızın kapatılması; Kullanım Koşullarının Kapatılması ve Fesih Koşullarının Feshi",
  "Bizim tarafımızdan kapatma ve fesih",
  "Bizim tarafından süspansiyon",
  "Hizmetlere erişim ve kullanımı",
  "Web Sitesinin Değiştirilmesi",
  "Üçüncü taraf yazılımı",
  "Bu başarısızlık",
  "Sorumluluğumuzun dışlanması",
  "Kullanım Koşullarının ihlali",
  "Fikri Mülkiyet Hakları",
  "Virüsler, hack ve diğer suçlar",
  "Kişisel Bilgileriniz",
  'Web sitesinde "çerezlerin" kullanılması',
  "Şikayetler ve Bildirimler",
  "Hakların ve yükümlülüklerin devri",
  "Kontrolümüz dışındaki olaylar",
  "Feragat",
  "Koparılabilirlik",
  "Tüm anlaşma",
  "Üçüncü taraf hakları",
  "Hukuk ve yargı yetkisi",
  "Bağlantılar",
  "Site Kullanımı Politikası",
  "Yasal yönler",
  "Oranlar üzerindeki kısıtlamalar",
  "Kullanıcının verilerinin gizliliği",
  "Para birimi",
  "Bonus programı",
  "Mevduat",
  "Hesabına paranın girişi",
  "Ödeme",
  "Oyuncu Olarak Yetkiliniz",
  "Düşük ve iptal edilen oyunlar",
];

export const accountPayoutBonus = [
  {
    expl: [
      "1.     Hizmetleri kullanarak bir oyun oynamak için operatörle (“Okyanusbahis hesabı” veya “hesap”) bir hesap açmanız gerekir.",

      "2.     Hesabınız ya operatör veya kendi adına ve/veya sözleşme yaptığınız ilgili operatör grubu şirketi için grubundaki başka bir şirket tarafından işletilecektir.",

      "3.     Hesabınızı açtığınızda, adınız, doğum tarihiniz ve adres, telefon numarası ve e-posta adresi (“iletişim bilgileriniz”) dahil olmak üzere uygun iletişim bilgileriniz dahil kişisel bilgileri vermeniz istenecektir. Müşteri hizmetleriyle iletişim kurarak iletişim bilgilerinizi zaman zaman güncelleyebilirsiniz; veya web sitesindeki hesap yönetimi sayfam aracılığıyla: veya zaman zaman operatör tarafından sunulacak başka bir yöntemle.",

      "4.     Hesabınızı açarken bunu garanti edersiniz",

      "5.    Hizmetleri kullanarak, para kazanabileceğiniz ve para kazanabileceğiniz riskini anlar ve kabul edersiniz.",

      "6.     Siz: (a) 18 yaşından büyük: ve (b) kumar veya oyun faaliyetlerinin sizin için geçerli olan yasa veya yargı yetkisi kapsamında yasal olduğu yaşın üzerinde (“ilgili yaş”)",

      "7.     Yaşadığınız bölgede kumar yasadışı değildir",

      "8.     Yasal olarak sözleşmelere girebilirsiniz",

      "9.     Kumardan çıkarılmadınız; Ve",

      "10.   Aşağıdaki nedenler kapsamında bizim tarafımızdan kapatma, hile, sahtekarlık ve suç faaliyeti, kullanım koşullarının ihlali veya sorumlu oyun/kumar paragrafı kapsamındaki talebiniz üzerine henüz bir hesabınız yoktu.",

      "11.   Hesabınız kendi, doğru, ad ve kişisel bilgilerinize kaydedilmelidir. Yalnızca sizin için bir kez yayınlanacak ve başka bir kişi, aile, hane halkı, adres (posta veya IP), e -posta adresi, erişim cihazı veya erişim cihazlarının paylaşıldığı herhangi bir ortam (örneğin okullar, işyerleri, halk kütüphaneleri vb.) , Hizmetler açısından bilgisayar (veya başka bir erişim cihazı). Bizimle açtığınız veya hizmetlerle ilgili olarak size ait olduğunuz diğer hesaplar “yinelenen hesaplar” olacaktır. Herhangi bir yinelenen hesabı kapatabiliriz (ancak bunu yapmak zorunda kalmayacağız).",
      "12.   Bir yinelenen hesabı kapatırsak",

      "13.   Bu yinelenen hesap kullanılarak elde edilen bu tür bonuslardan ve ücretsiz bahislerden tahakkuk eden tüm bonuslar, ücretsiz bahisler ve kazançlar, geçersiz olacak ve sizin tarafınızdan kaybedilecektir.",

      "14.   Tüm kendi takdirimize bağlı olarak, tüm kazançları geçersiz kılabilir ve bu yinelenen hesapla ilgili olarak yapılan tüm depozitoları (boş kazançlarla ilgili daha az miktar) ve bizim tarafımızdan alakalı kopya hesaptan kurtarılmadığı ölçüde, herhangi bir miktarda Yinelenen bir hesapla ilgili olarak bize geri ödeme yapmak bizim tarafımızdan doğrudan hesaplarınızdan (diğer yinelenen hesaplar dahil) kurtarılabilir; veya ",

      "15.    Tüm kendi takdirimize bağlı olarak, kopya hesabının geçerli sayılmasına izin verebiliriz, bu durumda, yinelenen hesap aracılığıyla veya sizin için verilen tüm kayıplar ve riskler bizim tarafımızdan korunacaktır.",
    ],
  },
  {
    title: "MANAGEMENT OF THE casinoName ACCOUNT",
    expl: [
      " 1.     casinoName reserves the right at its own discretion and always, to:",
      "       b) Decline to accept deposits without any explanation whatsoever;",
      "       c) Request documents to verify: (i) the identity of the Account Holder, (ii) his/her authorization to use a specific Card and/or (iii) other facts and information provided by the Account Holder. Such request may be made at any given moment and *** reserves the right to suspend an account pending investigation;",
      "       d) Transfer and/or license, without prior notice, data regarding an Account Holder to any other legal entity, in any country, ultimately managed and controlled by R.Bostock Enterprises.BV, subject to casinoName guaranteeing that the said data always is transferred and managed in accordance with applicable laws, data protection acts and/or similar;",
      "       e) Transfer and/or license, without prior notice, the rights and liabilities regarding an Account Holder to any other legal entity, in any country, ultimately managed and controlled by R.Bostock Enterprises B.V, subject to casinoName guaranteeing the liabilities being honoured;",
      "       f) Hold and manage funds belonging to Account Holders in accordance with generally accepted guidelines for cash management regarding such funds; this may include a Financial Institution and/or a Payment Solution Provider being entrusted to hold funds in the name of and/or for the benefit of Account Holders;",
      "       g) Forfeit and/or confiscate funds available on a casinoName Account and/or refuse to honor a claim, in the event that, directly or indirectly: (i) the casinoName Rules have been violated; and/or (ii) other unauthorised activities have occurred in connection with a betting event and/or the operation of a casinoName Account (such as, but not limited to, breach of the law or other regulations, breach of a third party’s rights, fraud, and cheating);",
      "       h) Suspend and/or cancel the participation of an Account Holder in the games, promotional activities, competitions or other services, whenever casinoName believes there are legitimate concerns that a casinoName Account is, has been, or may be used for illegal, fraudulent or dishonest practices;",
      "       i) Suspend and/or cancel the participation of the Account Holder in the Services, and/or forfeit and/or confiscate funds available on their casinoName Account if the Account Holder is found cheating, or if it is determined by casinoName that the Account Holder has employed or made use of a system (including machines, robots, computers, software or any other automated system) designed to defeat or capable of defeating the Client Application and/or Software.",
      "2.     casinoName is committed to detect and prevent software programs which are designed to enable artificial intelligence (“AI Software”) to play on its website(s) including but not limited to opponent-profiling, player collusion; robots, other ‘cheating’ software or anything else that in our reasonable opinion distorts normal game play and enables the player to have an unfair advantage over other players. You acknowledge that casinoName will take measures to detect and prevent the use of such programs and AI Software using methods (including but not limited to reading the list of currently running programs on a player’s computer) and the customer agrees not to use any AI Software and/or any such programs.",
      "3.     casinoName warrants at all times to:",
      "       a) Manage funds belonging to Account Holders in a secure and appropriate manner; and/or",
      "       b) Absorb the cost of and pay the Gaming and Betting Duty, as applicable, at the Place of the Contract;",
      "       c) Manage data regarding an Account Holder in accordance with applicable laws, data protection acts and/or similar;",
      "       d) Not offer contingencies to customers to proceed to any fund transfer between customers’ accounts.",
      "4.     casinoName shall keep Account Holders’ funds separate from casinoName’s own funds in a client account held with a Financial Institution approved by the Regulator.",
      "5.      casinoName Account does not accrue interest. The Account Holder shall not treat casinoName as a financial institution.",
      "6.     An Account Holder can only hold one casinoName Account at a time. In the event that this rule is breached, casinoName reserves the right to block and/or delete the superfluous casinoName Account(s) held by the Account Holder in breach of this clause, and reallocate all the funds to a single casinoName Account. No bonus given to the superfluous casinoName Account(s) will be reallocated.",
      "7.     A casinoName Account is non-transferable. It is prohibited for players to sell, transfer or acquire accounts from or to other players. Funds cannot be transferred between casinoName accounts.",
      "8.     An Account Holder shall not allow any other individual, including any minor, to use or reuse its casinoName Account, access and/or use any material or information from the Website, accept any Prize, or access and/or participate in the Services.",
      "How do I verify my account?",
      "Before you can make a withdrawal, you have to verify your account. To do so you need to send an e-mail to us, providing us with the following documents:",
      "Proof of identity: A photo copy of your ID (national ID, driver’s license or passport)",
      "Proof of address: A copy of an official letter (EX. government or bank issued letter)",
      "If you use a credit card, we also need:",
      "A photo copy of the front and back of your credit card (only the first 6 and last 4 numbers of the card number needs to be visible).",
      "How do I know if my documents are approved?",
      "After you’ve supplied us with the documents it’ll take up to 12 hours before you’ll receive a confirmation of the documents either being approved or what further information are required.",
      "Why do I need to give our personal information to register an account at casinoName?",
      "For us at casinoName to provide you with the best service and highest possible security we need to be able to identify our members, especially before any winning amounts can be paid out. For that reason we need members full name and home address. To verify that players are of legal age to gamble online we also need birth date. Access to your email adders ensures that we can keep you informed of any important news, promotions or information needed if you’ve forgot your password. You can rest assure that your information is kept safe and no third party will have access to it.",
    ],
  },
  {
    title: "CHARGEBACK",
    expl: [
      "1.     Subject to the sub-clauses below and without prejudice to casinoName’s right to seek redress under any applicable legislation, regulation, enactment or policy, or under any other provision of the casinoName Rules, casinoName shall have the right to block a casinoName Account when a chargeback has been requested in relation to that casinoName Account.",
      "2.     When a chargeback has been requested, casinoName will send a “Chargeback Notice” to the Account Holder at the email address mentioned in the Account Holder’s details, in order to seek confirmation of the Account Holder’s identity and of the payment method used to credit to the Account Holder’s casinoName Account any funds entirely unrelated to a chargeback (“Untainted Funds”).",
      "3.     In the absence of confirmation by the Account Holder of the Account Holder’s identity and of the payment method used to credit Untainted Funds to the Account Holder’s casinoName Account, following a Chargeback Notice, casinoName will send two written reminders to the Account Holder at the email available to it, each of which will be subject to a processing fee of fifty (50) EURO drawn on any Untainted Funds.",
      "4.     Where an casinoName Account has been blocked due to a chargeback and the Account Holder has not:",
      "1.     a) logged in to the casinoName Account for a period of thirty (30) consecutive months; or",
      "2.     b) confirmed to casinoName his identity and the details of the payment method used to credit Untainted Funds to the Account Holder’s casinoName Account and then requested a withdrawal; any Untainted Funds on the casinoName Account will be treated as though they were funds on an Inactive Account and casinoName will remit the balance on the casinoName Account of the Account Holder.",
    ],
  },
  {
    title: "CLOSURE OF AN casinoName ACCOUNT",
    expl: [
      "1.     An Account Holder may close their casinoName account at any time by contacting casinoName’s Customer Support using the contact details provided in the “Get Help” section on the Website (www.OkyanusBahis.com/support), either by phone or email. Any funds in the casinoNamebet Account will be remitted to the Account Holder.",
      "2.     Should an existing casinoName Account be closed, any obligations already entered into will be honoured.",
      "3.     Account Holders who wish to recover funds held in a closed, blocked or excluded account are advised to contact Customer Support.",
      "4.     In case of closure of their casinoName Account due to gambling addiction or fraud, an individual must not open a new casinoName Account. casinoName will not be liable should the individual succeed in opening a new account, nor for any direct or indirect consequential damages. casinoName reserves the right to close an account opened in breach of this rule at any point. ",
    ],
  },
  {
    title: "PAYMENT RULES",
    expl: [
      "1.    Deposits to and withdrawals from a casinoName Account shall at all times be made through a Financial Institution or a Payment Solution Provider. Procedures, terms and conditions, availability, and duration for deposits/withdrawals may vary depending on time as well as the country and Financial Institution in question. Current advice is available when logged in on the Website under the headings “Deposit” or “Withdrawal”.",
      "2.    casinoName may appoint a Payment Solution Provider to act, receive deposits, hold and manage funds, and/or expedite withdrawals, on behalf of casinoName.",
      "3.    casinoName does not accept cash funds sent or delivered directly to casinoName or a Payment Solution Provider.",
      "4.    casinoName will credit to the casinoName Account all funds received by casinoName from or on behalf of the Account Holder, or owed by casinoName to the Account Holder.",
      "5.    Method of payment/withdrawal from/to casinoName Account",
      "6.    An Account Holder is only allowed to:",
      "   a) Make deposits to his casinoName Account with his personal Credit Card or via his personal account created with one of the Financial Institutions or their licensees;",
      "   b) Once per day, request withdrawals of funds held on his casinoName Account to his/her personal Credit Card or his/her personal account created with one of the Financial Institutions or their licensees.",
      "7.     An Account Holder is responsible for providing casinoName with the correct details of his/her personal account for the purpose of withdrawals from his/her casinoName Account.",
      "8.     An Account Holder must not allow third parties to use his/her casinoName Account to make deposits to or withdrawals from his/her casinoName Account.",
      "9.      It is the Account Holder’s sole responsibility to ensure that he/she complies with the above provisions. ",
      "10.   casinoName shall not accept a wager from an Account Holder unless a casinoName Account has been established in the name of the Account Holder and there are adequate funds in the casinoName Account to cover the amount of the wager, or funds necessary to cover the amount of the wager are provided in an approved manner.",
      "11.   casinoName shall not deal with the credit balance of a casinoName Account except:",
      "       a) to debit from the casinoName Account a wager made by the Account Holder or an amount the Account Holder indicates they want to wager in the course of a game they are playing or about to play;",
      "       b) to remit funds standing to the credit of the casinoName Account to the Account Holder, at the Account Holder’s request, in terms of regulation 37 of the Remote Gaming Regulations;",
      "       c) to pay reasonable bank charges for deposits received and funds withdrawn; or",
      "       d) as otherwise authorised by the Remote Gaming/Gambling Regulations.",
      "12.   The balance of a casinoName Account may turn negative in case of chargeback.",
      "13.   Withdrawals from a casinoName Account are made through payments addressed to the Account Holder or transferred to a bank account held in the name of the Account Holder, as advised by the Account Holder. Whenever possible, casinoName will restrict withdrawals to be made only to the same account utilised by the Account Holder to make deposits.",
      "14.   When an Account Holder wishes to withdraw funds they have deposited into their casinoName Account but have not wagered in any game or sport betting offer, casinoName reserves the right to refuse to transfer the funds to another bank account than the one the funds originated if casinoName suspects fraud and/or money laundering.",
      "15.   Depending on the payment method chosen by the Account Holder, minimum and/or maximum deposit limits may apply. The withdrawals will be remitted only to the same account from which the funds originated.",
      "16.   casinoName reserves the right to charge the Account Holder for administrative costs resulting from withdrawals made by the Account Holder, as indicated on the Website.",
      "17.    Placing a bet through the Internet may be illegal in the jurisdiction in which an Account Holder is resident and/or domiciled; if so, the Account Holder is not authorised to use a Card for the purpose of placing a bet",
      "18.   The participation of an Account Holder in the Services in a jurisdiction where such participation is prohibited by law shall not affect any stakes or payment made to and accrued for the benefit of casinoName.",
      "19.   casinoName, or Governing Authority can monitor or request to review all transactions to prevent money laundering. All suspicious transactions detected by casinoName will be reported to the Governing Authorities",
      "20.   All transactions are checked to prevent money laundering.",
      "21.   It is the sole responsibility of the Account Holder to pay and proceed with all necessary diligence in relation to taxes on any Prize, if and where applicable.",
      "22.   It is unlawful to deposit money from ill-gotten means",
      "23.   With initiative of Finance / Accounting Department, users may be redirect for different payment methods.",
      "24. Players must wager all their deposits once, on odds of 1.30 or above. The deposited amount cannot be withdrawn without completing the deposit wager within the specified limits. This rule applies to the amount transferred through the method Send to a friend .(Wagering is not required when sending to a friend, but wagering is required after transferring to a friend.) This rule exists to prevent fraud and money laundering.",
    ],
  },
];

export const contactData = {
	email: "support@okyanusbahis.com"
}



export const contactSupport = [
  { title: "Email", text: "support@okyanusbahis.com" },
];

export const ourFeatures = [
  {
    img: "search3Icon",
    title: "Guarantee",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    img: "spadeTokenIcon",
    title: "Best Games",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    img: "supportIcon",
    title: "Support",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    img: "scheduleIcon",
    title: "Favorite Matches",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
];

export const aboutUsTitle = ["About us"];
export const aboutUsSpan = ["casinoName.com"];
export const aboutUsDescripton = [
  "offers a range of products to it's visitors to play and enjoy their spent time here . Your favorite games and matches. Here you can find them all. Due to our professional and experienced specialists we assure you that you will find convenience and comfort while playing and enjoying Your time. Also our 24/7 support team is always ready to help you.",
];
export const aboutUsBtn = ["Play now"];

