import { Images } from "../../../../Images";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const SupportRight = () => {
  const { t } = useTranslation();
  const [displayNone, setDisplayNone] = useState(null);
  const { questionMark, addIcon } = Images;
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
	
	const frequentlyAskedQuestions = [
		{
			question: t("faq_q_1"),
			answer: t("faq_a_1"),
		},
		{
			question: t("faq_q_2"),
			answer: t("faq_a_2"),
		},
		{
			question: t("faq_q_3"),
			answer: t("faq_a_3"),
		},
		{
			question: t("faq_q_4"),
			answer: t("faq_a_4"),
		},
		{
			question: t("faq_q_5"),
			answer: t("faq_a_5"),
		},
	];

  const questions = frequentlyAskedQuestions.map((x, index) => {
    return (
      <div key={x.question}>
        <div className="support-faq-block">
          <button onClick={() => setDisplayNone((prev) => (prev === index ? null : index))} className="support-faq-quest">
            <img loading="lazy" width="14" height="14" src={addIcon} className={displayNone !== index ? "" : "active"} alt="icon" />
            <p>{x.question}</p>
          </button>
          <div className={`support-faq-answ ${displayNone !== index ? "" : "active"}`}>{x.answer}</div>
        </div>
        <div className="support-faq-divider"></div>
      </div>
    );
  });

  return (
    <div className="support-faq">
      <div className="support-faq-header">
        <img
          className={themeMode === "light" ? "black-image" : ""}
          width="26"
          height="26"
          loading="lazy"
          src={questionMark}
          alt="question icon"
        />
        <p>{t("Faq")}</p>
      </div>
      <div className="support-faq-divider"></div>
      {questions}
    </div>
  );
};

export default SupportRight;
