import { MENU_KEYS } from "../Route/navigation";
import routes from "../Route/URLs";
import { Images } from "../Images";
import { apiVars } from "../api";

export const sidebarRoutes = [
  {
    name: "Home",
    key: MENU_KEYS.HOME_PAGE,
    url: routes.HOME_PAGE,
    icon: Images.homeLink,
  },
  {
    name: "Casino",
    key: MENU_KEYS.CASINO,
    url: routes.CASINO,
    icon: Images.casinoLink,
  },
  {
    name: "Live Casino",
    key: MENU_KEYS.LIVE_CASINO,
    url: routes.LIVE_CASINO,
    icon: Images.livecasinoLink,
  },
  {
    name: "Games",
    key: MENU_KEYS.GAMES,
    url: routes.GAMES,
    icon: Images.gamesLink,
  },
  {
    name: "Sports",
    key: MENU_KEYS.SPORTS,
    url: routes.SPORTS,
    icon: Images.sportLink,
  },
  {
    name: "Poker",
    key: MENU_KEYS.POKER,
    url: apiVars.pokerUrl,
    icon: Images.pokerLink,
  },
  {
    name: "In-Play",
    key: MENU_KEYS.INPLAY,
    url: routes.INPLAY,
    icon: Images.inplayLink,
  },
];
