import CommonFooterTermsAndConditions from "./CommonFooterTermsAndConditions";
import { useTranslation } from "react-i18next";

export default function AccountPayoutAndBonus() {
	const { t } = useTranslation();
	
  return (
		<div>
			<CommonFooterTermsAndConditions 
				title={t("terms_service_title")} 
				data={t("terms_service_text")} 
			/>
		</div>
  );
};