import { Images } from "../../../Images";
// eslint-disable-next-line no-unused-vars

export const bannerData = [
  {
    title: "Site banner image 1",
    text: "",
    imgs: [Images.sportsWelcomeBannerEn, Images.sportsWelcomeBannerTr],
    imgsLoggedIn: [Images.sportsWelcomeBannerEnPlay, Images.sportsWelcomeBannerTrPlay],
    ProductId: "",
  },
  {
    title: "Site banner image 2",
    text: "",
    imgs: [Images.slotsAndCasinoBannerEn, Images.slotsAndCasinoBannerTr],
    imgsLoggedIn: [Images.slotsAndCasinoBannerEnPlay, Images.slotsAndCasinoBannerTrPlay],
    ProductId: "",
  },
  {
    title: "Site banner image 3",
    text: "",
    imgs: [Images.casinoWelcomBannerEn, Images.casinoWelcomBannerTr],
    imgsLoggedIn: [Images.casinoWelcomBannerEnPlay, Images.casinoWelcomBannerTrPlay],
    ProductId: "",
  },
  // {
  //   title: "Blinged",
  //   text: "",
  //   imageDesktop: "/images/1.jpg",
  //   imageMobile: Images.blingedBannerMobile,
  //   ProductId: 25144,
  // },
  // {
  //   title: "Jack Hammer 2",
  //   text: "",
  //   imageDesktop: Images.hammerBannerDesktop,
  //   imageMobile: Images.hammerBannerMobile,
  //   ProductId: 17022,
  // },
  // {
  //   title: "Pimped",
  //   text: "",
  //   imageDesktop: Images.pimpedBannerDesktop,
  //   imageMobile: Images.pimpedBannerMobile,
  //   ProductId: 27014,
  // },
  // {
  //   title: "White King",
  //   text: "",
  //   imageDesktop: Images.whiteKingBannerDesktop,
  //   imageMobile: Images.whiteKingBannerMobile,
  //   ProductId: 78413,
  // },
  // {
  //   title: "Thunder Struck",
  //   text: "",
  //   imageDesktop: Images.thunderBannerDesktop,
  //   imageMobile: Images.thunderBannerMobile,
  //   ProductId: 29805,
  // },
  // {
  //   title: "Wolf Gold",
  //   text: "",
  //   imageDesktop: Images.wolfGoldBannerDesktop,
  //   imageMobile: Images.wolfGoldBannerMobile,
  //   ProductId: 54060,
  // },
  // {
  //   title: "Crazy Time",
  //   text: "",
  //   image: Images.crazyTimeBanner,
  //   ProductId: 21000,
  // },
];
