import { Images } from "../../Images";
import { useTranslation } from "react-i18next";
import { checkPathname } from "../../utils/classNameManager";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export default function FooterSlogan() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div
      className="footer-slogan"
      style={{
        background: `url(${checkPathname(location.pathname) ? Images.safeBetterBgRed : Images.safeBetterBg}) center no-repeat`,
        backgroundSize: "cover",
      }}>
      <div className="footer-slogan-content">
        {checkPathname(location.pathname) ? (
          <img
            className="footer-slogan-logo"
            width="165"
            height="60"
            src={Images.footerLogoRed}
            alt="logo"
          />
        ) : (
          <img
            className="footer-slogan-logo"
            width="165"
            height="60"
            src={Images.footerLogo}
            alt="logo"
          />
        )}
        <div className="">
          <div className="footer-slogan-title">{t("SAFER.BETTER.TOGETHER")}</div>
          <div className="footer-slogan-text">{t("Playing responsible with limits")}</div>
        </div>
      </div>
    </div>
  );
}
