import { Images } from "../../../Images";

export const LiveCasino = [
	{
		url: Images.pragmaticLive,
		keyName: "Pragmatic Play",
		ProductId: 54230,
	},
	{
		url: Images.evolutionLive,
		keyName: "Evolution",
		ProductId: 21000,
	},
	{
		url: Images.ezugiLive,
		keyName: "Ezugi",
		ProductId: 5001,
	},
	{
		url: Images.vivoGamingLive,
		keyName: "Vivo Gaming",
		ProductId: 75697,
	},
	{
		url: Images.monopolyLive,
		keyName: "Monopoly",
		ProductId: 21000,
	},
	{
		url: Images.sweetBonanzaLive,
		keyName: "Sweet Bonanza CandyLand",
		ProductId: 54230,
	},
	{
		url: Images.teenPattiLive,
		keyName: "Teen Patti",
		ProductId: 75697,
	},
	{
		url: Images.lightRouletteLive,
		keyName: "Lightning Roulette",
		ProductId: 21000,
	},
	{
		url: Images.crazyTimeLive,
		keyName: "Crazy Time",
		ProductId: 21000,
	},
	{
		url: Images.boomCityLive,
		keyName: "Boom City",
		ProductId: 54230,
	},
]

export const Megaways = [
	{
		url: Images.Starz,
		ProductId: 25341,
		keyName: "Starz Megaways",
	},
	{
		url: Images.BigBadWolf,
		ProductId: 75100,
		keyName: "Big Bad Wolf Megaways",
	},
	{
		url: Images.DragonMatch,
		ProductId: 29858,
		keyName: "Dragon Match Megaways",
	},
	{
		url: Images.AztecGold,
		ProductId: 29352,
		keyName: "Aztec Gold Extra Gold Megaways",
	},
	{
		url: Images.Fruitshop,
		ProductId: 17644,
		keyName: "Fruitshop Megaways",
	},
	{
		url: Images.Morgana,
		ProductId: 25822,
		keyName: "Morgana Megaways",
	},
	{
		url: Images.Moriarty,
		ProductId: 29566,
		keyName: "Moriarty Megaways",
	},
	{
		url: Images.Royale,
		ProductId: 29608,
		keyName: "Royale with Cheese Megaways",
	},
	{
		url: Images.Scrooge,
		ProductId: 75344,
		keyName: "Scrooge Megaways",
	},
	{
		url: Images.TyrantKing,
		ProductId: 75119,
		keyName: "Tyrant King Megaways",
	},
];

export const SweetTreat = [
	{
		url: Images.Sweets,
		ProductId: 25017,
		keyName: "Super Sweets",
	},
	{
		url: Images.Alchemy,
		ProductId: 26993,
		keyName: "Sweet Alchemy",
	},
	{
		url: Images.SugarPop,
		ProductId: 76535,
		keyName: "Sugar Pop",
	},
	{
		url: Images.SugarDrop,
		ProductId: 27759,
		keyName: "Sugar Drop",
	},
	{
		url: Images.SugarRush,
		ProductId: 76426,
		keyName: "Sugar Rush",
	},
	{
		url: Images.SweetBonanza,
		ProductId: 54002,
		keyName: "Sweet Bonanza", //&shy;
	},
	{
		url: Images.SweetieLand,
		ProductId: 27732,
		keyName: "Sweetie Land",
	},
	{
		url: Images.SweetSugar,
		ProductId: 29478,
		keyName: "Sweet Sugar",
	},
	{
		url: Images.SweetWin,
		ProductId: 25184,
		keyName: "Sweet Win",
	},
	
];

export const TameDragon = [
	{
		url: Images.DragonHorn,
		ProductId: 25888,
		keyName: "Dragon Horn",
	},
	{
		url: Images.DragonKings,
		ProductId: 76276,
		keyName: "9 Dragon Kings",
	},
	{
		url: Images.DragonHoldAndSpin,
		ProductId: 54094,
		keyName: "Dragon Hot Hold & Spin",
	},
	{
		url: Images.Dragon,
		ProductId: 28577,
		keyName: "24K Dragon",
	},
	{
		url: Images.DoubleDragons,
		ProductId: 27332,
		keyName: "Double Dragons",
	},
	// {
	// 	url: Images.DoubleDragons, 
	// 	ProductId: 67228,
	// 	keyName: t("Twin Dragons"),
	// },
	
	{
		url: Images.DragonShrine,
		ProductId: 27530,
		keyName: "Dragon Shrine",
	},
	{
		url: Images.FloatingDragon,
		ProductId: 54097,
		keyName: "Floating Dragon",
	},
	{
		url: Images.LuckyDragon,
		ProductId: 54153,
		keyName: "Lucky Dragon",
	},
	{
		url: Images.DragonWatch,
		ProductId: 66590,
		keyName: "Dragon Watch",
	},
];

export const MagicMoments = [
	{
		url: Images.HulaMagic,
		ProductId: 75316,
		keyName: "Hula Magic",
	},
	{
		url: Images.LeprechaunMagic,
		ProductId: 76591,
		keyName: "Leprechaun's Magic Power Reels",
	},
	{
		url: Images.MagicCharm,
		ProductId: 67675,
		keyName: "Magic Charm",
	},
	{
		url: Images.MagicMerlin,
		ProductId: 28805,
		keyName: "Magic Merlin Megaways",
	},
	{
		url: Images.MagicCauldron,
		ProductId: 54115,
		keyName: "The Magic Cauldron",
	},
	{
		url: Images.JadeMagician,
		ProductId: 27033,
		keyName: "Jade Magician",
	},
	
	{
		url: Images.MagicForest,
		ProductId: 27359,
		keyName: "Magic Forest",
	},
	{
		url: Images.MagiciansSecrets,
		ProductId: 54313,
		keyName: "Magician’s Secrets",
	},
	{
		url: Images.Magicious,
		ProductId: 25906,
		keyName: "Magicious",
	},
	{
		url: Images.MagicRings,
		ProductId: 26733,
		keyName: "Magic of The Ring",
	},
];

export const NewGame = [
	{
		url: Images.TyrantKing,
		ProductId: 75119,
		keyName: "Tyrant King Megaways",
	},
	{
		url: Images.SweetieLand,
		ProductId: 27732,
		keyName: "Sweetie Land",
	},
	{
		url: Images.Magicious,
		ProductId: 25906,
		keyName: "Magicious",
	},
	{
		url: Images.LuckyDragon,
		ProductId: 54153,
		keyName: "Lucky Dragon",
	},
	{
		url: Images.MagiciansSecrets,
		ProductId: 54313,
		keyName: "Magician’s Secrets",
	},
	{
		url: Images.DragonShrine,
		ProductId: 27530,
		keyName: "Dragon Shrine",
	},
	{
		url: Images.MagicForest,
		ProductId: 27459,
		keyName: "Magic Forest",
	},
];

export const BookClub = [
	{
		url: Images.BookDuat,
		ProductId: 75292,
		keyName: "Book of Duat",
	},
	{
		url: Images.BookDead,
		ProductId: 27066,
		keyName: "Book of Dead",
	},
	{
		url: Images.BookDarkness,
		ProductId: 28553,
		keyName: "Book of Darkness",
	},
	{
		url: Images.BookAztec,
		ProductId: 28683,
		keyName: "Book of Aztec",
	},
	{
		url: Images.BookSkulls,
		ProductId: 29976,
		keyName: "Book of Skulls",
	},
	{
		url: Images.BookImmortals,
		ProductId: 29901,
		keyName: "Book of Immortals",
	},
	{
		url: Images.BookFortune,
		ProductId: 27138,
		keyName: "Book of Fortune",
	},

	{
		url: Images.BookFruits,
		ProductId: 29555,
		keyName: "Book of Fruits 10",
	},
	{
		url: Images.BookKingdoms,
		ProductId: 54080,
		keyName: "Book of Kingdoms",
	},
	{
		url: Images.BookTombs,
		ProductId: 28685,
		keyName: "Book of Tombs",
	},
];

export const Top10games = [
	{
		url: Images.ChiliPop,
		ProductId: 25006,
		keyName: "ChilliPop",
	},
	{
		url: Images.InvisibleManGif,
		ProductId: 69516,
		keyName: "The Invisible man",
	},
	{
		url: Images.HugoCarts,
		ProductId: 29960,
		keyName: "Hugo Carts",
	},
	{
		url: Images.YetiGigablox,
		ProductId: 75262,
		keyName: "90k Yeti Gigablox",
	},
	{
		url: Images.Deadwood,
		ProductId: 25312,
		keyName: "Deadwood",
	},
	{
		url: Images.RazorShark,
		ProductId: 29991,
		keyName: "Razor Shark",
	},
	{
		url: Images.JumboStampede,
		ProductId: 29905,
		keyName: "Jumbo Stampede",
	},
	{
		url: Images.SweetBonanzagif,
		ProductId: 54002,
		keyName: "Sweet Bonanza",
	},
	{
		url: Images.EsqueletoExplosivo,
		ProductId: 25914,
		keyName: "Esqueleto Explosivo",
	},
	{
		url: Images.ChaosCrew,
		ProductId: 66475,
		keyName: "Chaos Crew",
	},
];

