import { Modal } from "antd";
import browser from "browser-detect";
import { useContext, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { saveActivity } from "../../../../../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../../../../Components/Common/getIp";
import { currency_symbols } from "../../../../../Components/Navbar/constantNavBar";
import { getItemFromLocalStorage } from "../../../../../utils/localStorage";
import { GetClientBalanceForWithdrawSuccess, withdraw, withdrawSuccess } from "../../../Services/actions";
import { useTranslation } from "react-i18next";
import CasherNav from "../../../context/context";
import axios from "axios";
import { handleClientBalance } from "../../../lib/constants";
import { apiVars } from "../../../../../api";
import { Images } from "../../../../../Images";
import WithdrawFormHeader from "./components/WithdrawFormHeader";
import { useForm, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import Select from "react-select";
import { styles } from "./components/selectStyles";

const browserInfo = browser();
const Withdraw = (props) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();
  const { withdraw, GetClientBalanceForWithdrawSuccess, withdrawSuccess } = props;
  const CurrencyId = getItemFromLocalStorage("CurrencyId");
  const [errMsg, setErrMsg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [accountType, setaccountType] = useState([]);
  const [transactionUrl, setTransactionUrl] = useState("");
  const [bankNames, setBankNames] = useState([]);

  let ClientBalance = useSelector((state) => state?.cashierReducer?.clientWithrawBalance?.data?.ResponseObject);
  const { selectedCurrency } = useContext(CasherNav);

  let Token = getItemFromLocalStorage("Token");
  let ClientId = getItemFromLocalStorage("Id");
  let SelectedCard = useSelector((state) => state?.cashierReducer?.selectedCard);
  const LanguageId = getItemFromLocalStorage("i18nextLng") === "en-GB" ? "en" : getItemFromLocalStorage("i18nextLng");

  const isCryptoMethod = SelectedCard?.PaymentSystemName !== "FugaPayPayFix" && SelectedCard?.PaymentSystemName !== "FugaPayBankTransfer" && SelectedCard?.PaymentSystemName !== "FugaPayPapara" && SelectedCard?.PaymentSystemName !== "Praxis";
  const isNowMethod = SelectedCard.PaymentSystemName === "NOWPay";
  const isFugaMethod = SelectedCard.PaymentSystemName === "FugaPayPayFix" || SelectedCard.PaymentSystemName === "FugaPayBankTransfer" || SelectedCard.PaymentSystemName === "FugaPayPapara";

  function onSubmit(data) {
    WithdrawMoney(data);
  }

  function resetForm() {
    reset();
    setValue("accountType", "");
    setValue("bankName", "");
  }

  const WithdrawMoney = async (data) => {
    setErrMsg("");
    if (data.amount < SelectedCard.MinAmount) {
      return setErrMsg(`Amount should not be less than ${SelectedCard.MinAmount}`);
    }
    if (data.amount > SelectedCard.MaxAmount) {
      return setErrMsg(`Amount should not be greater than ${SelectedCard.MaxAmount}`);
    }

    let payload = {
      Loader: true,
      Controller: "Document",
      Method: "CreatePaymentRequest",
      Token: Token,
      ClientId: ClientId,
      PartnerId: SelectedCard?.PartnerId,
      RequestData: JSON.stringify({
        PartnerId: SelectedCard?.PartnerId,
        Amount: data.amount,
        Info: JSON.stringify({
          Amount: data.amount,
          BankId: data.bankName && isFugaMethod ? data.bankName.value : "",
          BankName: "",
          BankAccountNumber: isFugaMethod ? data.bankAccountNumber : "",
          AccountType: data.accountType && isNowMethod ? data.accountType.value : "",
          NameSurname: "",
          WithdrawCode: "",
          CardType: "",
          Name: "",
          CardNumber: "",
          ExpDate: "",
          ExpDateMM: "",
          ExpDateYY: "",
          Info: "",
          MobileNumber: "",
          SMSCode: "",
          CardHolderName: "",
          ExpirationDate: "",
          Email: "",
          BankACH: "",
          BankAccountHolder: isFugaMethod ? data.bankAccountHolder : "",
          BankBranchName: "",
          WalletNumber: data.walletAddress && isCryptoMethod ? data.walletAddress : "",
          NationalId: "",
          TypeDocumentId: "",
        }),
        PaymentSystemId: SelectedCard?.PaymentSystemId,
        PaymentForm: "",
        ImageName: "",
        ClientId: ClientId,
        CurrencyId: SelectedCard?.CurrencyId,
        CashCode: "",
        BetShopId: "",
      }),
      TimeZone: 0,
    };
    await withdraw(payload);
    resetForm();

    let deviceIp = await getDeviceIP();
    saveActivity({
      EventPage: "Cashier",
      EventType: "Withdraw_Confirm",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: SelectedCard?.PaymentSystemId,
      attribute3: SelectedCard?.CurrencyId,
      attribute4: data.amount,
      attribute5: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });

    handleClientBalance().then((res) => {
      if (res.status === 200) {
        GetClientBalanceForWithdrawSuccess(res);
      }
    });
  };

  const ResponseObject = useSelector((state) => state?.cashierReducer?.withdraw);

  function findBalanceFromAccount(accountTypeName) {
    return ClientBalance?.Accounts.find((x) => x.AccountTypeName === accountTypeName)?.Balance || 0;
  }

  //Unused + Used + Bonus
  const clientTotalAccount = findBalanceFromAccount("Unused Balance") + findBalanceFromAccount("Used Balance") + findBalanceFromAccount("Bonus Balance");

  const handleAmount = (e) => {
    let total = clientTotalAccount.toFixed(2);
    setValue("amount", total);
    clearErrors("amount");
  };

  const handleAccountType = () => {
    if (SelectedCard?.PaymentSystemName !== "PaymentIQLuxon") {
      const payload = {
        Loader: true,
        PartnerId: 74,
        TimeZone: 0,
        LanguageId: LanguageId,
        ProductId: null,
        Method: "GetPaymentBanks",
        Controller: "Document",
        CategoryId: null,
        PageIndex: 0,
        PageSize: 100,
        ProviderIds: [],
        Index: null,
        ActivationKey: null,
        MobileNumber: null,
        Email: null,
        Code: null,
        Type: null,
        ClientId: ClientId,
        PaymentInfo: null,
        RequestData: SelectedCard?.PaymentSystemId,
        Token: Token,
      };
      if (Token) {
        axios
          .post(`${apiVars.baseApiUrl}/Main/ApiRequest`, payload)
          .then((res) => {
            let response = res.data.ResponseObject;
            response = response.map((x) => ({ value: x.BankCode, label: x.BankName }));
            setaccountType(response);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  async function getPartnerCustomerBanks() {
    if (isFugaMethod) {
      try {
        const resp = await axios.post(`${apiVars.baseApiUrl}/Main/ApiRequest`, {
          ClientId: ClientId,
          Method: "GetPartnerCustomerBanks",
          Controller: "Client",
          PartnerId: 74,
          Token: Token,
          Loader: true,
          RequestData: SelectedCard.PaymentSystemId,
          TimeZone: 0,
        });
        let response = resp.data.ResponseObject;
        response = response.map((x) => ({ value: x.Branches[0].Id, label: x.BankName }));
        setBankNames(response);
        if (response.length === 1) {
          setValue("bankName", response[0]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  useEffect(() => {
    setValue("bankName", "");
    setValue("accountType", "");
    handleAccountType();
    getPartnerCustomerBanks();
    clearErrors();
    //eslint-disable-next-line
  }, [SelectedCard]);

  useEffect(() => {
    const url = ResponseObject?.data?.ResponseObject?.Url;
    if (url) {
      setTransactionUrl(url);
      setOpenModal(true);
    }
  }, [ResponseObject]);

  return (
    <div className="cabinetForm withdrawForm cabinet-block">
      <div className="cabinetForm-block">
        <div className="cabinetForm-title">{t("Select_payment")}</div>
        <WithdrawFormHeader
          clientTotalAccount={clientTotalAccount.toFixed(2)}
          clientBookingAmount={findBalanceFromAccount("Client Booking")}
          currency={currency_symbols[CurrencyId] || "€"}
        />
        <div className="cabinet-divider"></div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="inputGroup mt-3">
            <p className="inputGroup-label">
              {t("Amount")} ({selectedCurrency})
            </p>
            <div className="withdrawForm-amount">
              <TextField
                inputProps={{
                  step: "any",
                  max: clientTotalAccount.toFixed(2),
                  min: SelectedCard.MinAmount,
                }}
                {...register("amount", { required: true })}
                type="number"
                className="mui-textfield"
                data-e2e="amount"
              />
              <button
                data-e2e="all-amount"
                onClick={handleAmount}
                type="button"
                className="cabinet-btn">
                {t("All Amount")}
              </button>
            </div>
            {errors.amount && <p className="error-message">This field is required</p>}
            {errMsg && (
              <p
                data-e2e={errMsg}
                className="error-message">
                {errMsg} {currency_symbols[CurrencyId] || ""}
              </p>
            )}
          </div>
          {isCryptoMethod && (
            <>
              <div className="inputGroup">
                {/* todo: change wallet number to address */}
                <p className="inputGroup-label">{t("WalletNumber")}</p>
                <TextField
                  data-e2e="wallet-number"
                  className="mui-textfield"
                  {...register("walletAddress", { required: isCryptoMethod })}
                />
                {errors.walletAddress && <p className="error-message">This field is required</p>}
              </div>
              {isNowMethod && (
                <div className="inputGroup">
                  <p className="inputGroup-label">{t("AccountType")}</p>
                  <Controller
                    control={control}
                    {...register("accountType", { required: isNowMethod })}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        value={value}
                        options={accountType}
                        placeholder=""
                        menuPortalTarget={document.body}
                        onChange={(selected) => {
                          setValue("accountType", selected);
                          clearErrors("accountType");
                          return selected.value;
                        }}
                        styles={styles}
                      />
                    )}
                  />
                  {errors.accountType && <p className="error-message">This field is required</p>}
                </div>
              )}
            </>
          )}
          {isFugaMethod && (
            <div>
              <div className="inputGroup">
                <p className="inputGroup-label">{t("Bank name")}</p>
                <Controller
                  control={control}
                  {...register("bankName", { required: isFugaMethod })}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      value={value}
                      ref={ref}
                      options={bankNames}
                      placeholder=""
                      menuPortalTarget={document.body}
                      onChange={(selected) => {
                        console.log(selected);
                        setValue("bankName", selected);
                        clearErrors("bankName");
                        return selected.value;
                      }}
                      styles={styles}
                    />
                  )}
                />
                {errors.bankName && <p className="error-message">This field is required</p>}
              </div>
              <div className="inputGroup mt-3">
                <p className="inputGroup-label">Bank Account Number</p>
                <TextField
                  type="number"
                  className="mui-textfield"
                  {...register("bankAccountNumber", { required: isFugaMethod })}
                />
                {errors.bankAccountNumber && <p className="error-message">This field is required</p>}
              </div>
              <div className="inputGroup mt-3">
                <p className="inputGroup-label">Bank Account Holder</p>
                <TextField
                  className="mui-textfield"
                  {...register("bankAccountHolder", { required: isFugaMethod })}
                />
                {errors.bankAccountHolder && <p className="error-message">This field is required</p>}
              </div>
            </div>
          )}
          <button
            data-e2e={t("Confirm")}
            className="cabinetForm-btn no-mobile theme-btn full-width sqr-btn"
            type="submit">
            {t("Confirm")}
          </button>
        </form>
      </div>
      <Modal
        open={openModal}
        destroyOnClose
        footer={null}
        className="theme-modal"
        onCancel={() => {
          withdrawSuccess([]);
          setOpenModal(false);
          setTransactionUrl("");
        }}>
        <div className="theme-modal-body">
          <button
            type="button"
            onClick={() => {
              withdrawSuccess([]);
              setOpenModal(false);
              setTransactionUrl("");
            }}
            className="theme-modal-close absolute">
            <img
              loading="lazy"
              width="17"
              height="17"
              src={Images.closeIcon}
              alt="close icon"
            />
          </button>
          <div className="theme-modal-iframe">
            <iframe
              src={transactionUrl}
              width="100%"
              height="500px"
              title="Payment iframe"></iframe>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default connect(null, {
  withdraw,
  withdrawSuccess,
  GetClientBalanceForWithdrawSuccess,
})(Withdraw);
