import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Localisation } from "../locale/Localisation";
import routes from "./URLs";
import history from "../utils/history";
import Loading from "../Components/Common/Loading";
import Games from "../modules/Games";
import Casino from "../modules/Casino";
import Casher from "../modules/Casher";
import Promotions from "../modules/Promotions";
import SinglePromotion from "../modules/Promotions/SinglePromotion";
import TermsCondition from "../modules/Footer/Terms&Regulations/Terms&Condition";
import Support from "../modules/Footer/Support/Support";
import SignUpPage from "../Components/Models/Register/SignUpPage";
// import Help from "../modules/Footer/Help/Help";
import AboutUs from "../modules/Footer/AboutUs/AboutUs";
import { resources } from "../locale/Resources";
import ResponsibleGames from "../modules/Footer/Terms&Regulations/ResponsibleGames";
import SelfExclusion from "../modules/Footer/Terms&Regulations/SelfExclusion";
import DisputeResolution from "../modules/Footer/Terms&Regulations/DisputeResolution";
import AmlPage from "../modules/Footer/Terms&Regulations/AmlPage";
import FairnessAndRNG from "../modules/Footer/Terms&Regulations/FairnessAndRNG";
import PrivacyPolicy from "../modules/Footer/Terms&Regulations/PrivacyPolicy";
import AccountPayoutAndBonus from "../modules/Footer/Terms&Regulations/AccountPayoutAndBonus";
import History from "../modules/Casher/History/History";
import Bonuses from "../modules/Casher/Bonuses/Bonuses";
import Profile from "../modules/Casher/Profile/Profile";
import Ticket from "../modules/Casher/Ticket/Ticket";
import Balance from "../modules/Casher/Balance/Balance";
import Wallets from "../modules/Casher/Finances/Wallets";
import Home from "../modules/Home";
import SportsBookPage from "../modules/Footer/Terms&Regulations/SportsBookPage";
import LandingPage from "../pages/LandingPage";
import ChangePassword from "../pages/ChangePassword";

export const BASE_ROUTE = process.env.BASE_ROUTE_PREFIX || "/okyanus-bahis/";
const ErrorBoundary = lazy(() => import("./ErrorBoundry"));
const PageNotFound = lazy(() => import("./PageNotFound"));
const AccessDenied = lazy(() => import("./AccessDenied"));
const PrivateRouteWithStore = lazy(() => import("./PrivateRouteWithStore"));
const LiveCasino = lazy(() => import("../modules/LiveCasino"));
const LiveCasinoPreview = lazy(() => import("../modules/LiveCasino/LiveCasinoPreview"));
const SportsMain = lazy(() => import("../modules/Sports/main"));
const InPlay = lazy(() => import("../modules/Inplay/Index"));
const NoAccessPage = lazy(() => import("../pages/NoAccessPage"));

const Routes = () => {
  useEffect(() => {
    Localisation.LoadData(resources);
  }, []);

  return (
    <Router history={history}>
      <Suspense fallback={<Loading />}>
        <ErrorBoundary>
          <Switch>
            <Route
              exact
              path="/"
              component={(props) => <LandingPage {...props} />}
            />
            <Route
              exact
              path="/home"
              component={(props) => <Home {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.CASINO}
              component={(props) => <Casino {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.INPLAY}
              component={(props) => <InPlay {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.PROMOTIONS}
              component={(props) => <Promotions {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.SINGLE_PROMOTION}
              component={(props) => <SinglePromotion {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.LIVE_CASINO}
              component={(props) => <LiveCasino {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.LIVE_CASINO_PREVIEW}
              component={(props) => <LiveCasinoPreview {...props} />}
            />
            <PrivateRouteWithStore
                exact
                path="/live-casino/:id"
                component={(props) => <LiveCasinoPreview {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.CASHER}
              component={(props) => <Casher {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.CASHER_WALLETS}
              component={(props) => <Wallets {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.CASHER_HISTORY}
              component={(props) => <History {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.CASHER_BONUSES}
              component={(props) => <Bonuses {...props} />}
            />

            <PrivateRouteWithStore
              exact
              path={routes.CASHER_PROFILE}
              component={(props) => <Profile {...props} />}
            />

            <PrivateRouteWithStore
              exact
              path={routes.CASHER_TICKET}
              component={(props) => <Ticket {...props} />}
            />

            <PrivateRouteWithStore
              exact
              path={routes.CASHER_BALANCE}
              component={(props) => <Balance {...props} />}
            />

            <PrivateRouteWithStore
              exact
              path={routes.GAMES}
              component={(props) => <Games {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.SINGLE_PROMOTION}
              component={(props) => <SinglePromotion {...props} />}
            />

            <PrivateRouteWithStore
              exact
              path={routes.TERMS_CONDITION}
              component={(props) => <TermsCondition {...props} />}
            />

            <PrivateRouteWithStore
              exact
              path={routes.RESPONSIBLE_GAMING}
              component={(props) => <ResponsibleGames {...props} />}
            />

            <PrivateRouteWithStore
              exact
              path={routes.SELF_EXCLUSION}
              component={(props) => <SelfExclusion {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.DISPUTE_RESOLUTION}
              component={(props) => <DisputeResolution {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.AMP_PAGE}
              component={(props) => <AmlPage {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.FAIRNESS_AND_RNG_TESTING}
              component={(props) => <FairnessAndRNG {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.PRIVACY_POLICY}
              component={(props) => <PrivacyPolicy {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.ACCOUNTS_PAYOUTS_BONUS}
              component={(props) => <AccountPayoutAndBonus {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.SUPPORT}
              component={(props) => <Support {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.SIGN_UP}
              component={(props) => <SignUpPage {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.CHANGE_PASSWORD}
              component={(props) => <ChangePassword {...props} />}
            />
            <PrivateRouteWithStore
              exact
              path={routes.SPORTSBOOK_RULES}
              component={(props) => <SportsBookPage {...props} />}
            />

            {/* <PrivateRouteWithStore
              exact
              path={routes.HELP}
              component={(props) => <Help {...props} />}
            /> */}

            <PrivateRouteWithStore
              exact
              path={routes.ABOUT_US}
              component={(props) => <AboutUs {...props} />}
            />

            <PrivateRouteWithStore
              exact
              path={routes.SPORTS}
              component={(props) => <SportsMain {...props} />}
            />

            <PrivateRouteWithStore
              exact
              path={routes.ACCESS_DENIED}
              component={(props) => <AccessDenied {...props} />}
            />

            <PrivateRouteWithStore
                exact
                path={routes.NO_ACCESS}
                component={(props) => <NoAccessPage {...props} />}
            />

            <PrivateRouteWithStore
              path="*"
              component={(props) => <PageNotFound {...props} />}
            />
          </Switch>
        </ErrorBoundary>
      </Suspense>
    </Router>
  );
};

export default Routes;
