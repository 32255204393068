import i18n from 'i18next';
import { Localisation } from '../locale/Localisation';
import React, { useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { languageDropdown } from '../Components/Navbar/constantNavBar';

export default function LangSwitch({isMobile}) {
  const { ChangeLanguage } = Localisation;
  const handleChangeLanguage = (e, val) => {
    ChangeLanguage(val);
		handleClose(e);
  };
	const activeLang = languageDropdown.find(x => x.key === i18n.language)
	
	useEffect(() => {
		if (activeLang === "he" || activeLang === "ar") {
			document.documentElement.setAttribute("dir", "rtl");
		} else {
			document.documentElement.setAttribute("dir", "ltr");
		}
	}, [activeLang])
	
	const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
		event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation()
		setAnchorEl(null);
		
  };
	
  return (
		<div className="langSwitch">
			<button type="button" className={`langSwitch__btn ${open ? "active" : ""}`} 
				aria-label="more"
        id="lang-button"
        aria-controls={open ? 'lang-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
			>
				<div className="langSwitch__btn-icon">
					<img src={activeLang.img} className="" alt="" />
				</div>
				{activeLang.value}
				<svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none"><path d="M0.275 0.700013C0.0916663 0.883347 0 1.11668 0 1.40001C0 1.68335 0.0916663 1.91668 0.275 2.10001L4.175 6.00001L0.275 9.90001C0.0916663 10.0833 0 10.3167 0 10.6C0 10.8833 0.0916663 11.1167 0.275 11.3C0.458333 11.4833 0.691666 11.575 0.974999 11.575C1.25833 11.575 1.49167 11.4833 1.675 11.3L6.275 6.70001C6.375 6.60001 6.446 6.49168 6.488 6.37501C6.52933 6.25835 6.55 6.13335 6.55 6.00001C6.55 5.86668 6.52933 5.74168 6.488 5.62501C6.446 5.50835 6.375 5.40001 6.275 5.30001L1.675 0.700013C1.49167 0.51668 1.25833 0.425012 0.974999 0.425012C0.691666 0.425012 0.458333 0.51668 0.275 0.700013Z" fill="#B7B7B7"/></svg>
			</button>
			<Menu
        id="lang-menu"
				className="langSwitch__menu custom-mui-select"
        MenuListProps={{
          'aria-labelledby': 'lang-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => handleClose(e)}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
          },
        }}
				transformOrigin={{ horizontal: !isMobile ? 'left' : "right", vertical: 'bottom' }}
        anchorOrigin={{ horizontal: !isMobile ? 'left' : "right", vertical: 'bottom' }}
      >
				{languageDropdown.map(x => {
					return (
						<MenuItem key={x.key} selected={x.key === activeLang.key} onClick={(e) => handleChangeLanguage(e, x.key)}>
							<img src={x.img} alt="" />
							{x.value}
						</MenuItem>
					)
				})}
      </Menu>
		</div>
  );
}
