export const SideMenuStyle = {
  width: "120px",
  height: "100%",
  fontSize: "16px",
  fontWeight: 500,
  backgroundColor: "#101219",
  marginTop: "20px",
  fontFamily: "Poppins",
  color: "#808185",
};

export const SideMenuItemStyle = {
  fontSize: "15px",
};

export const AvatarIconStyle = { backgroundColor: "#101219" };

export const logoStyle = {
  height: "55px",
  backgroundSize: "contain",
  display: "block",
  margin: "0 auto",
  marginTop: "2em",
};
const LayoutComponentStyles = {
  fixedSider: {
    // overflowY: "auto",
    // overflowX: "hidden",
    // overflow: "hidden",
    height: "100vh",
    position: "fixed",
    inlineStart: 0,
  },
  actionButtons: {
    paddingInlineEnd: 20,
    marginTop: 10,
  },
  parentLayout: { minHeight: "100vh" },
 
  fixedPageHeader: {
    zIndex: 100,
    width: "inherit",
    backgroundColor: "#101219",
    height: "50px",
  },
  noData: { margin: "20px" },
};

export default LayoutComponentStyles;
