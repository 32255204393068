import React from "react";
import CommonFooterTermsAndConditions from "./CommonFooterTermsAndConditions";
import { useTranslation } from "react-i18next";

const FairnessAndRNG = () => {
  const { t } = useTranslation();
	
  return (
    <CommonFooterTermsAndConditions
      title={t("fairness_title")}
      data={t("fairness_text")}
    />
  );
};

export default FairnessAndRNG;
