import { Images } from "../../../Images";
import { useHistory } from "react-router-dom";
import NavbarTime from "./NavbarTime";
import { useSelector } from "react-redux";
import { loggedInMenu } from "../constantNavBar";
import { useContext } from "react";
import CasherNav from "../../../modules/Casher/context/context";
import { useTranslation } from "react-i18next";
import { getItemFromLocalStorage } from "../../../utils/localStorage";

export default function NavbarProfileSidebar(props) {
  const { closeHandler, isSidebar, user, userbalance } = props;
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const { setFinUrl } = useContext(CasherNav);
  const history = useHistory();
  const { t } = useTranslation();
  const username = getItemFromLocalStorage("UserName");

  const handleroutes = (items) => {
    const pathName = window?.location?.pathname;
    if (items.url === pathName) {
      closeHandler();
    }
    items.url && history.push(items.url);
    items.inside_route && setFinUrl(items.inside_route);
    items.islogout && props.logoutHandler();
  };
  return (
    <div className={`${isSidebar ? "active" : ""} sidebarM on-tablet`}>
      <div>
        <div className="sidebarM-header">
          <div className="sidebarM-user">
            <div className="sidebarM-user-img">
              <img
                width="40"
                height="40"
                data-e2e="Profile-icon"
                src={
                  themeMode === "dark"
                    ? Images.navProfileIcon
                    : Images.navProfileIconLight
                }
                alt="profile icon"
              />
            </div>
            <div>
              <div className="sidebarM-profile-name navbar-profile-t-name">
                {username}
              </div>
              <div className="sidebarM-profile-balance navbar-profile-t-balance">
                {user.currency} {userbalance || 0}
              </div>
            </div>
          </div>
          <button onClick={closeHandler} className="sidebarM-close">
            <img
              className={themeMode === "light" ? "light-theme-icon" : ""}
              width="17"
              height="17"
              src={Images.closeIcon}
              alt="close icon"
            />
          </button>
        </div>
        <div className="sidebarM-links">
          {loggedInMenu.map((items, i) => {
            if (!items.islogout) {
              return (
                <div
                  key={i}
                  className="sidebarM-link cursor-pointer"
                  onClick={() => {
                    handleroutes(items);
                  }}
                >
                  <div className="sidebarM-link-row">
                    <img
                      className={themeMode === "light" ? "black-image" : ""}
                      width="16"
                      height="16"
                      src={items.icon}
                      alt="logout icon"
                    />
                    {t(items.name)}
                  </div>
                  <img
                    width="16"
                    height="16"
                    src={Images.chevronRightIcon}
                    alt="chevron right icon"
										className="sidebarM-link-chevron"
										
                  />
                </div>
              );
            } else {
              return (
                <div key={i}>
                  <div className="sidebarM-main-divider"></div>
                  <div
                    key={i}
                    className="sidebarM-link cursor-pointer"
                    onClick={() => {
                      handleroutes(items);
                    }}
                  >
                    <div className="sidebarM-link-row">
                      <img
                        className={themeMode === "light" ? "black-image" : ""}
                        width="16"
                        height="16"
                        src={items.icon}
                        alt="logout icon"
                      />
                      {t(items.name)}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      <NavbarTime />
    </div>
  );
}
