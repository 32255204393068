import BasicLayout from "../../../BasicLayout";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
	const { t } = useTranslation();
	
  return (
    <BasicLayout>
      <div className="content-px content-py uw-content legal">
        <div className="layout-title">{t("About us")}</div>
        <div className="legal-block">
					<p dangerouslySetInnerHTML={{__html: t("about_us_text")}}></p>
        </div>
      </div>
    </BasicLayout>
  );
};

export default AboutUs;
