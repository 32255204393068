
export const MENU_KEYS = {
  HOME_PAGE: "home_page",
  CASINO: "casino",
  LIVE_CASINO: "live_casino",
  GAMES: "games",
  SPORTS: "sports",
  INPLAY: "inplay",
  PROMOTIONS: "promotions",
  POKER: "poker"
};
