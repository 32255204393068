// function extract(url) {
// 	if (!url) return
// 	var res = url.split('.')[0];
// 	return res;
// }

// let currentHost = window.location.hostname !== "localhost" ? extract(window.location.hostname) : "okyanusbahis";
let currentHost = "oceanbet";
export const apiVars = {
  baseApiUrl: `https://websitewebapi.${currentHost}.casino/74/api`,
  casinoHubConnection: `https://websitewebapi.${currentHost}.casino/basehub?PartnerId=43&Token=&LanguageId=en&TimeZone=0`,
  currentHost: currentHost,
  pokerUrl: `https://oceanbet.com/poker/en/`,
};
