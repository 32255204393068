import axios from "axios";
import { apiVars } from "../../../api";

class LoginApi {
  loginUser = (data) =>
    axios.post(
      `${apiVars.baseApiUrl}/Main/LoginClient`,
      data
    );
  getClientByToken = (data) =>
    axios.post(
			`${apiVars.baseApiUrl}/Main/GetClientByToken`,
      data
    );
  userSignup = (data) =>
    axios.post(
			`${apiVars.baseApiUrl}/Main/RegisterClient`,
      data.Data
    );
  countries = (data) =>
    axios.post(
			`${apiVars.baseApiUrl}/Main/GetRegions`,
      data.Data
    );
  cities = (data) =>
    axios.post(
			`${apiVars.baseApiUrl}/Main/GetRegions`,
      data.Data
    );
  recoveryPassword = (data) =>
    axios.post(
			`${apiVars.baseApiUrl}/Main/SendRecoveryToken`,
      data.Data
    );
}

const api = new LoginApi();
export default api;
