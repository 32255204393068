import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";
import { addItemToLocalStorage } from "../../utils/localStorage";
import { liveCasino } from "../../modules/LiveCasino/constant";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { useDispatch } from "react-redux";
import { loginModal } from "../Login/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function FooterMenuLink({ titleKey, loginRouteTo, to }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    if (loginRouteTo) {
        function openLiveCasino(loginRouteTo) {
            const foundLiveCasino = liveCasino.find((x) => x.slug === loginRouteTo);
            addItemToLocalStorage("productCode", foundLiveCasino.productCode);

            const Token = getItemFromLocalStorage("Token");
            if (!Token) {
                dispatch(loginModal(true));
            } else {
                history.push(`/live-casino/${foundLiveCasino.slug}`);
            }
        }

        return (
            <div className="footer-menu-link-block">
                <button onClick={() => openLiveCasino(loginRouteTo)}>{t(titleKey)}</button>
            </div>
        );
    } else {
        return (
            <div className="footer-menu-link-block">
                <NavLink to={to}>{t(titleKey)}</NavLink>
            </div>
        );
    }
}
