import CasherNav from "../context/context";
import { useContext, useEffect, useState } from "react";
import MainHistory from "../History/components/MainHistory";
import { useSelector } from "react-redux";
import BasicLayout from "../../../BasicLayout";
import CabinetNav from "../components/CabinetNav";
import CabinetNavComponents from "../components/CabinetNavComponents";
import { useTranslation } from "react-i18next";


const Balance = (props) => {
  const { balanceUrl } = useContext(CasherNav);
  const [check, setcheck] = useState(false);
  const [clientBalance, setclientBalance] = useState([]);
  const { histUrl, setHistUrl } = useContext(CasherNav);
  const Balance = useSelector(
    (state) => state?.headerReducer?.getClientBalanceData?.data?.Balances
  );
  const { t } = useTranslation();

  const handleBalance = (e) => {
    let balance = {};
    switch (e.TypeId) {
      case 1:
        balance.type = "Unused Balance";
        balance.balance = e.Balance;
        break;
      case 2:
        balance.type = "Used Balance";
        balance.balance = e.Balance;
        break;
      case 3:
        balance.type = "Booking Balance";
        balance.balance = e.Balance;
        break;
      case 15:
        balance.type = "Affiliate Manager Balance";
        balance.balance = e.Balance;
        break;
      case 12:
        balance.type = "Bonus Balance";
        balance.balance = e.Balance;
        break;
      default:
    }
    setclientBalance((prevState) => [...prevState, balance]);
    setcheck(true);
  };

  useEffect(() => {
    // debugger
    if (!check) {
      Balance?.map((e) => handleBalance(e));
    }
    //eslint-disable-next-line
  }, [Balance]);

  const navElBalance = [
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
      render: (text) => <p>{text}</p>,
    },
    {
      title: t("Balance"),
      dataIndex: "balance",
      key: "Balance",
      render: (text) => <p>{text}</p>,
    },
  ];
  const main = () => {
    if (balanceUrl === "Balance")
      return (
        <>
          <MainHistory
            empty={"Balance-empty"}
            navEl={navElBalance}
            Payhistory={clientBalance}
          />
        </>
      );
  };
  const historyNavEl = ["Balance"];

  return (
    <BasicLayout pageTitle="Balance">
      <div className="content-px content-py uw-content">
        <CabinetNav />
        <div className="cabinet-block">
          <CabinetNavComponents
            isSingleLink={true}
            navEl={historyNavEl}
            url={histUrl}
            setUrl={setHistUrl}
          />
        </div>
        {main()}
      </div>
    </BasicLayout>
  );
};

export default Balance;
