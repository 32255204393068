import { Select } from "antd";
import { Localisation } from "../../../locale/Localisation";
import { languageDropdown } from "../constantNavBar";
import i18n from 'i18next';
import { useEffect } from "react";

export default function NavbarLang(props) {
  const { Option } = Select;
  const { ChangeLanguage } = Localisation;

  const HandleSelectLanguage = (e) => {
    ChangeLanguage(e);
  };
	
	const activeLang = i18n.language;
	
	useEffect(() => {
		if (activeLang === "he" || activeLang === "ar") {
			document.documentElement.setAttribute("dir", "rtl");
		} else {
			document.documentElement.setAttribute("dir", "ltr");
		}
	}, [activeLang])
	
  return (
    <div
      id={`${
        props.isTablet ? "navbar-langM-container" : "navbar-lang-container"
      }`}
    >
      <Select
        showArrow={false}
        getPopupContainer={() =>
          document.getElementById(
            `${
              props.isTablet
                ? "navbar-langM-container"
                : "navbar-lang-container"
            }`
          )
        }
        popupClassName="navbar-lang-dropdown"
        className="navbar-lang"
				defaultValue={i18n.language}
        onChange={HandleSelectLanguage}
      >
        {languageDropdown?.map((e, i) => {
          return (
            <Option value={e.key} key={i}>
              <img
                width="32"
                height="32"
                className="navbar-lang-option"
                src={e.img}
                alt={`${e.value} language icon`}
              />
              {e.value}
            </Option>
          );
        })}
      </Select>
    </div>
  );
}
