import { Images } from "../../Images";

export default function FooterPayments() {
  return (
    <div className="footer-payments block-mg">
      <div className="footer-payments-container">
        <img
          width="41"
          height="25"
          loading="lazy"
          className="footer-payments-img"
          src={Images.PaymentNowpay}
          alt="paymant nowpay icon"
        />
        <img
          width="41"
          height="25"
          loading="lazy"
          className="footer-payments-img"
          src={Images.PaymentFiat}
          alt="payment fiat icon"
        />
        <img
          width="41"
          height="25"
          loading="lazy"
          className="footer-payments-img"
          src={Images.payPapara}
          alt="pay para icon"
        />
        <img
          width="81"
          height="25"
          loading="lazy"
          className="footer-payments-img"
          src={Images.payCreditCard}
          alt="pay credit card icon"
        />
        <img
          width="143"
          height="25"
          loading="lazy"
          className="footer-payments-img"
          src={Images.skrill}
          alt="skrill icon"
        />
        <img
          width="141"
          height="25"
          loading="lazy"
          className="footer-payments-img"
          src={Images.payBank}
          alt="pay bank icon"
        />
      </div>
      <div className="divider"></div>
      <img
        width="46"
        height="46"
        loading="lazy"
        className="footer-ageLimit"
        src={Images.age_limit}
        alt="age limit"
      />
    </div>
  );
}
