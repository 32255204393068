import React from "react";
import CommonFooterTermsAndConditions from "./CommonFooterTermsAndConditions";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
	
  return (
    <CommonFooterTermsAndConditions 
			title={t("policy_title")} 
			data={t("policy_text")} 
		/>
  );
};

export default PrivacyPolicy;