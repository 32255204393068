import { connect } from "react-redux";
import { checkLoginStatus } from "../../Games/actions";
import { getGames } from "../Services/actions";
import { bannerData } from "../data/constantBanerData";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import routes from "../../../Route/URLs";
import { registerModal } from "../../../Components/Login/actions";
import { useHistory } from "react-router-dom";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { useTranslation } from "react-i18next";
import { getPromoImage } from "../../Promotions/helper";

const Banner = (props) => {
  const history = useHistory();
  const loggedIn = getItemFromLocalStorage("Token");
  const { i18n } = useTranslation();
  const activeLanguage = i18n.language;

  const { registerModal } = props;
  function showLoginModal(e) {
    if (!loggedIn) {
      registerModal(true);
    } else {
      history.push(routes.CASHER_WALLETS);
    }
  }

  SwiperCore.use([Pagination, Autoplay]);

  return (
    <>
      <div className="banner">
        <Swiper
          dir={"ltr"}
          modules={[Pagination, Autoplay]}
          slidesPerView={1}
          autoplay={{ delay: 4000, pauseOnMouseEnter: true }}
          loop={true}
          pagination={{ dynamicBullets: true, clickable: true }}>
          {bannerData.map((banner, index) => {
            return (
              <SwiperSlide key={`${banner.title}${index}`}>
                <div className="banner-slide">
                  <button
                    type="button"
                    className="banner-slide-login"
                    onClick={showLoginModal}></button>
                  <img
                    className="banner-slide-img"
                    src={getPromoImage(banner, activeLanguage)}
                    alt={banner.title}
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};
export default connect(null, {
  registerModal,
  getGames,
  checkLoginStatus,
})(Banner);
