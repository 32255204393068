import React from "react";
import CommonFooterTermsAndConditions from "./CommonFooterTermsAndConditions";
import { useTranslation } from "react-i18next";

const SelfExclusion = () => {
  const { t } = useTranslation();
  return (
    <CommonFooterTermsAndConditions 
			title={t("Self-Exclusion")} 
			data={t("self_exclusion_text")} 
		/>
  );
};

export default SelfExclusion;
