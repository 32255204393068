import React from "react";
import SwiperCore, { Pagination, Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
// import { useQuery } from "@apollo/client";
// import { useTranslation } from "react-i18next";
import LogoCasinoColor from "../../icons/LogoCasinoColor";
// import { bannersGql } from "../../gql";
// import { getArrayLocale } from "../../helper";
// import DataLoader from "../Common/DataLoader";
import LogoPokerColor from "../../icons/LogoPokerColor";
import LogoSportColor from "../../icons/LogoSportColor";
import { Images } from "../../Images";
import { apiVars } from "../../api";

const banners = [
  {
    subtitle: "Casino Hoşgeldin Bonusu",
    bonusAmount: "12.000",
    bonusTitle: "TL'ye kadar %120 devasal bonus <br/>+ 25 Free Spins",
    buttonLink: "/sign-up",
    buttonStyle: "casino",
    buttonText: "OkyanusBahis'e Katılın",
    bottomText: "Eğer şimdi Okyanus casino'ya ilk adımlarınızı atıyorsanız, ilk para yatırma işleminizde size büyük bir bonusla mükemmel bir başlangıç sunmak istiyoruz!<br><br>İlk para yatırma işleminizde HOSGELDIN120 koduyla hoş geldin bonusunuzu alın ve bu bonusun sadece bir parçası olan Wild Drops, Book of Dead, Sherlock of London, Wild Rails, Rick Wilde ve Tome of Madness gibi en popüler oyunları oynarken büyük kazanın, muhteşem slot oyunlarını burada bulabilirsiniz.",
    image: { url: Images.casinoBgLanding },
    float_image: { url: Images.casinoBgFloat },
  },
  {
    subtitle: "Spor Hoşgeldin Bonusu",
    bonusAmount: "6.000",
    bonusTitle: "TL'ye kadar %100 yatırım bonusu",
    buttonLink: "/sports",
    buttonStyle: "sport",
    buttonText: "OkyanusBahis'e Katılın",
    bottomText: "Okyanus'a yapacağınız ilk para yatırımıza 6000 TL'ye kadar %100 bonusu kaçırmayın!!!<br/>HOSGELDIN100 kodunu kullanarak bonusunuzu aktif etmelisiniz.",
    image: { url: Images.sportBgLanding },
    float_image: { url: Images.sportBgFloat },
  },
  {
    subtitle: "Poker Hoşgeldin Bonusu",
    bonusAmount: "600$",
    bonusTitle: "KADAR YÜZDE YÜZ!",
    buttonLink: apiVars.pokerUrl,
    buttonStyle: "poker",
    buttonText: "OkyanusBahis Poker’e Katil",
    bottomText: "",
    image: { url: Images.pokerBgLanding },
    float_image: null,
  },
];

const SlideElement = ({ banner }) => {
  const { subtitle, bonusAmount, bonusTitle, buttonLink, buttonStyle, buttonText, bottomText, image, float_image } = banner;
  return (
    <div className="landBanner__slide">
      <div className={`landBanner__slide-container ${float_image?.url ? "" : "pd-b"}`}>
        <div className="landBanner__slide-content">
          {buttonStyle === "casino" && <LogoCasinoColor />}
          {buttonStyle === "poker" && <LogoPokerColor />}
          {buttonStyle === "sport" && <LogoSportColor />}
          <p className="landBanner__slide-text">{subtitle}</p>
          <div className="landBanner__slide-block">
            <p className="gradient-text">{bonusAmount}</p>
            <p dangerouslySetInnerHTML={{ __html: bonusTitle }}></p>
          </div>
          <p
            className="landBanner__slide-bottom"
            dangerouslySetInnerHTML={{ __html: bottomText }}></p>
          <div className="landBanner__slide-btn">
            {buttonStyle === "poker" ? (
              // eslint-disable-next-line
              <a
                href={buttonLink || "#/"}
                className={`theme-btn fill-btn ${buttonStyle}-btn-style`}>
                <span>{buttonText}</span>
              </a>
            ) : (
              <NavLink
                // eslint-disable-next-line
                to={buttonLink || "#/"}
                className={`theme-btn fill-btn ${buttonStyle}-btn-style`}>
                <span>{buttonText}</span>
              </NavLink>
            )}
          </div>
        </div>
      </div>
      {float_image?.url && (
        <img
          className="landBanner__slide-float"
          src={float_image?.url}
          alt=""
        />
      )}
      <img
        className="landBanner__slide-img"
        src={image?.url}
        alt={subtitle}
      />
    </div>
  );
};

export default function BannerLanding() {
  SwiperCore.use([Pagination, Autoplay, EffectFade]);
  // const { i18n } = useTranslation();
  // const activeLanguage = i18n.language;
  // const { loading, error, data } = useQuery(bannersGql);
  // const [banners, setBanners] = useState([]);
  // console.log(error);
  // useEffect(() => {
  //   setBanners(getArrayLocale(activeLanguage, data?.banners));
  // }, [activeLanguage, data]);

  return (
    <div className="landBanner">
      {/* {loading && <DataLoader />} */}
      <Swiper
        dir={"ltr"}
        modules={[Pagination, Autoplay, EffectFade]}
        slidesPerView={1}
        autoplay={{ delay: 4000, pauseOnMouseEnter: true }}
        effect="fade"
        autoHeight={true}
        fadeEffect={{ crossFade: true }}
        loop={true}
        pagination={{ dynamicBullets: true, clickable: true }}>
        {banners?.map((x) => {
          return (
            <SwiperSlide key={x.id}>
              <SlideElement banner={x} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
