import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { Images } from "../Images";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { checkPathname } from "../utils/classNameManager";

export default function SidebarLogo() {
	const [logo, setLogo] = useState(null)
	const location = useLocation();

	useEffect(() => {
		setLogo(checkPathname(location.pathname) ? Images.sideBarLogoSport : Images.sideBarLogoCasino)
	}, [location])

	return (
		<NavLink to="/">
			{logo && <img
				className="sidebar-logo cursor-pointer"
				src={logo}
				alt="Oceanbet logo"
			/>}
		</NavLink>
	)
}
