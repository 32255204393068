import PaymentCard from "./PaymentCard";
import { useContext, useEffect, useState } from "react";
import CasherNav from "../../context/context";



const DepositPayMethods = () => {
  const [cardSelected, setCardSelected] = useState(0);
  const [methods, setMethods] = useState([]);
  const { partnerPayment, selectedCurrency, setSelectedPaymentMethod } = useContext(CasherNav);

  useEffect(() => {
    const options = [];
    if (partnerPayment.length > 0) {
      partnerPayment.forEach((p) => {
        if (p.CurrencyId === selectedCurrency && p.Type === 2) {
          options.push(p);
        }
      });
    }

    setMethods(options);
    setSelectedPaymentMethod(options[0])
    // eslint-disable-next-line 
  }, [selectedCurrency, partnerPayment]);
  
  return (
    <div className="deposit-scroll">
			<div className="deposit-grid">
				{methods?.map((card, i) => (
					<PaymentCard
						key={i}
						cardSelected={cardSelected}
						setCardSelected={setCardSelected}
						i={i}
						card={card}
					/>
				))}
			</div>
		</div>
  );
};

export default DepositPayMethods;
