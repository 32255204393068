export default function LogoCasinoColor() {
  return (
    <svg
      width="280"
      height="57"
      viewBox="0 0 280 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5644 35.0908C21.3028 35.0908 25.63 25.2006 25.63 16.59C25.63 11.9515 23.5263 10.552 21.1165 10.552C19.9491 10.548 18.8012 10.8514 17.7879 11.4317C17.2553 11.7249 17.029 11.8982 16.6828 11.8982C16.3367 11.8982 16.1502 11.6583 16.1502 11.2584C16.6275 9.37473 17.8222 7.75285 19.4788 6.73983C21.2099 5.47188 23.3127 4.8155 25.4569 4.87377C29.8373 4.87377 33.0594 7.85948 33.0594 14.0708C33.0594 26.8401 24.1654 40.1292 13.3942 40.1292C5.79175 40.1292 0 35.384 0 25.8404C0 8.08609 13.1678 0.648438 22.4079 0.648438C26.096 0.648438 27.7337 1.47484 27.7337 2.35456C27.7337 2.70111 27.494 2.94104 26.9747 2.94104C26.4555 2.94104 25.6433 2.82108 24.1654 2.82108C16.6162 2.82108 10.1855 10.8185 10.1855 25.014C10.1855 32.0517 12.5288 35.0908 15.5112 35.0908"
        fill="#3CCFCF"
      />
      <path
        d="M15.5644 35.0908C21.3028 35.0908 25.63 25.2006 25.63 16.59C25.63 11.9515 23.5263 10.552 21.1165 10.552C19.9491 10.548 18.8012 10.8514 17.7879 11.4317C17.2553 11.7249 17.029 11.8982 16.6828 11.8982C16.3367 11.8982 16.1502 11.6583 16.1502 11.2584C16.6275 9.37473 17.8222 7.75285 19.4788 6.73983C21.2099 5.47188 23.3127 4.8155 25.4569 4.87377C29.8373 4.87377 33.0594 7.85948 33.0594 14.0708C33.0594 26.8401 24.1654 40.1292 13.3942 40.1292C5.79175 40.1292 0 35.384 0 25.8404C0 8.08609 13.1678 0.648438 22.4079 0.648438C26.096 0.648438 27.7337 1.47484 27.7337 2.35456C27.7337 2.70111 27.494 2.94104 26.9747 2.94104C26.4555 2.94104 25.6433 2.82108 24.1654 2.82108C16.6162 2.82108 10.1855 10.8185 10.1855 25.014C10.1855 32.0517 12.5288 35.0908 15.5112 35.0908"
        fill="url(#paint0_linear_3_84931)"
      />
      <path
        d="M52.7116 33.8094C53.1244 33.3962 53.364 33.2229 53.7102 33.2229C53.9258 33.2263 54.1318 33.3126 54.2855 33.464C54.4393 33.6154 54.5289 33.8203 54.5357 34.036C54.4433 34.7519 54.1098 35.4149 53.5903 35.9154C52.7627 37.0504 51.6927 37.9862 50.4581 38.6549C49.2236 39.3236 47.8557 39.7082 46.4539 39.7809C41.3678 39.7809 41.0217 31.9833 40.4359 31.9833C39.8441 34.0468 39.3728 36.143 39.0246 38.2613C39.0246 39.2477 38.6784 39.4876 37.7996 39.4876C36.0421 39.4876 33.6988 39.7809 32.7668 39.7809C32.0612 39.7809 31.6484 39.4876 31.6484 38.448C31.6484 33.3562 36.8011 21.0535 36.8011 12.323C36.8011 10.3902 35.8025 9.45722 34.5776 9.45722C34.0583 9.45722 33.6988 9.2173 33.6988 8.75078C33.6988 7.52451 36.8011 5.5918 40.143 5.5918C43.2319 5.5918 45.1091 7.23126 45.1091 10.1637C45.1091 13.8558 40.9551 26.6784 40.9551 29.0243C41.8342 28.9273 42.6563 28.5409 43.2924 27.9258C43.9285 27.3106 44.3428 26.5016 44.4701 25.6254C44.4701 24.9723 44.0573 24.8656 44.0573 24.2925C44.0573 23.0529 47.6788 21.1868 50.3816 21.1868C51.54 21.1868 52.0725 21.6533 52.0725 22.5197C52.0725 24.9722 49.5561 28.1446 45.229 30.5171C45.8681 32.5698 47.8918 34.969 50.315 34.969C50.7805 34.956 51.2372 34.8392 51.6518 34.6271C52.0664 34.4149 52.4285 34.1128 52.7116 33.7428"
        fill="#3CCFCF"
      />
      <path
        d="M52.7116 33.8094C53.1244 33.3962 53.364 33.2229 53.7102 33.2229C53.9258 33.2263 54.1318 33.3126 54.2855 33.464C54.4393 33.6154 54.5289 33.8203 54.5357 34.036C54.4433 34.7519 54.1098 35.4149 53.5903 35.9154C52.7627 37.0504 51.6927 37.9862 50.4581 38.6549C49.2236 39.3236 47.8557 39.7082 46.4539 39.7809C41.3678 39.7809 41.0217 31.9833 40.4359 31.9833C39.8441 34.0468 39.3728 36.143 39.0246 38.2613C39.0246 39.2477 38.6784 39.4876 37.7996 39.4876C36.0421 39.4876 33.6988 39.7809 32.7668 39.7809C32.0612 39.7809 31.6484 39.4876 31.6484 38.448C31.6484 33.3562 36.8011 21.0535 36.8011 12.323C36.8011 10.3902 35.8025 9.45722 34.5776 9.45722C34.0583 9.45722 33.6988 9.2173 33.6988 8.75078C33.6988 7.52451 36.8011 5.5918 40.143 5.5918C43.2319 5.5918 45.1091 7.23126 45.1091 10.1637C45.1091 13.8558 40.9551 26.6784 40.9551 29.0243C41.8342 28.9273 42.6563 28.5409 43.2924 27.9258C43.9285 27.3106 44.3428 26.5016 44.4701 25.6254C44.4701 24.9723 44.0573 24.8656 44.0573 24.2925C44.0573 23.0529 47.6788 21.1868 50.3816 21.1868C51.54 21.1868 52.0725 21.6533 52.0725 22.5197C52.0725 24.9722 49.5561 28.1446 45.229 30.5171C45.8681 32.5698 47.8918 34.969 50.315 34.969C50.7805 34.956 51.2372 34.8392 51.6518 34.6271C52.0664 34.4149 52.4285 34.1128 52.7116 33.7428"
        fill="url(#paint1_linear_3_84931)"
      />
      <path
        d="M55.0053 23.3741C55.0053 22.0412 58.1608 21.2148 61.4893 21.2148C62.8208 21.2148 63.4865 21.7347 63.4865 22.7343C63.4865 25.3068 61.4361 29.6521 61.4361 33.2776C61.4103 33.4976 61.4297 33.7205 61.493 33.9327C61.5564 34.1449 61.6624 34.342 61.8045 34.5117C61.9466 34.6814 62.1219 34.8203 62.3195 34.9198C62.5172 35.0193 62.7331 35.0774 62.9539 35.0904C63.7115 34.9799 64.4352 34.7026 65.0729 34.2785C65.7106 33.8545 66.2465 33.2941 66.6419 32.6378C67.7957 30.3497 68.3804 27.8165 68.3462 25.2536C68.3462 24.2539 67.9334 24.1339 67.9334 23.3741C67.9334 22.0412 71.0889 21.2148 74.4308 21.2148C75.7622 21.2148 76.4146 21.7347 76.4146 22.7343C76.4146 24.2005 75.7755 24.8403 74.1379 34.4506C72.6067 43.4077 70.7427 56.3503 60.1446 56.3503C55.884 56.3503 52.156 53.8977 52.156 49.7924C52.0486 48.2522 52.5059 46.7256 53.4422 45.4988C54.3785 44.2719 55.7295 43.429 57.2421 43.1278C57.6548 43.1278 57.8811 43.3011 57.8811 43.6477C57.8811 44.2341 57.3619 44.5274 57.3619 45.8203C57.3619 47.5131 58.6933 48.5661 60.6905 48.5661C64.9643 48.5661 66.4822 41.9016 67.3476 36.21C64.9643 38.5142 61.7665 39.7794 58.4537 39.7289C57.8233 39.7798 57.1891 39.7017 56.5898 39.4994C55.9905 39.297 55.4386 38.9747 54.9677 38.5519C54.4969 38.1292 54.1169 37.6149 53.851 37.0404C53.585 36.466 53.4387 35.8433 53.4209 35.2104C53.4209 31.2916 55.3514 27.3062 55.3514 25.2002C55.3514 24.2005 54.952 24.0806 54.952 23.3208"
        fill="#3CCFCF"
      />
      <path
        d="M55.0053 23.3741C55.0053 22.0412 58.1608 21.2148 61.4893 21.2148C62.8208 21.2148 63.4865 21.7347 63.4865 22.7343C63.4865 25.3068 61.4361 29.6521 61.4361 33.2776C61.4103 33.4976 61.4297 33.7205 61.493 33.9327C61.5564 34.1449 61.6624 34.342 61.8045 34.5117C61.9466 34.6814 62.1219 34.8203 62.3195 34.9198C62.5172 35.0193 62.7331 35.0774 62.9539 35.0904C63.7115 34.9799 64.4352 34.7026 65.0729 34.2785C65.7106 33.8545 66.2465 33.2941 66.6419 32.6378C67.7957 30.3497 68.3804 27.8165 68.3462 25.2536C68.3462 24.2539 67.9334 24.1339 67.9334 23.3741C67.9334 22.0412 71.0889 21.2148 74.4308 21.2148C75.7622 21.2148 76.4146 21.7347 76.4146 22.7343C76.4146 24.2005 75.7755 24.8403 74.1379 34.4506C72.6067 43.4077 70.7427 56.3503 60.1446 56.3503C55.884 56.3503 52.156 53.8977 52.156 49.7924C52.0486 48.2522 52.5059 46.7256 53.4422 45.4988C54.3785 44.2719 55.7295 43.429 57.2421 43.1278C57.6548 43.1278 57.8811 43.3011 57.8811 43.6477C57.8811 44.2341 57.3619 44.5274 57.3619 45.8203C57.3619 47.5131 58.6933 48.5661 60.6905 48.5661C64.9643 48.5661 66.4822 41.9016 67.3476 36.21C64.9643 38.5142 61.7665 39.7794 58.4537 39.7289C57.8233 39.7798 57.1891 39.7017 56.5898 39.4994C55.9905 39.297 55.4386 38.9747 54.9677 38.5519C54.4969 38.1292 54.1169 37.6149 53.851 37.0404C53.585 36.466 53.4387 35.8433 53.4209 35.2104C53.4209 31.2916 55.3514 27.3062 55.3514 25.2002C55.3514 24.2005 54.952 24.0806 54.952 23.3208"
        fill="url(#paint2_linear_3_84931)"
      />
      <path
        d="M94.8372 39.7822C93.8572 39.7989 92.8966 39.5085 92.0897 38.9516C91.2828 38.3948 90.6699 37.5993 90.3369 36.6765C88.3274 38.5601 85.711 39.6617 82.9608 39.7822C79.5125 39.7822 76.7031 37.4363 76.7031 32.9311C76.7031 27.1329 80.2181 21.2148 88.5795 21.2148C91.6817 21.2148 92.5737 22.3211 93.4924 22.3211C94.4111 22.3211 97.0473 21.2415 98.3788 21.2415C98.4983 21.2234 98.6204 21.2338 98.7353 21.2716C98.8501 21.3094 98.9545 21.3737 99.04 21.4593C99.1255 21.5449 99.1897 21.6493 99.2274 21.7643C99.2652 21.8793 99.2755 22.0015 99.2575 22.1212C99.2575 23.5208 97.2071 28.0926 97.2071 32.6645C97.1701 32.9542 97.1929 33.2483 97.2741 33.5287C97.3553 33.8092 97.4932 34.07 97.6792 34.2949C97.8651 34.5198 98.0953 34.7041 98.3554 34.8364C98.6154 34.9686 98.8998 35.046 99.1909 35.0637C100.189 35.0637 100.655 34.5972 101.414 33.8375C101.827 33.4243 102.067 33.251 102.413 33.251C102.627 33.2544 102.832 33.3412 102.983 33.4929C103.135 33.6447 103.222 33.8495 103.225 34.0641C103.139 34.7788 102.81 35.4422 102.293 35.9435C101.428 37.1091 100.31 38.0628 99.023 38.7324C97.7359 39.4021 96.3137 39.7701 94.8638 39.8089M89.1253 23.814C85.7968 23.814 84.3322 29.972 84.3322 33.1443C84.3322 34.6639 85.0379 35.9035 86.3294 35.9035C87.6208 35.9035 88.6061 34.9038 89.365 33.1443C90.2808 30.6884 90.7934 28.1001 90.8828 25.4801C90.9126 25.2443 90.8871 25.0047 90.8085 24.7804C90.7298 24.5561 90.6001 24.3532 90.4296 24.1877C90.2592 24.0222 90.0526 23.8987 89.8262 23.8269C89.5999 23.7551 89.3599 23.737 89.1253 23.774"
        fill="#3CCFCF"
      />
      <path
        d="M94.8372 39.7822C93.8572 39.7989 92.8966 39.5085 92.0897 38.9516C91.2828 38.3948 90.6699 37.5993 90.3369 36.6765C88.3274 38.5601 85.711 39.6617 82.9608 39.7822C79.5125 39.7822 76.7031 37.4363 76.7031 32.9311C76.7031 27.1329 80.2181 21.2148 88.5795 21.2148C91.6817 21.2148 92.5737 22.3211 93.4924 22.3211C94.4111 22.3211 97.0473 21.2415 98.3788 21.2415C98.4983 21.2234 98.6204 21.2338 98.7353 21.2716C98.8501 21.3094 98.9545 21.3737 99.04 21.4593C99.1255 21.5449 99.1897 21.6493 99.2274 21.7643C99.2652 21.8793 99.2755 22.0015 99.2575 22.1212C99.2575 23.5208 97.2071 28.0926 97.2071 32.6645C97.1701 32.9542 97.1929 33.2483 97.2741 33.5287C97.3553 33.8092 97.4932 34.07 97.6792 34.2949C97.8651 34.5198 98.0953 34.7041 98.3554 34.8364C98.6154 34.9686 98.8998 35.046 99.1909 35.0637C100.189 35.0637 100.655 34.5972 101.414 33.8375C101.827 33.4243 102.067 33.251 102.413 33.251C102.627 33.2544 102.832 33.3412 102.983 33.4929C103.135 33.6447 103.222 33.8495 103.225 34.0641C103.139 34.7788 102.81 35.4422 102.293 35.9435C101.428 37.1091 100.31 38.0628 99.023 38.7324C97.7359 39.4021 96.3137 39.7701 94.8638 39.8089M89.1253 23.814C85.7968 23.814 84.3322 29.972 84.3322 33.1443C84.3322 34.6639 85.0379 35.9035 86.3294 35.9035C87.6208 35.9035 88.6061 34.9038 89.365 33.1443C90.2808 30.6884 90.7934 28.1001 90.8828 25.4801C90.9126 25.2443 90.8871 25.0047 90.8085 24.7804C90.7298 24.5561 90.6001 24.3532 90.4296 24.1877C90.2592 24.0222 90.0526 23.8987 89.8262 23.8269C89.5999 23.7551 89.3599 23.737 89.1253 23.774"
        fill="url(#paint3_linear_3_84931)"
      />
      <path
        d="M114.261 35.2646C114.261 31.6924 116.125 29.3598 116.125 27.0672C116.125 26.1342 115.605 25.4278 114.793 25.4278C113.981 25.4278 112.397 26.4274 111.119 28.9999C109.785 31.9267 108.935 35.0508 108.602 38.2503C108.602 39.25 108.189 39.4899 107.271 39.4899C105.513 39.4899 103.276 39.7832 102.358 39.7832C101.439 39.7832 101.133 39.4899 101.133 38.4503C101.133 36.2776 103.583 28.3735 103.583 25.2145C103.583 24.2148 103.17 24.0948 103.17 23.3351C103.17 22.0022 106.339 21.1758 109.734 21.1758C111.065 21.1758 111.718 21.6956 111.718 22.6953C111.65 23.597 111.494 24.4899 111.252 25.3611C112.303 24.0282 115.752 21.2024 119.24 21.2024C122.462 21.2024 123.754 22.6686 123.754 24.8946C123.754 27.5604 122.116 30.5061 122.116 32.892C122.116 34.4115 122.928 34.998 123.98 34.998C125.032 34.998 125.445 34.5315 126.204 33.7717C126.616 33.3585 126.856 33.1853 127.202 33.1853C127.416 33.1887 127.621 33.2755 127.773 33.4272C127.924 33.5789 128.011 33.7838 128.014 33.9983C127.928 34.7131 127.599 35.3765 127.082 35.8778C126.217 37.0434 125.099 37.9971 123.812 38.6667C122.525 39.3363 121.103 39.7044 119.653 39.7432C116.324 39.7432 114.327 37.7438 114.327 35.2246"
        fill="#3CCFCF"
      />
      <path
        d="M114.261 35.2646C114.261 31.6924 116.125 29.3598 116.125 27.0672C116.125 26.1342 115.605 25.4278 114.793 25.4278C113.981 25.4278 112.397 26.4274 111.119 28.9999C109.785 31.9267 108.935 35.0508 108.602 38.2503C108.602 39.25 108.189 39.4899 107.271 39.4899C105.513 39.4899 103.276 39.7832 102.358 39.7832C101.439 39.7832 101.133 39.4899 101.133 38.4503C101.133 36.2776 103.583 28.3735 103.583 25.2145C103.583 24.2148 103.17 24.0948 103.17 23.3351C103.17 22.0022 106.339 21.1758 109.734 21.1758C111.065 21.1758 111.718 21.6956 111.718 22.6953C111.65 23.597 111.494 24.4899 111.252 25.3611C112.303 24.0282 115.752 21.2024 119.24 21.2024C122.462 21.2024 123.754 22.6686 123.754 24.8946C123.754 27.5604 122.116 30.5061 122.116 32.892C122.116 34.4115 122.928 34.998 123.98 34.998C125.032 34.998 125.445 34.5315 126.204 33.7717C126.616 33.3585 126.856 33.1853 127.202 33.1853C127.416 33.1887 127.621 33.2755 127.773 33.4272C127.924 33.5789 128.011 33.7838 128.014 33.9983C127.928 34.7131 127.599 35.3765 127.082 35.8778C126.217 37.0434 125.099 37.9971 123.812 38.6667C122.525 39.3363 121.103 39.7044 119.653 39.7432C116.324 39.7432 114.327 37.7438 114.327 35.2246"
        fill="url(#paint4_linear_3_84931)"
      />
      <path
        d="M139.656 36.2101C137.241 38.4293 134.106 39.6982 130.829 39.7822C130.223 39.8138 129.617 39.7193 129.049 39.5048C128.482 39.2903 127.964 38.9604 127.53 38.5359C127.096 38.1114 126.755 37.6015 126.527 37.0383C126.3 36.4751 126.191 35.8709 126.209 35.2637C126.209 31.6382 128.193 27.3595 128.193 25.2536C128.193 24.2539 127.78 24.1339 127.78 23.3741C127.78 22.0412 130.949 21.2148 134.331 21.2148C135.662 21.2148 136.328 21.7347 136.328 22.7343C136.328 25.4002 134.157 29.3989 134.157 33.6242C134.138 33.817 134.16 34.0119 134.223 34.1951C134.286 34.3784 134.389 34.5456 134.523 34.6852C134.658 34.8248 134.821 34.9334 135.001 35.0034C135.182 35.0733 135.376 35.1031 135.569 35.0904C136.308 35.0202 137.017 34.7633 137.629 34.3436C138.242 33.9239 138.738 33.3553 139.07 32.6912C140.332 30.4092 141.035 27.8602 141.121 25.2536C141.121 24.2539 140.708 24.1339 140.708 23.3741C140.708 22.0412 143.877 21.2148 147.272 21.2148C148.603 21.2148 149.256 21.7347 149.256 22.7343C149.256 25.0136 147.205 29.5322 147.205 32.6378C147.205 34.1573 148.031 35.0371 149.083 35.0371C150.135 35.0371 150.547 34.5706 151.306 33.8108C151.719 33.3976 151.945 33.2243 152.305 33.2243C152.519 33.2277 152.724 33.3145 152.875 33.4663C153.027 33.618 153.114 33.8228 153.117 34.0374C153.03 34.7522 152.701 35.4156 152.185 35.9168C151.33 37.0806 150.222 38.0337 148.943 38.7036C147.665 39.3734 146.251 39.7423 144.809 39.7822C140.189 39.7822 139.656 36.2101 139.656 36.2101Z"
        fill="#3CCFCF"
      />
      <path
        d="M139.656 36.2101C137.241 38.4293 134.106 39.6982 130.829 39.7822C130.223 39.8138 129.617 39.7193 129.049 39.5048C128.482 39.2903 127.964 38.9604 127.53 38.5359C127.096 38.1114 126.755 37.6015 126.527 37.0383C126.3 36.4751 126.191 35.8709 126.209 35.2637C126.209 31.6382 128.193 27.3595 128.193 25.2536C128.193 24.2539 127.78 24.1339 127.78 23.3741C127.78 22.0412 130.949 21.2148 134.331 21.2148C135.662 21.2148 136.328 21.7347 136.328 22.7343C136.328 25.4002 134.157 29.3989 134.157 33.6242C134.138 33.817 134.16 34.0119 134.223 34.1951C134.286 34.3784 134.389 34.5456 134.523 34.6852C134.658 34.8248 134.821 34.9334 135.001 35.0034C135.182 35.0733 135.376 35.1031 135.569 35.0904C136.308 35.0202 137.017 34.7633 137.629 34.3436C138.242 33.9239 138.738 33.3553 139.07 32.6912C140.332 30.4092 141.035 27.8602 141.121 25.2536C141.121 24.2539 140.708 24.1339 140.708 23.3741C140.708 22.0412 143.877 21.2148 147.272 21.2148C148.603 21.2148 149.256 21.7347 149.256 22.7343C149.256 25.0136 147.205 29.5322 147.205 32.6378C147.205 34.1573 148.031 35.0371 149.083 35.0371C150.135 35.0371 150.547 34.5706 151.306 33.8108C151.719 33.3976 151.945 33.2243 152.305 33.2243C152.519 33.2277 152.724 33.3145 152.875 33.4663C153.027 33.618 153.114 33.8228 153.117 34.0374C153.03 34.7522 152.701 35.4156 152.185 35.9168C151.33 37.0806 150.222 38.0337 148.943 38.7036C147.665 39.3734 146.251 39.7423 144.809 39.7822C140.189 39.7822 139.656 36.2101 139.656 36.2101Z"
        fill="url(#paint5_linear_3_84931)"
      />
      <path
        d="M156.028 33.9824C156.028 35.4353 156.56 36.4349 157.612 36.4349C158.424 36.4349 158.944 35.9151 158.944 34.8621C158.944 31.3966 153.152 30.8634 153.152 26.4781C153.152 22.786 156.827 20.9199 161.22 20.9199C165.614 20.9199 167.425 22.5594 167.425 24.9186C167.472 25.3026 167.434 25.6921 167.315 26.0602C167.197 26.4283 166.999 26.7661 166.737 27.0502C166.475 27.3342 166.154 27.5578 165.797 27.7053C165.44 27.8528 165.055 27.9207 164.669 27.9043C164.129 27.9118 163.608 27.7087 163.215 27.338C162.822 26.9672 162.589 26.4581 162.565 25.9183C162.565 25.0386 163.031 24.6787 163.031 24.1589C163.031 23.639 162.565 23.3325 161.926 23.3325C161.454 23.3882 161.019 23.6162 160.705 23.9728C160.391 24.3294 160.219 24.7895 160.222 25.2652C160.222 28.7307 166.666 29.1973 166.666 33.4626C166.666 37.2747 163.617 39.8472 158.464 39.8472C154.084 39.8472 150.862 37.9145 150.862 34.7555C150.837 34.3835 150.886 34.0102 151.007 33.6575C151.128 33.3048 151.318 32.9799 151.566 32.7018C151.814 32.4236 152.115 32.1979 152.451 32.0379C152.788 31.8778 153.153 31.7867 153.525 31.7698C155.109 31.7698 156.041 32.5295 156.041 33.9424"
        fill="#3CCFCF"
      />
      <path
        d="M156.028 33.9824C156.028 35.4353 156.56 36.4349 157.612 36.4349C158.424 36.4349 158.944 35.9151 158.944 34.8621C158.944 31.3966 153.152 30.8634 153.152 26.4781C153.152 22.786 156.827 20.9199 161.22 20.9199C165.614 20.9199 167.425 22.5594 167.425 24.9186C167.472 25.3026 167.434 25.6921 167.315 26.0602C167.197 26.4283 166.999 26.7661 166.737 27.0502C166.475 27.3342 166.154 27.5578 165.797 27.7053C165.44 27.8528 165.055 27.9207 164.669 27.9043C164.129 27.9118 163.608 27.7087 163.215 27.338C162.822 26.9672 162.589 26.4581 162.565 25.9183C162.565 25.0386 163.031 24.6787 163.031 24.1589C163.031 23.639 162.565 23.3325 161.926 23.3325C161.454 23.3882 161.019 23.6162 160.705 23.9728C160.391 24.3294 160.219 24.7895 160.222 25.2652C160.222 28.7307 166.666 29.1973 166.666 33.4626C166.666 37.2747 163.617 39.8472 158.464 39.8472C154.084 39.8472 150.862 37.9145 150.862 34.7555C150.837 34.3835 150.886 34.0102 151.007 33.6575C151.128 33.3048 151.318 32.9799 151.566 32.7018C151.814 32.4236 152.115 32.1979 152.451 32.0379C152.788 31.8778 153.153 31.7867 153.525 31.7698C155.109 31.7698 156.041 32.5295 156.041 33.9424"
        fill="url(#paint6_linear_3_84931)"
      />
      <path
        d="M220.611 41.9843C219.633 41.998 218.675 41.7079 217.87 41.1541C217.064 40.6003 216.449 39.81 216.11 38.8919C214.098 40.7686 211.482 41.8651 208.734 41.9843C205.286 41.9843 202.477 39.6517 202.477 35.1331C202.477 29.335 205.992 23.4168 214.353 23.4168C217.455 23.4168 218.347 24.5365 219.266 24.5365C220.185 24.5365 222.781 23.4168 224.126 23.4168C224.245 23.3988 224.367 23.4091 224.482 23.4469C224.597 23.4848 224.701 23.5491 224.787 23.6347C224.872 23.7203 224.936 23.8247 224.974 23.9397C225.012 24.0547 225.022 24.1769 225.004 24.2966C225.004 25.7094 222.954 30.2813 222.954 34.8399C222.915 35.1316 222.937 35.4282 223.018 35.7111C223.099 35.994 223.238 36.2571 223.425 36.4837C223.613 36.7103 223.845 36.8956 224.108 37.0279C224.37 37.1602 224.657 37.2366 224.951 37.2524C225.936 37.2524 226.402 36.7726 227.175 36.0128C227.574 35.613 227.814 35.4263 228.16 35.4263C228.378 35.4298 228.586 35.518 228.74 35.6722C228.894 35.8264 228.982 36.0346 228.985 36.2527C228.985 36.8925 228.626 37.3591 228.04 38.1188C227.177 39.2867 226.059 40.2419 224.772 40.9118C223.484 41.5816 222.061 41.9485 220.611 41.9843ZM214.885 25.9894C211.544 25.9894 210.079 32.1341 210.079 35.3197C210.079 36.8392 210.785 38.0655 212.076 38.0655C213.368 38.0655 214.353 37.0792 215.112 35.3197C216.034 32.8604 216.551 30.2674 216.643 27.6422C216.67 27.4074 216.643 27.1694 216.564 26.9468C216.484 26.7242 216.354 26.5231 216.184 26.3592C216.014 26.1953 215.808 26.073 215.583 26.0019C215.357 25.9308 215.119 25.9128 214.885 25.9494"
        fill="#3CCFCF"
      />
      <path
        d="M220.611 41.9843C219.633 41.998 218.675 41.7079 217.87 41.1541C217.064 40.6003 216.449 39.81 216.11 38.8919C214.098 40.7686 211.482 41.8651 208.734 41.9843C205.286 41.9843 202.477 39.6517 202.477 35.1331C202.477 29.335 205.992 23.4168 214.353 23.4168C217.455 23.4168 218.347 24.5365 219.266 24.5365C220.185 24.5365 222.781 23.4168 224.126 23.4168C224.245 23.3988 224.367 23.4091 224.482 23.4469C224.597 23.4848 224.701 23.5491 224.787 23.6347C224.872 23.7203 224.936 23.8247 224.974 23.9397C225.012 24.0547 225.022 24.1769 225.004 24.2966C225.004 25.7094 222.954 30.2813 222.954 34.8399C222.915 35.1316 222.937 35.4282 223.018 35.7111C223.099 35.994 223.238 36.2571 223.425 36.4837C223.613 36.7103 223.845 36.8956 224.108 37.0279C224.37 37.1602 224.657 37.2366 224.951 37.2524C225.936 37.2524 226.402 36.7726 227.175 36.0128C227.574 35.613 227.814 35.4263 228.16 35.4263C228.378 35.4298 228.586 35.518 228.74 35.6722C228.894 35.8264 228.982 36.0346 228.985 36.2527C228.985 36.8925 228.626 37.3591 228.04 38.1188C227.177 39.2867 226.059 40.2419 224.772 40.9118C223.484 41.5816 222.061 41.9485 220.611 41.9843ZM214.885 25.9894C211.544 25.9894 210.079 32.1341 210.079 35.3197C210.079 36.8392 210.785 38.0655 212.076 38.0655C213.368 38.0655 214.353 37.0792 215.112 35.3197C216.034 32.8604 216.551 30.2674 216.643 27.6422C216.67 27.4074 216.643 27.1694 216.564 26.9468C216.484 26.7242 216.354 26.5231 216.184 26.3592C216.014 26.1953 215.808 26.073 215.583 26.0019C215.357 25.9308 215.119 25.9128 214.885 25.9494"
        fill="url(#paint7_linear_3_84931)"
      />
      <path
        d="M239.734 37.4748C239.734 34.3159 241.611 31.2635 241.611 29.0375C241.621 28.85 241.591 28.6626 241.523 28.4875C241.456 28.3124 241.352 28.1535 241.219 28.0214C241.086 27.8893 240.926 27.7868 240.75 27.7208C240.575 27.6547 240.387 27.6265 240.2 27.638C238.975 27.638 237.684 28.9709 236.525 31.2635C235.33 34.2161 234.502 37.3051 234.062 40.4605C234.062 41.4602 233.649 41.7001 232.837 41.7001C231.013 41.7001 228.683 41.9801 227.738 41.9801C227.046 41.9801 226.633 41.7002 226.633 40.6472C226.633 35.6621 231.785 22.7196 231.785 14.5222C231.785 12.5895 230.787 11.6564 229.562 11.6564C229.029 11.6564 228.683 11.4165 228.683 10.95C228.683 9.72372 231.785 7.79102 235.114 7.79102C238.216 7.79102 240.094 9.43047 240.094 12.3495C240.094 15.6418 237.511 24.4257 236.698 27.0115C237.644 25.9244 238.801 25.0421 240.099 24.4186C241.397 23.7952 242.809 23.4438 244.248 23.386C247.456 23.386 249.214 24.8389 249.214 27.3847C249.214 30.3171 247.456 32.5964 247.456 35.2356C247.426 35.4908 247.45 35.7497 247.529 35.9945C247.607 36.2393 247.737 36.4643 247.91 36.6541C248.083 36.8439 248.295 36.9941 248.532 37.0944C248.768 37.1946 249.024 37.2425 249.28 37.2349C250.266 37.2349 250.732 36.7551 251.491 35.9953C251.903 35.5954 252.143 35.4088 252.489 35.4088C252.707 35.4123 252.915 35.5004 253.069 35.6547C253.223 35.8089 253.311 36.0172 253.315 36.2353C253.315 36.8751 252.955 37.3416 252.369 38.1147C251.504 39.2786 250.385 40.2302 249.098 40.8976C247.811 41.5649 246.389 41.9305 244.94 41.9667C241.372 41.9667 239.734 40.154 239.734 37.4615"
        fill="#3CCFCF"
      />
      <path
        d="M239.734 37.4748C239.734 34.3159 241.611 31.2635 241.611 29.0375C241.621 28.85 241.591 28.6626 241.523 28.4875C241.456 28.3124 241.352 28.1535 241.219 28.0214C241.086 27.8893 240.926 27.7868 240.75 27.7208C240.575 27.6547 240.387 27.6265 240.2 27.638C238.975 27.638 237.684 28.9709 236.525 31.2635C235.33 34.2161 234.502 37.3051 234.062 40.4605C234.062 41.4602 233.649 41.7001 232.837 41.7001C231.013 41.7001 228.683 41.9801 227.738 41.9801C227.046 41.9801 226.633 41.7002 226.633 40.6472C226.633 35.6621 231.785 22.7196 231.785 14.5222C231.785 12.5895 230.787 11.6564 229.562 11.6564C229.029 11.6564 228.683 11.4165 228.683 10.95C228.683 9.72372 231.785 7.79102 235.114 7.79102C238.216 7.79102 240.094 9.43047 240.094 12.3495C240.094 15.6418 237.511 24.4257 236.698 27.0115C237.644 25.9244 238.801 25.0421 240.099 24.4186C241.397 23.7952 242.809 23.4438 244.248 23.386C247.456 23.386 249.214 24.8389 249.214 27.3847C249.214 30.3171 247.456 32.5964 247.456 35.2356C247.426 35.4908 247.45 35.7497 247.529 35.9945C247.607 36.2393 247.737 36.4643 247.91 36.6541C248.083 36.8439 248.295 36.9941 248.532 37.0944C248.768 37.1946 249.024 37.2425 249.28 37.2349C250.266 37.2349 250.732 36.7551 251.491 35.9953C251.903 35.5954 252.143 35.4088 252.489 35.4088C252.707 35.4123 252.915 35.5004 253.069 35.6547C253.223 35.8089 253.311 36.0172 253.315 36.2353C253.315 36.8751 252.955 37.3416 252.369 38.1147C251.504 39.2786 250.385 40.2302 249.098 40.8976C247.811 41.5649 246.389 41.9305 244.94 41.9667C241.372 41.9667 239.734 40.154 239.734 37.4615"
        fill="url(#paint8_linear_3_84931)"
      />
      <path
        d="M251.566 37.1299C251.566 33.3845 253.497 29.5724 253.497 27.4664C253.497 26.4667 253.137 26.3467 253.137 25.587C253.137 24.2541 256.24 23.4277 259.635 23.4277C260.966 23.4277 261.632 23.9475 261.632 24.9472C261.632 27.2931 259.755 30.9719 259.755 34.8373C259.755 36.3702 260.633 37.2499 261.685 37.2499C262.737 37.2499 263.15 36.7701 263.909 36.0103C264.321 35.6104 264.548 35.4238 264.907 35.4238C265.123 35.4307 265.327 35.5203 265.479 35.6742C265.63 35.8281 265.716 36.0344 265.719 36.2502C265.719 36.89 265.373 37.3565 264.787 38.1296C263.923 39.2953 262.804 40.249 261.517 40.9186C260.23 41.5882 258.808 41.9563 257.358 41.9951C253.497 41.9951 251.566 39.8224 251.566 37.1299ZM259.342 12.3512C259.767 12.3109 260.196 12.3623 260.6 12.5017C261.004 12.6411 261.374 12.8654 261.684 13.1595C261.994 13.4537 262.238 13.8109 262.399 14.2071C262.56 14.6033 262.634 15.0295 262.617 15.4569C262.617 17.8028 260.514 20.322 258.29 20.322C257.867 20.3291 257.447 20.2526 257.053 20.097C256.659 19.9414 256.3 19.7097 255.996 19.4151C255.692 19.1205 255.449 18.7688 255.28 18.3801C255.112 17.9914 255.022 17.5733 255.015 17.1497C255.038 15.9623 255.489 14.8232 256.284 13.9417C257.078 13.0602 258.164 12.4954 259.342 12.3512Z"
        fill="#3CCFCF"
      />
      <path
        d="M251.566 37.1299C251.566 33.3845 253.497 29.5724 253.497 27.4664C253.497 26.4667 253.137 26.3467 253.137 25.587C253.137 24.2541 256.24 23.4277 259.635 23.4277C260.966 23.4277 261.632 23.9475 261.632 24.9472C261.632 27.2931 259.755 30.9719 259.755 34.8373C259.755 36.3702 260.633 37.2499 261.685 37.2499C262.737 37.2499 263.15 36.7701 263.909 36.0103C264.321 35.6104 264.548 35.4238 264.907 35.4238C265.123 35.4307 265.327 35.5203 265.479 35.6742C265.63 35.8281 265.716 36.0344 265.719 36.2502C265.719 36.89 265.373 37.3565 264.787 38.1296C263.923 39.2953 262.804 40.249 261.517 40.9186C260.23 41.5882 258.808 41.9563 257.358 41.9951C253.497 41.9951 251.566 39.8224 251.566 37.1299ZM259.342 12.3512C259.767 12.3109 260.196 12.3623 260.6 12.5017C261.004 12.6411 261.374 12.8654 261.684 13.1595C261.994 13.4537 262.238 13.8109 262.399 14.2071C262.56 14.6033 262.634 15.0295 262.617 15.4569C262.617 17.8028 260.514 20.322 258.29 20.322C257.867 20.3291 257.447 20.2526 257.053 20.097C256.659 19.9414 256.3 19.7097 255.996 19.4151C255.692 19.1205 255.449 18.7688 255.28 18.3801C255.112 17.9914 255.022 17.5733 255.015 17.1497C255.038 15.9623 255.489 14.8232 256.284 13.9417C257.078 13.0602 258.164 12.4954 259.342 12.3512Z"
        fill="url(#paint9_linear_3_84931)"
      />
      <path
        d="M268.002 36.1839C268.002 37.6501 268.521 38.6498 269.573 38.6498C270.399 38.6498 270.905 38.1167 270.905 37.0637C270.905 33.6114 265.113 33.0649 265.113 28.693C265.113 25.0009 268.801 23.1348 273.181 23.1348C277.562 23.1348 279.386 24.7742 279.386 27.1335C279.432 27.5175 279.395 27.907 279.276 28.2751C279.158 28.6432 278.96 28.981 278.698 29.265C278.436 29.5491 278.115 29.7727 277.758 29.9202C277.401 30.0677 277.016 30.1356 276.63 30.1192C276.088 30.1266 275.565 29.9216 275.172 29.548C274.778 29.1745 274.547 28.662 274.526 28.1199C274.526 27.2535 274.992 26.8936 274.992 26.3737C274.992 25.8539 274.526 25.5473 273.887 25.5473C273.416 25.6033 272.983 25.8318 272.671 26.1887C272.359 26.5456 272.189 27.0056 272.196 27.4801C272.196 30.9323 278.627 31.3988 278.627 35.6774C278.627 39.4895 275.578 42.0621 270.439 42.0621C266.045 42.0621 262.823 40.1293 262.823 36.9704C262.798 36.5983 262.847 36.225 262.968 35.8724C263.089 35.5197 263.279 35.1947 263.527 34.9166C263.775 34.6385 264.076 34.4128 264.412 34.2527C264.749 34.0927 265.114 34.0016 265.486 33.9847C267.057 33.9847 268.002 34.7444 268.002 36.144"
        fill="#3CCFCF"
      />
      <path
        d="M268.002 36.1839C268.002 37.6501 268.521 38.6498 269.573 38.6498C270.399 38.6498 270.905 38.1167 270.905 37.0637C270.905 33.6114 265.113 33.0649 265.113 28.693C265.113 25.0009 268.801 23.1348 273.181 23.1348C277.562 23.1348 279.386 24.7742 279.386 27.1335C279.432 27.5175 279.395 27.907 279.276 28.2751C279.158 28.6432 278.96 28.981 278.698 29.265C278.436 29.5491 278.115 29.7727 277.758 29.9202C277.401 30.0677 277.016 30.1356 276.63 30.1192C276.088 30.1266 275.565 29.9216 275.172 29.548C274.778 29.1745 274.547 28.662 274.526 28.1199C274.526 27.2535 274.992 26.8936 274.992 26.3737C274.992 25.8539 274.526 25.5473 273.887 25.5473C273.416 25.6033 272.983 25.8318 272.671 26.1887C272.359 26.5456 272.189 27.0056 272.196 27.4801C272.196 30.9323 278.627 31.3988 278.627 35.6774C278.627 39.4895 275.578 42.0621 270.439 42.0621C266.045 42.0621 262.823 40.1293 262.823 36.9704C262.798 36.5983 262.847 36.225 262.968 35.8724C263.089 35.5197 263.279 35.1947 263.527 34.9166C263.775 34.6385 264.076 34.4128 264.412 34.2527C264.749 34.0927 265.114 34.0016 265.486 33.9847C267.057 33.9847 268.002 34.7444 268.002 36.144"
        fill="url(#paint10_linear_3_84931)"
      />
      <path
        d="M192.338 4.70498C197.663 4.58502 202.457 7.30413 202.457 12.2226C202.457 17.5542 198.05 20.8598 193.43 21.4196C196.585 21.6995 201.498 25.4184 201.498 30.1235C201.498 39.214 194.495 41.8664 188.863 41.8664C184.802 41.8664 182.033 39.8271 181.247 39.8271C179.916 39.8271 177.066 41.8664 175.495 41.8664C174.923 41.8664 174.59 41.5865 174.59 41.1333C174.59 40.4002 175.429 40.0537 176.454 37.2945C177.346 34.802 180.062 19.2736 181.633 13.1823C182.23 10.7932 183.597 8.66788 185.523 7.13535C187.448 5.60283 189.825 4.74862 192.285 4.70498M187.345 22.4326C187.345 22.4326 185.028 34.7487 185.028 36.2682C185.014 36.6033 185.067 36.9378 185.185 37.2517C185.303 37.5657 185.483 37.8526 185.714 38.0953C185.945 38.3379 186.223 38.5314 186.531 38.6641C186.838 38.7967 187.17 38.8659 187.505 38.8674C189.821 38.8674 193.03 35.935 193.03 28.0309C193.07 27.2773 192.949 26.5238 192.676 25.8205C192.403 25.1171 191.984 24.4797 191.446 23.9505C190.909 23.4214 190.265 23.0124 189.558 22.7508C188.851 22.4891 188.097 22.3807 187.345 22.4326ZM194.947 11.0363C194.947 8.77035 191.432 8.58373 190.221 10.823C189.027 13.6171 188.302 16.5896 188.077 19.6202C192.071 19.6202 194.947 15.2083 194.947 11.0363Z"
        fill="#3CCFCF"
      />
      <path
        d="M192.338 4.70498C197.663 4.58502 202.457 7.30413 202.457 12.2226C202.457 17.5542 198.05 20.8598 193.43 21.4196C196.585 21.6995 201.498 25.4184 201.498 30.1235C201.498 39.214 194.495 41.8664 188.863 41.8664C184.802 41.8664 182.033 39.8271 181.247 39.8271C179.916 39.8271 177.066 41.8664 175.495 41.8664C174.923 41.8664 174.59 41.5865 174.59 41.1333C174.59 40.4002 175.429 40.0537 176.454 37.2945C177.346 34.802 180.062 19.2736 181.633 13.1823C182.23 10.7932 183.597 8.66788 185.523 7.13535C187.448 5.60283 189.825 4.74862 192.285 4.70498M187.345 22.4326C187.345 22.4326 185.028 34.7487 185.028 36.2682C185.014 36.6033 185.067 36.9378 185.185 37.2517C185.303 37.5657 185.483 37.8526 185.714 38.0953C185.945 38.3379 186.223 38.5314 186.531 38.6641C186.838 38.7967 187.17 38.8659 187.505 38.8674C189.821 38.8674 193.03 35.935 193.03 28.0309C193.07 27.2773 192.949 26.5238 192.676 25.8205C192.403 25.1171 191.984 24.4797 191.446 23.9505C190.909 23.4214 190.265 23.0124 189.558 22.7508C188.851 22.4891 188.097 22.3807 187.345 22.4326ZM194.947 11.0363C194.947 8.77035 191.432 8.58373 190.221 10.823C189.027 13.6171 188.302 16.5896 188.077 19.6202C192.071 19.6202 194.947 15.2083 194.947 11.0363Z"
        fill="url(#paint11_linear_3_84931)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3_84931"
          x1="17.0807"
          y1="8.87346"
          x2="15.3794"
          y2="41.0127"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8EFFF8" />
          <stop
            offset="1"
            stopColor="#8EFFF8"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3_84931"
          x1="43.4735"
          y1="12.7144"
          x2="41.6336"
          y2="40.5021"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8EFFF8" />
          <stop
            offset="1"
            stopColor="#8EFFF8"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3_84931"
          x1="66.923"
          y1="25.9892"
          x2="62.712"
          y2="56.9633"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8EFFF8" />
          <stop
            offset="1"
            stopColor="#8EFFF8"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3_84931"
          x1="90.4062"
          y1="25.0885"
          x2="89.935"
          y2="40.2527"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8EFFF8" />
          <stop
            offset="1"
            stopColor="#8EFFF8"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3_84931"
          x1="115.022"
          y1="25.0523"
          x2="114.556"
          y2="40.2277"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8EFFF8" />
          <stop
            offset="1"
            stopColor="#8EFFF8"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3_84931"
          x1="140.111"
          y1="25.0842"
          x2="139.647"
          y2="40.232"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8EFFF8" />
          <stop
            offset="1"
            stopColor="#8EFFF8"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3_84931"
          x1="159.426"
          y1="24.863"
          x2="158.647"
          y2="40.2745"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8EFFF8" />
          <stop
            offset="1"
            stopColor="#8EFFF8"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3_84931"
          x1="216.173"
          y1="27.2783"
          x2="215.702"
          y2="42.4282"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8EFFF8" />
          <stop
            offset="1"
            stopColor="#8EFFF8"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint8_linear_3_84931"
          x1="240.418"
          y1="14.9136"
          x2="238.838"
          y2="42.7334"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8EFFF8" />
          <stop
            offset="1"
            stopColor="#8EFFF8"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint9_linear_3_84931"
          x1="258.879"
          y1="18.5164"
          x2="256.649"
          y2="42.5193"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8EFFF8" />
          <stop
            offset="1"
            stopColor="#8EFFF8"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint10_linear_3_84931"
          x1="271.387"
          y1="27.0779"
          x2="270.608"
          y2="42.4894"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8EFFF8" />
          <stop
            offset="1"
            stopColor="#8EFFF8"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint11_linear_3_84931"
          x1="188.988"
          y1="12.4438"
          x2="187.2"
          y2="42.6772"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8EFFF8" />
          <stop
            offset="1"
            stopColor="#8EFFF8"
            stopOpacity="0"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
