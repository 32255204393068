import React from "react";
import CommonFooterTermsAndConditions from "./CommonFooterTermsAndConditions";
import { useTranslation } from "react-i18next";


const DisputeResolution = () => {
  const { t } = useTranslation();
	
  return (
    <CommonFooterTermsAndConditions 
			title={t("Dispute Resolutions")} 
			data={t("dispute_resolutions_text")} 
		/>
  );
};

export default DisputeResolution;
