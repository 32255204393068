import { useTranslation } from "react-i18next";

export default function FooterBottom() {
  const { t } = useTranslation();

  return (
    <>
      <div className="footer-text">
        {t("copyright_title")}
        <br />
        <br />
        {/* todo: kristi <p dangerouslySetInnerHTML={{ __html: t("copyright_text") }}></p> */}
        <p>
          OkyanusBahis is operated by R. Bostock Enterprises B.V. (reg. nr. 159489), Managing Director Kurason Trust, Schottegatweg Oost 10 Unit 1-9 Bon Bini Business Center, Curaçao.
          <br />
          <br />
          Payments and financial transactions are processed by R. Bostock Enterprises Ltd (registration number HE 424401) of Agiou Andreou, 3 Pallouriotissa, 1040, Nicosia, Cyprus. By accessing, or continuing to use or browse this website, you agree that we may use certain cookies to improve your experience with us. We only use cookies which will enhance your experience and which will not interfere with your privacy. In order to register for this website, the user is required to accept the General Terms and Conditions. In the event the General Terms and Conditions are updated, existing users may choose to discontinue using the products and services before the said update shall become effective, which is a minimum of two weeks after it has been announced.
        </p>
      </div>
    </>
  );
}
