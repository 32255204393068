import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { saveActivity } from "../../../Components/ActivityTracking/activityTrackingService";
import CasinoCardGameModal from "../../../Components/Models/CasinoCardGameModal";
import { Images } from "../../../Images";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { getGames } from "../Services/actions";

import GameCard from "../../../Components/Game/GameCard";
import { loginModal } from "../../../Components/Login/actions";

import SwiperCore, {
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";

const GamesSlider = (props) => {
  const {
    gameHeading,
    getGames,
    GamesData,
    fullscreen,
    Top10Number,
    isRoundImage,
    isLiveCasino,
    loginModal,
  } = props;
  const [gamemodel, setgamemodel] = useState(false);
  const [gameName, setgameName] = useState(false);

  const showgameUrl = useSelector((state) => state?.homeReducer?.gameUrl?.data);

  const LanguageId = getItemFromLocalStorage("i18nextLng");
  const PartnerId = getItemFromLocalStorage("PartnerId") || 43;
  const Token = getItemFromLocalStorage("Token");
  const CliendId = getItemFromLocalStorage("Id");
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handlegames = (ProductId) => {
    let payload = {
      Loader: true,
      TimeZone: 0,
      Controller: "Main",
      LanguageId: LanguageId,
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: null,
      Email: null,
      Code: null,
      RequestData: "{}",
      PartnerId: PartnerId,
      ProductId: ProductId,
      Method: "GetProductUrl",
      IsForDemo: false,
      IsForMobile: isMobile ? true : false,
      Position: "",
      DeviceType: 1,
    };
    if (Token) {
      payload = {
        ...payload,
        Token: Token,
        ClientId: CliendId,
      };
    }
    getGames(payload);
  };

  const activityTrack = async (game) => {
    saveActivity({
      EventPage: "HomePage",
      EventType: "GameLaunch",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: game?.content?.replace("<br/>", "") || "",
    });
  };

  SwiperCore.use([Mousewheel, Pagination, Navigation, FreeMode]);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  // useEffect(() => {
  //   if (showgameUrl) {
  //     if (showgameUrl?.ResponseCode === 21) {
  //       setgamemodel(false);
  //       loginModal(true);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [showgameUrl]);
  return (
    <div className="gamesSlider">
      <p
        className={`${themeMode === "light" ? "light-effect" : ""
          } gamesSlider-title`}
        data-e2e={gameHeading}
      >
        {gameHeading}
      </p>
      <div className="gamesSlider-btns">
        <button ref={navigationPrevRef} className="gamesSlider-btns-prev">
          <img
            width="49"
            height="49"
            loading="lazy"
						src={Images.sliderArrowLeft}
            // src={
            //   themeMode === "dark"
            //     ? Images.sliderArrowLeft
            //     : Images.sliderArrowLeftLight
            // }
            alt="icon"
          />
        </button>
        <button ref={navigationNextRef} className="gamesSlider-btns-next">
          <img
            width="49"
            height="49"
            loading="lazy"
						src={Images.sliderArrowLeft}
            // src={
            //   themeMode === "dark"
            //     ? Images.sliderArrowLeft
            //     : Images.sliderArrowLeftLight
            // }
            alt="icon"
          />
        </button>
      </div>

      {GamesData && GamesData.length > 0 ? (
        <Swiper
          className={`${Top10Number ? "pt-4" : ""} ${isLiveCasino ? "pt-3" : ""
            }`}
          modules={[Mousewheel, FreeMode, Navigation]}
          slidesPerView={5}
          slidesPerGroup={5}
          freeMode={true}
          mousewheel={{ releaseOnEdges: true, forceToAxis: true }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          breakpoints={{
            2571: {
              slidesPerView: 7,
              slidesPerGroup: 7,
            },
            1321: {
              slidesPerView: 5,
              slidesPerGroup: 5,
            },
            801: {
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
            501: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
            0: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
        >
          {GamesData.map((game, index) => {
            return (
              <SwiperSlide key={`${game.ProductId}-${gameHeading + index}`}>
                <div className="gamesSlider-slide">
                  {/* {GamesData.length} */}
                  <GameCard
                    isTop10={Top10Number}
                    index={index}
                    isRoundImage={isRoundImage}
                    handleClick={() => {
                      if (gameHeading === "Live Casino" && !Token) {
                        loginModal(true);
                      } else {
                        handlegames(game.ProductId);
                        setgameName(game.keyName);
                        setgamemodel(true);
                        activityTrack(game);
                      }
                    }}
                    key={game.ProductId}
                    {...game}
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : null}
      <CasinoCardGameModal
        gamemodel={gamemodel}
        setgamemodel={setgamemodel}
        gameName={gameName}
        showgameUrl={showgameUrl}
        fullscreen={fullscreen}
      />
    </div>
  );
};

export default connect(null, {
  getGames,
  loginModal,
})(GamesSlider);
