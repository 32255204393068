/* eslint-disable */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import routes from "../../Route/URLs";
import { loginModal, logout, registerModal } from "../Login/actions";
import Login from "../Models/Login/Login";
import NavbarLang from "./components/NavbarLang";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { Images } from "../../Images";
// import NavbarProfileMenu from "./components/NavbarProfileMenu";
import ProductLinks from "./ProductLinks";

const NavbarLanding = (props) => {
  const history = useHistory();
  const { loginModal, logout } = props;
  const loggedIn = getItemFromLocalStorage("Token");
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { t } = useTranslation();
  const closeModal = useSelector((state) => state?.loginReducer?.closeModal);

  const [mobileMenu, setMobileMenu] = useState(false);

  const toggleMobileMenu = () => setMobileMenu((prev) => !prev);

  useEffect(() => {
    if (loggedIn) {
      setIsLoggedIn(true);
      loginModal(false);
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  useEffect(() => {
    if (closeModal === true) {
      loginModal(false);
    }
  }, [closeModal, loginModal]);

  const showRegisterModal = () => {
    dispatch(registerModal(true));
  };

  const showLoginModal = () => {
    dispatch(loginModal(true));
  };

  const HandleLogout = () => {
    setIsLoggedIn(false);
    logout(false);
    history.push(routes.HOME_PAGE);
    window.location.reload();
  };

  return (
    <>
      <div className={`landHeader ${mobileMenu ? "opened" : ""}`}>
        <div className="landHeader__products">
          <ProductLinks />
        </div>
        <NavLink
          to={routes.HOME_PAGE}
          className="landHeader__logo">
          <img
            src={Images.oceanBetLandingLogo}
            alt=""
          />
        </NavLink>
        <div className="landHeader__actions">
          <div className="landHeader__actions-btns">
            <NavLink
              to={routes.SUPPORT}
              className="landHeader__actions-item mr">
              <div className="landHeader__actions-rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none">
                  <path
                    d="M9.05078 9.25622C9.05078 9.25622 9.12503 7.55466 10.7802 6.19031C11.763 5.38027 12.9432 5.14566 14.0008 5.13122C14.9665 5.11936 15.8297 5.28281 16.3458 5.53444C17.2281 5.96653 18.9508 7.01841 18.9508 9.25622C18.9508 11.6111 17.4462 12.6784 15.7359 13.8546C14.0255 15.0307 13.5883 16.1842 13.5883 17.5062"
                    stroke="white"
                    strokeWidth="2.2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M13.5875 23.0744C14.4988 23.0744 15.2375 22.3357 15.2375 21.4244C15.2375 20.5131 14.4988 19.7744 13.5875 19.7744C12.6762 19.7744 11.9375 20.5131 11.9375 21.4244C11.9375 22.3357 12.6762 23.0744 13.5875 23.0744Z"
                    fill="white"
                  />
                </svg>
              </div>
            </NavLink>
            <div className="landHeader__actions-item mr">
              <NavbarLang />
            </div>
            {/*<NavbarProfileMenu logoutHandler={HandleLogout} />*/}
          </div>
        </div>
        <button
          type="button"
          onClick={toggleMobileMenu}
          className="landHeader__mobileBtn">
          {!mobileMenu ? (
            <svg
              width="20"
              height="10"
              viewBox="0 0 20 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 1.5H19M1 8.5H19"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>
      </div>
      <Login setRegisterModal={showRegisterModal} />
      <div className={`landHeader__mobileMenu ${mobileMenu ? "opened" : ""}`}>
        <div className="landHeader__mobileMenu-links">
          <ProductLinks />
        </div>
        <div className="landHeader__mobileMenu-bottom">
          <div className="landHeader__mobileMenu-actions landHeader__actions">
            <NavLink
              to={routes.SUPPORT}
              className="landHeader__actions-item mr">
              <div className="landHeader__actions-rounded">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none">
                  <path
                    d="M9.05078 9.25622C9.05078 9.25622 9.12503 7.55466 10.7802 6.19031C11.763 5.38027 12.9432 5.14566 14.0008 5.13122C14.9665 5.11936 15.8297 5.28281 16.3458 5.53444C17.2281 5.96653 18.9508 7.01841 18.9508 9.25622C18.9508 11.6111 17.4462 12.6784 15.7359 13.8546C14.0255 15.0307 13.5883 16.1842 13.5883 17.5062"
                    stroke="white"
                    strokeWidth="2.2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M13.5875 23.0744C14.4988 23.0744 15.2375 22.3357 15.2375 21.4244C15.2375 20.5131 14.4988 19.7744 13.5875 19.7744C12.6762 19.7744 11.9375 20.5131 11.9375 21.4244C11.9375 22.3357 12.6762 23.0744 13.5875 23.0744Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p>{t("Support")}</p>
            </NavLink>
            <div className="landHeader__actions-item mr">
              <NavbarLang isTablet={true} />
              <p>{t("Language")}</p>
            </div>
          </div>
          {/*{!isLoggedIn ?*/}
          {/*    <div className="landHeader__mobileMenu-btns">*/}
          {/*        <NavLink to="/sign-up" className="theme-btn fill-btn header-btn">*/}
          {/*            /!* todo:kris add translations *!/*/}
          {/*            <span data-e2e={t("Sign Up")}>{t("Join")}</span>*/}
          {/*        </NavLink>*/}
          {/*        <button onClick={showLoginModal} className="landHeader__actions-login header-btn">*/}
          {/*            <span data-e2e={t("Sign In")}>{t("Sign In")}</span>*/}
          {/*        </button>*/}
          {/*    </div>*/}
          {/*    :*/}
          {/*    <div className="landHeader__mobileMenu-btns">*/}
          {/*        <NavLink to="/casher/wallets" className="theme-btn fill-btn header-btn">*/}
          {/*            <span data-e2e={t("Sign Up")}>{t("Profile")}</span>*/}
          {/*        </NavLink>*/}
          {/*        <button onClick={HandleLogout} className="landHeader__actions-login header-btn">*/}
          {/*            <span>{t("Logout")}</span>*/}
          {/*        </button>*/}
          {/*    </div>*/}
          {/*}*/}
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  loginModal,
  logout,
})(NavbarLanding);
