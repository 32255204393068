import BasicLayout from "../../../BasicLayout";
// import { links, terms } from "../lib/constants";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
// import "./termsCondition.css";
import { Images } from "../../../Images";

const TermsCondition = () => {
	const iframeRef = useRef(null);
  	const { t } = useTranslation();
	
	const keysLinks = ['general_definitions', 'opening_your_account', 'management_account', 'chargeback', 'closure_account', 'payment_rules', 'limitation_liability', 'collusion_cheating_activity', 'closure_termination_you', 'closure_termination_us', 'suspension_by_us', 'access_use_of_services', 'alteration_website', 'third_party_software', 'it_failure', 'exclusion_liability', 'breach_terms', 'property_rights', 'viruses_offences', 'your_personal_information', 'use_of_cookies', 'complaints_notices', 'rights_obligations', 'outside_control', 'waiver', 'severability', 'entire_agreement', 'third_party_rights', 'law_jurisdiction', 'links', 'policy_site_use', 'legal_aspects', 'restrictions_rates', 'privacy_user_data', 'currency', 'bonus_program', 'deposits', 'entry_money', 'payments', 'player_obligations', 'aborted_games'];
	const halfKeyLinks = Math.ceil(keysLinks.length/2);
	const keysLinksBlock1 = keysLinks.slice(0, halfKeyLinks);
	const keysLinksBlock2 = keysLinks.slice(halfKeyLinks, keysLinks.length);
	
	function scrollTo(sectionId) {
		const el = document.getElementById(sectionId);
		el.scrollIntoView({ block: "center", behavior: "smooth" });
	}

	const printDiv = () => {
		const printContents = document.getElementById("printableContent").innerHTML;
		const iframe = iframeRef.current;
		const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
		iframeDocument.body.innerHTML = printContents;
		const styles = Array.from(document.styleSheets)
		  .map((styleSheet) => {
			try {
			  return Array.from(styleSheet.cssRules)
				.map((rule) => rule.cssText)
				.join("\n");
			} catch (error) {
			  return "";
			}
		  })
		  .join("\n");
		const styleElement = iframeDocument.createElement("style");
		styleElement.textContent = styles;
		iframeDocument.head.appendChild(styleElement);
		iframe.contentWindow.print();
	  };
	

  return (
    <BasicLayout pageTitle="terms and conditions">
      <div className="content-px content-py uw-content legal">
        <div className="terms-condition-block">
		<div className="layout-title">{t('Terms and Conditions')}</div>
		<div>
		<button className="pdf-button" onClick={printDiv}>
            <img src={Images?.pdfIcon} alt="pdf-icon"/>
          </button>
    </div>
		</div>
        <div className="legal-block">
          <div className="legal-links">
						<div>
							{keysLinksBlock1.map((link, i) => {
								return (<div className="legal-links-item" key={link}>
									{i + 1}. <button onClick={() => scrollTo(`link-${i + 1}`)}>{t(link)}</button>
								</div>)
							})}
						</div>
						<div>
							{keysLinksBlock2.map((link, i) => {
								return (<div className="legal-links-item" key={link}>
									{i + 1 + halfKeyLinks}. <button onClick={() => scrollTo(`link-${i + 1 + halfKeyLinks}`)}>{t(link)}</button>
								</div>)
							})}
							
						</div>
          </div>
          <div className="legal-divider"></div>
		  <div id="printableContent">
					{keysLinks.map((link, i) => {
						return (
							<div key={link+"-text"} id={`link-${i + 1}`}>
								<div className="legal-title">
									{t(link)}
								</div>
								<p dangerouslySetInnerHTML={{__html: t(link+"_text")}}></p>
							</div>
						)
					})}

		  </div>
        </div>
      </div>
	  <iframe
        title="printFrame"
        ref={iframeRef}
        style={{ display: "none" }}
      />
    </BasicLayout>
  );
};

export default TermsCondition;
