import axios from "axios";
import { apiVars } from "../../../../../api";
class BonusApi {
  bonusStatusList = (data) =>
    axios.post(
      `${apiVars.baseApiUrl}/Main/GetBonusStatusesEnum`,
      data
    );
  bonusApi = (data) =>
    axios.post(
      `${apiVars.baseApiUrl}/Main/ApiRequest`,
      data
    );
    promocodeApi = (data) =>
    axios.post(
      `${apiVars.baseApiUrl}/Main/ApiRequest`,
      data
    );
}

const api = new BonusApi();
export default api;
