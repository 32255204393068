import axios from "axios";
import { apiVars } from "../../../api";

class casinoGamesApi {
  getCasinoGames = (data) =>
		axios.post(`${apiVars.baseApiUrl}/Main/GetGames`, data);
}

const api = new casinoGamesApi();
export default api;
