import { Images } from "../../../../../Images";
import { useTranslation } from "react-i18next";
import { contactData } from "../../../lib/constants";

const ContactSocial = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="support-contact">
        <div className="support-faq-header">
          <p>{t("Contact details")}</p>
        </div>
        <div className="support-faq-divider"></div>
        <div className="support-faq-block">
          <p className="support-contact-title">OkyanusBahis</p>
						<div className="support-contact-group">
							<div className="support-contact-group-icon">
								<img width="40" height="40" loading="lazy" src={Images.blackEmailIcon} alt="email icon" />
								<div className="support-contact-group-title">{t("Email")}</div>
							</div>
							<a href={`mailto:${contactData["email"]}`} className="support-contact-group-text">
								{contactData["email"]}
							</a>
						</div>
        </div>
      </div>
    </div>
  );
};

export default ContactSocial;
