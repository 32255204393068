import BasicLayout from "../../BasicLayout";
import { NavLink, useParams } from "react-router-dom/cjs/react-router-dom";
import { promos } from "./data";
import { useTranslation } from "react-i18next";
import routes from "../../Route/URLs";
import { Result } from "antd";

export default function SinglePromotion() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { i18n } = useTranslation();
  const activeLang = i18n.language;
  const promotion = promos.find((x) => x.slug === id);
  return (
    <BasicLayout pageTitle="Promotions">
      <div className="singlePromo casino-page">
        {promotion && (
          <div className="uw-content content-px">
            <div className="d-flex">
              <NavLink
                exact
                activeClassName="active"
                to={routes.PROMOTIONS}
                className="singlePromo__back">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="76"
                  height="84"
                  viewBox="0 0 76 84"
                  fill="none">
                  <g filter="url(#filter0_d_254_8008)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M43.4374 48.6609C44.1969 49.434 44.1861 50.6629 43.413 51.4229C42.6399 52.1829 41.3899 52.1936 40.6035 51.4469L32.5868 43.5656C31.8044 42.7962 31.8044 41.549 32.5868 40.7796L40.6035 32.8983C41.3899 32.1516 42.6399 32.1623 43.413 32.9223C44.1861 33.6823 44.1969 34.9112 43.4374 35.6843L36.8377 42.1726L43.4374 48.6609Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_254_8008"
                      x="0"
                      y="0.345215"
                      width="76"
                      height="83.6548"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB">
                      <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="16" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.72 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_254_8008"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_254_8008"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
                {t("Back to promotions")}
              </NavLink>
            </div>
            <div className="singlePromo__block">
              <img
                className="singlePromo__img"
                src={activeLang === "tr" ? promotion.imgs[1] : promotion.imgs[0]}
                alt="promotions"
              />
              <div dangerouslySetInnerHTML={{ __html: t(promotion.content) }}></div>
            </div>
          </div>
        )}
        {!promotion && (
          <div className="content-px content-py uw-content">
            <Result
              title={t("Page not found")}
              subTitle={t("page_not_found_text")}
              className="errorPage"
              extra={
                <div className="d-flex justify-content-center">
                  <NavLink
                    exact
                    activeClassName="active"
                    to="/promotions"
                    className="theme-btn">
                    {t("Back to promotions")}
                  </NavLink>
                </div>
              }
            />
          </div>
        )}
      </div>
    </BasicLayout>
  );
}
