import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Images } from "../../../Images";
import { navEl } from "../lib/constants";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const NavBar = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
	const [activeLink, setActiveLink] = useState(null);
	const [filteredLinks, setFilteredLinks] = useState([]);
	const location = useLocation();
	const { pathname} = location;
	
	useEffect(() => {
		setActiveLink(navEl.find(x => x.route === pathname))
		setFilteredLinks(navEl.filter(x => x.route !== pathname))
	}, [pathname])
	
  return (
    <div className="">
      <div className="cabinet-nav">
        {navEl.map((el, i) => (
          <NavLink exact activeClassName="active" className="cabinet-nav-link" to={el.route} key={el.title}>
            <div className="cabinet-nav-link-icon">
              <img data-e2e={el.title}
                className=""
                width="30"
                height="30"
                loading="lazy"
                src={el.img}
                alt={el.title}
              />
            </div>
            {t(el.title)}
          </NavLink>
        ))}
      </div>
      <div className="cabinet-nav-m" onClick={() => setShow((prev) => !prev)}>
				{activeLink && <div className={`${show ? "active" : ""} cabinet-nav-m-selected cabinet-nav-link`}>
          <div className="cabinet-nav-link-icon">
            <img width="30" height="30" src={activeLink.img} alt="icon" />
          </div>
          {t(activeLink.title)}
          <img className="cabinet-nav-link-chevron" width="13" height="13" src={Images.chevronIcon} alt="chevron icon" />
        </div>}
        <div className={`${show ? "active" : ""} cabinet-nav-list`}>
          {filteredLinks?.map((el, i) => {
						return (
							<NavLink
							exact
							activeClassName="active"
								className="cabinet-nav-link"
								to={el.route}
								key={`${el.title}-m`}
							>
								<div className="cabinet-nav-link-icon">
									<img
										width="30"
										height="30"
										loading="lazy"
										src={el.img}
										alt={el.title}
									/>
								</div>
								{t(el.title)}
							</NavLink>
						);
          })}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
