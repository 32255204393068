import { StarFilled } from "@ant-design/icons";
import { Skeleton } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { Images } from "../../Images";
import { useDispatch, useSelector } from "react-redux";
import { playNowGameSuccess } from "../../modules/Games/actions";
import { currency_symbols } from "../Navbar/constantNavBar";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { Dialog } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import Alert from '@mui/material/Alert';
import BigNumber from "bignumber.js";

const CasinoCardGameModal = ({ result, gameName, showgameUrl, gamemodel, setgamemodel }) => {
  const [resize, setResize] = useState(true);
  const dispatch = useDispatch();
  const [userbalance, setuserbalance] = useState(0);

    const AvailableBalance = useSelector((state) =>
        state?.headerReducer?.getClientBalanceData?.data?.Balances?.reduce((total, balance) => {
            // Check if the balance's TypeId is 1, 2, or 12 and add it to the total
            if (balance?.TypeId === 1 || balance?.TypeId === 2 || balance?.TypeId === 12) {
                // Add balance.Balance to the total amount (as BigNumber)
                return total.plus(balance.Balance);
            }
            return total;
        }, new BigNumber(0))
    );

  const isClientBalance = useSelector(
    (state) => state?.headerReducer?.isClientBalance
  );
  const CurrencyId = getItemFromLocalStorage("CurrencyId");

  useEffect(() => {
    if (isClientBalance) {
      setuserbalance(AvailableBalance?.toString());
    }
     // eslint-disable-next-line
  }, [AvailableBalance?.toString(), isClientBalance]);

  const Rating = () => {
    return (
      <div className="playNowModal-rating">
        <div className="d-flex align-items-center">
          {Array.from(Array(result?.rating || 5).keys()).map((e, i) => {
            return (
              <>
                <StarFilled
                  key={i}
                  style={{ color: "var(--theme-color)", padding: "2px" }}
                />
              </>
            );
          })}
          <p className="playNowModal-rating-value">{result?.rating || "5.0"}</p>
        </div>
      </div>
    );
  };

  function setResizeHandler() {
    setResize((prev) => !prev);
  }

	function onClose() {
		dispatch(playNowGameSuccess([]));
		setgamemodel(false);
	}

  return (
    <Dialog
      open={gamemodel}
      className={`playNowModal ${resize ? "full-screen" : ""}`}
      onClose={onClose}
			fullScreen={resize}
    >
      <div className="playNowModal-grid">
				<div className="playNowModal-header">
					<div className="playNowModal-header-left">
						<p className="playNowModal-text"> {result?.name ? result?.name : gameName || "DEMO NAME"}</p>
						<Rating />
						<div className="playNowModal-balance">Balance: {currency_symbols[CurrencyId] || "€"} {userbalance || 0}</div>
					</div>
					<div className="playNowModal-actions">
                        <button
                            type="button"
                            className="playNowModal-resize">
                            <img
                                src={Images.resize}
                                width="17"
                                height="17"
                                loading="lazy"
                                onClick={setResizeHandler}
                                alt="resize icon"
                            />
                        </button>
                        <button
                            onClick={onClose}
                            type="button"
                            className="playNowModal-close">
                            <ClearIcon />
                        </button>
					</div>
				</div>

        <div className="playNowModal-body">
          <div className={`playNowModal-iframe ${showgameUrl?.ResponseCode !== 0 ? "overflow-auto" : ""}`}>
            {showgameUrl ? (
              showgameUrl?.ResponseCode === 0 ? (
                <iframe
                  src={showgameUrl?.ResponseObject}
                  width="100%"
                  title="Games"
                ></iframe>
              ) : (
                  <Alert severity="error">{showgameUrl?.Description}</Alert>
              )
            ) : (
              <div>
                <br />
                <Skeleton
                  title={<p className="playNowModal-text">loading...</p>}
                  size={"large"}
                  rows={10}
                  active
                  round={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <div className="casino-game-modal position-relative"> */}
      {/* <div className="text-center p-1 m-auto"> */}
      {/* {showgameUrl ? (
            showgameUrl?.ResponseCode === 0 ? (
              <iframe
                src={showgameUrl?.ResponseObject}
                className="casino-game-modal-iframe"
                width="100%"
                // height={resize ? "672px" : "788px"}
                title="Games"
              ></iframe>
            ) : (
              <Result
                status="500"
                title={
                  <span className="text-light">{showgameUrl?.Description}</span>
                }
                extra={
                  <Button
                    type="primary"
                    onClick={() => {
                      window.location.reload("/");
                    }}
                  >
                    Try again
                  </Button>
                }
              />
            )
          ) : (
            <Skeleton
              title={"loading..."}
              size={"large"}
              rows={10}
              active
              round={true}
            />
          )} */}
      {/* </div> */}
      {/* </div> */}
    </Dialog>
  );
};

export default CasinoCardGameModal;
