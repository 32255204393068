import { Menu } from "antd";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveActivity } from "../Components/ActivityTracking/activityTrackingService";
import { loginModal } from "../Components/Login/actions";
import NavbarTime from "../Components/Navbar/components/NavbarTime";
import { MENU_KEYS } from "../Route/navigation";
import { getItemFromLocalStorage } from "../utils/localStorage";
// import "./style.scss";
import SidebarLogo from "./SidebarLogo";

import ProfileBlock from "./ProfileBlock";
import SidebarMenuLinks from "./SidebarMenuLinks";

class SidebarMenuItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openKeys: [this.firstPath],
      selectedKey: this.path,
    };
  }
  rootSubmenuKeys = Object.values(MENU_KEYS);
  path = window.location.pathname;
  firstPath = this.path.split("/")[2];

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  onMenuItemSelected = async (e) => {
    if (e.key !== this.state.selectedKey) {
      this.setState({ selectedKey: e.key });
      this.props?.history?.push(`${e.key}`);
    }
    saveActivity({
      EventPage: "HomePage",
      EventType: "MenuClick",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: e?.key?.split("/")[2] || "",
    });
  };
  renderSideMenu = (routeMap) => {
    // const { themeMode } = this.props;
    const { selectedKey } = this.state;
    return routeMap?.map((menu, i) => {
      return (
        <Menu.Item
          key={menu.url}
          disabled={menu.disabled}
          className={` menu-item-style ${
            selectedKey === menu.url ? "active-menu-img" : ""
          }`}
        >
          <span className={`${selectedKey === menu.url ? "menu-img" : ""}`}>
            <img
              className={`${
                selectedKey === menu.url ? "active-sidebar-img" : ""
              }`}
              data-e2e={menu.name}
              src={menu.icon}
              alt={menu.icon}
              style={{ width: "17px", height: "17px" }}
              key={i}
            />
          </span>
          <span
            id="menu-heading"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              fontFamily: "Inter",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            {menu.name}
          </span>
        </Menu.Item>
      );
    });
  };

  render() {
    return (
      <>
        <div className="sidebar on-desktop sidebar-between">
          <div>
            <SidebarLogo />
            <div className="sidebar-container">
              <div className="sidebar-block">
                <ProfileBlock />
                <SidebarMenuLinks />
              </div>
            </div>
          </div>
          <div className="sidebar-container-time">
            <NavbarTime />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    themeMode: state?.themeReducer?.theme,
  };
};

export const SidebarMenuItemsWithRouter = connect(mapStateToProps, {
  loginModal,
})(withRouter(SidebarMenuItems));
