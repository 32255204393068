import { Form, Input, Select, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Images } from "../../../Images/index";
import AuthModalImg from "../AuthModalImg";
import { useTranslation } from "react-i18next";
import { registerModal, signup, loginModal } from "../../Login/actions";
import BasicLayout from "../../../BasicLayout";
import routes from "../../../Route/URLs";
import { useHistory } from "react-router-dom";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import dayjs from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const { Option } = Select;
const SignUpPage = (props) => {
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();
  const [errMsg, setErrMsg] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);
  const loggedIn = getItemFromLocalStorage("Token");
  const signupData = useSelector((state) => state?.loginReducer?.signupData);
  const [isLoading, setIsLoading] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [birthDate, setBirthDate] = useState(null);
  const [dobError, setDobError] = useState(null);

  function showTextBtn() {
    if (!isLoading || userCreated) {
      return t("Sign Up");
    }

    if (isLoading && !userCreated) {
      return "Loading...";
    }
  }

  function openLoginModal() {
    dispatch(registerModal(false));
    dispatch(loginModal(true));
  }

  const currencies = ["TRY", "EUR", "AUD", "USD", "CAD", "GBP"];

  function onFinishFailed(values) {
    if (!birthDate) {
      setDobError("empty");
      return false;
    }
  }

  async function onFinish(values) {
    if (!birthDate) {
      setDobError("empty");
      return;
    }

    if (values?.password !== values.confirmPassword) {
      return setErrMsg("The password confirmation does not match.");
    }
    if (!isAccepted) {
      return setErrMsg("Please accept Terms & Conditions!");
    }
    if (!dayjs(birthDate).isValid) {
      return setErrMsg("Please, enter a valid Birthdate");
    }

    setErrMsg("");
    const searchParams = getItemFromLocalStorage("searchParams");
    const AffiliatePlatformId = searchParams && searchParams.AffiliatePlatformId;
    const BonusCode = searchParams && searchParams.BonusCode;
    const clickid = searchParams && searchParams.clickid;
    const sourceid = searchParams && searchParams.sourceid;
    const AgentCode = searchParams && searchParams.AgentCode;

    const payload = {
      CurrencyId: values.currencyId,
      Email: values.email,
      Password: values.password,
      FirstName: values.FirstName,
      LastName: values.LastName,
      UserName: values.username,
      BirthDay: `${birthDate?.$D}`,
      BirthMonth: `${birthDate?.$M + 1}`,
      BirthYear: `${birthDate?.$y}`,
      AcceptTerms: isAccepted,
      TermsConditionsAccepted: isAccepted,
      confirmPassword: values.confirmPassword,
      TimeZone: 0,
      LanguageId: "en",
      ...(AffiliatePlatformId && { AffiliatePlatformId: AffiliatePlatformId }),
      ...(BonusCode && { BonusCode: BonusCode }),
      ...(clickid && { RefId: clickid }),
      ...(sourceid && { AffiliateId: sourceid }),
      ...(AgentCode && { AgentCode: AgentCode }),
    };

    if (!dobError) {
      dispatch(signup(payload));
      setIsLoading(true);
    }
  }

  useEffect(() => {
    if (loggedIn) {
      history.push(routes.CASHER_WALLETS);
      loginModal(false);
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  useEffect(() => {
    if (signupData?.data?.Email) {
      setIsLoading(false);
      setUserCreated(true);
      form.resetFields();
      setIsAccepted(false);
      setBirthDate(null);
    }

    if (signupData?.request?.response) {
      setIsLoading(false);
    }
  }, [signupData, form]);

  useEffect(() => {
    if (birthDate && dobError === "empty") {
      setDobError(false);
    }
  }, [birthDate, dobError]);

  return (
      <BasicLayout>
        <div className="uw-content content-px content-py">
          <div className="signUp authModal-row">
            <AuthModalImg />
            <div className="theme-modal-body authModal-body">
              <div className="theme-modal-header authModal-header">
                <p className="theme-modal-heading">{t("Sign Up")}</p>
              </div>
              <div className="authModal-reg">
                <Form
                    form={form}
                    autoComplete="off"
                    onFinishFailed={onFinishFailed}
                    onFinish={onFinish}>
                  <Form.Item
                      name="FirstName"
                      rules={[{ required: true, message: t("First name required") }]}>
                    <Input
                        placeholder={t("FirstName")}
                        data-e2e="first-name"
                        className="theme-input"
                        prefix={
                          <img
                              width="18"
                              height="18"
                              src={Images.userIconModal}
                              loading="lazy"
                              alt="icon"
                          />
                        }
                    />
                  </Form.Item>
                  <Form.Item
                      name="LastName"
                      rules={[{ required: true, message: t("Last name required") }]}>
                    <Input
                        placeholder={t("LastName")}
                        data-e2e="last-name"
                        className="theme-input"
                        prefix={
                          <img
                              width="18"
                              height="18"
                              loading="lazy"
                              src={Images.userIconModal}
                              alt="icon"
                          />
                        }
                    />
                  </Form.Item>
                  <Form.Item
                      name="username"
                      rules={[{ required: true, message: t("Username is required") }]}>
                    <Input
                        placeholder={t("UserName")}
                        data-e2e="user-name"
                        className="theme-input"
                        prefix={
                          <img
                              width="18"
                              height="18"
                              src={Images.userIconModal}
                              loading="lazy"
                              alt="icon"
                          />
                        }
                    />
                  </Form.Item>
                  <Form.Item
                      name="email"
                      rules={[{ required: true, message: t("Email-placeholder") }]}>
                    <Input
                        placeholder={t("Email")}
                        data-e2e="email"
                        className="theme-input"
                        prefix={
                          <img
                              width="18"
                              height="18"
                              loading="lazy"
                              src={Images.emailIcon}
                              alt="icon"
                          />
                        }
                    />
                  </Form.Item>
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          className={`dobDatePicker sign-up ${dobError ? "error" : ""}`}
                          onChange={(date, dateString) => setBirthDate(date)}
                          defaultValue={birthDate ? dayjs(birthDate) : null}
                          value={birthDate ? dayjs(birthDate) : null}
                          minDate={dayjs().subtract(99, "year")}
                          maxDate={dayjs().subtract(18, "year")}
                          views={["year", "month", "day"]}
                          format="YYYY-MM-DD"
                          onError={(newError) => {
                            setDobError(newError);
                          }}
                      />
                    </LocalizationProvider>
                    <p className="error-text mb-0">{dobError ? "Please enter a valid date" : ""}&nbsp;</p>
                  </div>
                  {/*<br />*/}
                  <Form.Item
                      name="currencyId"
                      rules={[{ required: true, message: t("Please select your Currency") }]}>
                    <Select
                        className="theme-input suffix-select"
                        placeholder={t("Currency")}
                        data-e2e="currency"
                        suffixIcon={
                          <img
                              width="18"
                              height="18"
                              loading="lazy"
                              src={Images.currency}
                              alt="icon"
                          />
                        }>
                      {currencies.map((x) => {
                        return (
                            <Option
                                key={x}
                                value={x}
                                data-e2e={x}>
                              {x}
                            </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                      name="password"
                      rules={[{ required: true, message: t("Please enter password") }]}>
                    <Input
                        data-e2e="password"
                        type="password"
                        placeholder={t("Password-placeholder")}
                        className="theme-input"
                        prefix={
                          <img
                              className="password-icon"
                              src={Images.passwordIcon}
                              alt=""
                          />
                        }
                    />
                  </Form.Item>
                  <Form.Item
                      name="confirmPassword"
                      rules={[{ required: true, message: t("Please enter password") }]}>
                    <Input
                        type="password"
                        data-e2e="confirm-password"
                        placeholder={t("Confirm-password-placeholder")}
                        className="theme-input"
                        prefix={
                          <img
                              className="password-icon"
                              src={Images.passwordIcon}
                              alt=""
                          />
                        }
                    />
                  </Form.Item>
                  <Checkbox
                      data-e2e="accept-t&c"
                      className={`authModal-checkbox ${themeMode === "light" ? "light-mode" : ""}`}
                      checked={isAccepted}
                      onChange={() => setIsAccepted((prev) => !prev)}>
                    <div
                        className="authModal-text"
                        dangerouslySetInnerHTML={{ __html: t("AcceptTerms") }}></div>
                  </Checkbox>
                  {errMsg !== "" && (
                      <div
                          className="error-message text-danger pt-2"
                          data-e2e={errMsg}>
                        {errMsg}
                      </div>
                  )}
                  <button
                      className={`authModal-btn theme-btn full-width sqr-btn no-mobile`}
                      data-e2e={t("Sign Up")}
                      disabled={isLoading && !userCreated}>
                    {showTextBtn()}
                  </button>
                  <br />
                  <button
                      onClick={openLoginModal}
                      className="authModal-text authModal-already"
                      type="button">
                  <span>
                    {t("Have an account?")} <span className="link-style">{t("Sign In")}</span>
                  </span>
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </BasicLayout>
  );
};

export default SignUpPage;
