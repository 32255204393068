import { NavLink } from "react-router-dom";
import { saveActivity } from "../Components/ActivityTracking/activityTrackingService";
import { getItemFromLocalStorage } from "../utils/localStorage";
import { useTranslation } from "react-i18next";
import { sidebarRoutes } from "./Constant";
import routes from "../Route/URLs";
import { Images } from "../Images";
import LangSwitch from "./LangSwitch";

export default function SidebarMenuLinks() {
  const { t } = useTranslation();

  async function onMenuItemSelected(e) {
    saveActivity({
      EventPage: "HomePage",
      EventType: "MenuClick",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: e?.key?.split("/")[2] || "",
    });
  }

  const sidebarRoutesElements = sidebarRoutes.map((link, index) => {
    return (
      <div
        key={link.key}
        onClick={(e) => onMenuItemSelected(e)}>
        {link.name === "Poker" ? (
          <a
            href={link.url}
            className="sidebar-link block-pd">
            <div className="sidebar-link-icon">
              <img
                src={link.icon}
                alt="icon"
              />
            </div>
            <span>{t(link.name)}</span>
          </a>
        ) : (
          <NavLink
            exact
            activeClassName="active"
            to={link.url}
            className="sidebar-link block-pd">
            <div className="sidebar-link-icon">
              <img
                src={link.icon}
                alt="icon"
              />
            </div>
            <span>{t(link.name)}</span>
          </NavLink>
        )}

        {index < sidebarRoutes.length - 1 && <div className="divider"></div>}
      </div>
    );
  });

  return (
    <div>
      <div>
        <NavLink
          exact
          activeClassName="active"
          to={routes.PROMOTIONS}
          className="sidebar-link block-fill sidebar-link-promotions">
          <div className="sidebar-link-icon">
            <img
              src={Images.promoLink}
              alt="icon"
            />
          </div>
          <span>{t("promotions")}</span>
        </NavLink>
      </div>
      <div className="sidebar-block block-fill no-pd mb-10">{sidebarRoutesElements}</div>
      <div className="sidebar-block block-bottom block-fill no-pd">
        <NavLink
          to="/support"
          className="sidebar-link block-pd">
          <div className="sidebar-link-icon support-icon">
            <img
              src={Images.supportLink}
              alt=""
            />
          </div>
          <span>{t("Support")}</span>
        </NavLink>
        <div className="divider"></div>
        <LangSwitch isMobile={true} />
      </div>
    </div>
  );
}
