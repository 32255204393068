import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Images } from "../../Images/index";
import routes from "../../Route/URLs";
import { loginModal, logout, registerModal } from "../Login/actions";
import Login from "../Models/Login/Login";
import NewTicket from "../Models/Mail/NewTicket";
import NavbarSearch from "./components/NavbarSearch";
// import SwitchTheme from "./components/SwitchTheme";
import NavbarProfileMenu from "./components/NavbarProfileMenu";
import NavbarProfileTablet from "./components/NavbarProfileTablet";
import NavbarMainTablet from "./components/NavbarMainTablet";
import { getItemFromLocalStorage } from "../../utils/localStorage";
import { getClientBalanceSuccess } from "./actions";
import axios from "axios";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { apiVars } from "../../api";
import ProductLinks from "./ProductLinks";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { checkPathname } from "../../utils/classNameManager";
const Navbar = (props) => {
  const history = useHistory();
  const { loginModal, logout, getClientBalanceSuccess } = props;
  const loggedIn = getItemFromLocalStorage("Token");
  const [userlogin, setuserlogin] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const [mailmodel, setmailmodel] = useState(false);
  const { t } = useTranslation();
  // const [initialized, setInitialized] = useState(false);

  const themeMode = useSelector((state) => state?.themeReducer?.theme);

  const closeModal = useSelector((state) => state?.loginReducer?.closeModal);

  useEffect(() => {
    if (loggedIn) {
      setuserlogin(true);
      loginModal(false);
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  useEffect(() => {
    if (closeModal === true) {
      loginModal(false);
    }
  }, [closeModal, loginModal]);

  const showRegisterModal = () => {
    // setRegisterModal(true);
    dispatch(registerModal(true))

  };

  const showLoginModal = () => {
    loginModal(true);
  };

  const HandleLogout = () => {
    setuserlogin(false);
    logout(false);
    history.push(routes.HOME_PAGE);
    window.location.reload(routes.HOME_PAGE);
  };

  useEffect(() => {
    setIntervalCalls();
    let interval1 = setInterval(() => {
      setIntervalCalls();
    }, 2000);
    return () => {
      clearInterval(interval1);
    };
    // eslint-disable-next-line
  }, []);

  const setIntervalCalls = async () => {
    const PartnerId = getItemFromLocalStorage("PartnerId");
    const CurrencyId = getItemFromLocalStorage("CurrencyId");
    const ClientId = getItemFromLocalStorage("Id");
    const Token = getItemFromLocalStorage("Token");
    let payload = {
      Token: Token,
      ClientId: ClientId,
      PartnerId: PartnerId,
      RequestData: JSON.stringify({ CurrencyId: CurrencyId }),
    };
    if (Token) {
      axios
        .post(
          `${apiVars.baseApiUrl}/Main/GetClientBalance`,
          payload
        )
        .then((res) => getClientBalanceSuccess(res))
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (checkPathname(location.pathname)) {
      document.documentElement.classList.add("sport-page");
    } else {
      document.documentElement.classList.remove("sport-page");
    }
  }, [location])

  return (
    <>
      <div className="main-navbar content-px">
        <NavbarMainTablet />
        <div className="navbar-search-d on-desktop">
          <NavbarSearch />
        </div>
        <div className="main-navbar-right">
          <ProductLinks />
          <div className="divider"></div>
          {!userlogin && (
            <div className="main-navbar-btns d-flex justify-content-center align-items-center">
              <button onClick={showLoginModal} className="landHeader__actions-login header-btn">
                <span data-e2e={t("Sign In")}>{t("Sign In")}</span>
              </button>
              <NavLink exact activeClassName="active" to={routes.SIGN_UP}
                className="theme-btn fill-btn header-btn"
              >
                <span data-e2e={t("Sign Up")}>{t("Sign Up")}</span>
              </NavLink>
            </div>
          )}

          {/*<Link to="/support" className="navbar-help on-desktop">*/}
          {/*  <img*/}
          {/*    width="42"*/}
          {/*    height="42"*/}
          {/*    src={Images.supportIcon}*/}
          {/*    alt="support icon"*/}
          {/*  />*/}
          {/*</Link>*/}
          {/*<div className="divider"></div>*/}
          {/*<NavbarLang />*/}
          {/*{userlogin && <div className="divider"></div>}*/}
          {userlogin && (
            <>
              <button
                onClick={() => history.push(routes.CASHER_WALLETS)}
                className="on-desktop navbar-depositBtn theme-btn fill-btn"
              >
                <span data-e2e="deposit-button">{t("Deposit")}</span>
              </button>
              <button
                className="navbar-email"
                onClick={() => setmailmodel(true)}
              >
                <img
                  className={themeMode === "light" ? "black-image" : ""}
                  width="25"
                  height="20"
                  src={Images.emailModelIcon}
                  alt="email model icon"
                  data-e2e="email-icon"
                />
              </button>
              <NavbarProfileMenu logoutHandler={HandleLogout} />
            </>
          )}
          {userlogin && <NavbarProfileTablet logoutHandler={HandleLogout} />}
          {/* <SwitchTheme /> */}
        </div>
      </div>
      <Login setRegisterModal={showRegisterModal} />
      {/* <Register
        // registerModalStatus={registerModal}
        // setregisterModal={setRegisterModal}
      /> */}
      <NewTicket mailmodel={mailmodel} setmailmodel={setmailmodel} />
    </>
  );
};

export default connect(null, {
  getClientBalanceSuccess,
  loginModal,
  logout,
})(Navbar);
