import React, { useEffect } from "react";
import NavbarLanding from "../Components/Navbar/NavbarLanding";
import BannerLanding from "../Components/Landing/BannerLanding";
import ProductsLanding from "../Components/Landing/ProductsLanding";
import Footer from "../Components/Footer/Footer";
// import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

// const client = new ApolloClient({
// 	uri: window.location.hostname === "localhost" ? "http://localhost:8080/graphql" : "https://dev-cms.oceanbetuk.io/graphql",
// 	cache: new InMemoryCache()
// });

export default function LandingPage() {
  useEffect(() => {
    document.documentElement.classList.remove("sport-page");
  }, []);
  return (
    // <ApolloProvider client={client}>
    <div className="landing">
      <NavbarLanding />
      <BannerLanding />
      <ProductsLanding />
      <Footer />
    </div>
    // </ApolloProvider>
  );
}
