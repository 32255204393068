import axios from "axios";
import { apiVars } from "../../../../api";
class inplayApi {
  getInplayURL = (data) =>
    axios.post(
      `${apiVars.baseApiUrl}/Main/GetProductUrl`,
      data
    );
}

const api = new inplayApi();
export default api;
