import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { Images } from "../../Images";
import { apiVars } from "../../api";
// import { useQuery } from "@apollo/client";
// import { useTranslation } from "react-i18next";
// import { bonusesGql } from "../../gql";
// import { getArrayLocale } from "../../helper";

const products = [
  {
    bonusText: "YENİ OYUNCULARA ÖZEL <span>GÜNLÜK HEDİYELER</span> ÖDÜLÜN İÇİN GÜNLÜK GİRİŞ YAP",
    buttonStyle: "poker",
    buttonText: "OkyanusBahis Poker’e Katil",
    buttonLink: apiVars.pokerUrl,
    image: { url: Images.pokerSmallProduct },
  },
  {
    bonusText: "Casino Hoşgeldin Bonusu <span>12.000 TL'ye kadar</span> %120 devasal bonus + 25 Free Spins",
    buttonStyle: "casino",
    buttonText: "OkyanusBahis'e Katılın",
    buttonLink: "/sign-up",
    image: { url: Images.casinoSmallProduct },
  },
  {
    bonusText: "Spor Hoşgeldin Bonusu <span>6.000 TL'ye kadar</span> %100 yatırım bonusu",
    buttonStyle: "sport",
    buttonText: "OkyanusBahis'e Katılın",
    buttonLink: "/sports",
    image: { url: Images.sportsSmallProduct },
  }
];

const ProductItem = ({ bonus }) => {
  const { bonusText, buttonStyle, buttonText, buttonLink, image } = bonus;
  return (
    <div className={`landProducts__item ${buttonStyle}`}>
      <img
        src={image?.url}
        alt=""
      />
      <div className="landProducts__content">
        <div
          className="landProducts__item-text"
          dangerouslySetInnerHTML={{ __html: bonusText }}></div>
        {buttonStyle === "poker" ? (
          // eslint-disable-next-line
          <a
            href={buttonLink || "#/"}
            className={`theme-btn fill-btn ${buttonStyle}-btn-style`}>
            <span>{buttonText}</span>
          </a>
        ) : (
          <NavLink
            // eslint-disable-next-line
            to={buttonLink || "#/"}
            className={`theme-btn fill-btn ${buttonStyle}-btn-style`}>
            <span>{buttonText}</span>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default function ProductsLanding() {
  // const { i18n } = useTranslation();
  // const activeLanguage = i18n.language;

  // const { loading, error, data } = useQuery(bonusesGql);
  // console.log(error, loading);

  // const [products, setProducts] = useState([]);
  // useEffect(() => {
  //   setProducts(getArrayLocale(activeLanguage, data?.bonuses));
  // }, [activeLanguage, data]);

  return (
    <div className="landProducts">
      {products?.map((x) => {
        return (
          <ProductItem
            bonus={x}
            key={x.id}
          />
        );
      })}
    </div>
  );
}
