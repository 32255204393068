import { Input } from "antd";
import { Spin, Switch } from "antd";
import browser from "browser-detect";
import { useTranslation } from "react-i18next";

import { useCallback, useEffect, useRef, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { saveActivity } from "../../../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../../Components/Common/getIp";
import { Images } from "../../../Images";
import { replaceGamesKeysNames } from "../../../utils/helper";
import { getItemFromLocalStorage } from "../../../utils/localStorage";
import { casino } from "../actions";
import GameCards from "./GameCards";

import SwiperCore, { FreeMode, Mousewheel, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import { apiVars } from "../../../api";

SwiperCore.use([Mousewheel, Scrollbar, FreeMode]);

let providerBaseUrl = `https://${apiVars.currentHost}.casino/assets/images/providers/`;

const browserInfo = browser();

const GameNavbar = ({ casino, gamesData, loading, searchText, setSearchText, scrollloading }) => {
  const listInnerRef = useRef();
  const [pageSize, setPageSize] = useState(24);
  const [favouriteStatus, setFavouriteStatus] = useState(false);
  const [showGameProviders, setShowGameProviders] = useState(true);
  const [highlightGame, setHighLightGame] = useState(0);
  const [providerId, setProviderId] = useState(null);
  const [allGames, setallGames] = useState([]);

  const themeMode = useSelector((state) => state?.themeReducer?.theme);

  // const [open, setOpen] = useState(false);
  // const contentWrapper1 = useRef(null);
  const [show, setShow] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize().innerWidth);

  const [gamesdata, setgamesdata] = useState([]);
  const { star, cherry, stars, dice, heart, ActiveGames, Games41 } = Images;
  const [displayCategoryName, setDisplayCategoryName] = useState("AllGames");
  const [displayCategoryImg, setDisplayCategoryImg] = useState(
    themeMode === "dark" ? Games41 : ActiveGames
  );

  console.log(displayCategoryImg, show, windowSize, highlightGame);
  const { t } = useTranslation();

  const handleFavoruiteStatus = () => {
    setFavouriteStatus((prev) => !prev);
  };
  useEffect(() => {
    if (gamesData?.Games) {
      setallGames(gamesData?.Games);
    }
  }, [gamesData]);

  let gamesCategory = [];
  const categoryImages = {
    AllGames: themeMode === "dark" ? Games41 : Images.Games41Light,
    TopGames: themeMode === "dark" ? star : Images.starLight,
    ClassicSlots: themeMode === "dark" ? cherry : Images.cherryLight,
    Specials: themeMode === "dark" ? stars : Images.starLight,
    TableGames: themeMode === "dark" ? dice : Images.diceLight,
    MyFavorites: themeMode === "dark" ? heart : Images.heartLight,
  };
  useEffect(() => {
    axios.get(`https://${apiVars.currentHost}.casino/assets/json/menu.json?=554`)
      .then(res => {
        const menudata = res?.data?.MenuList.find(val => val.Type === "CasinoMenu");
        if (menudata) {
          const updatedCategory = menudata.Items.map((item, i) => ({
            categoryName: item.Title === "ClassicSlots" ? "Slots" : item.Title,
            categoryImage: categoryImages[item.Title],
            category_id: item.Href || null,
            categoryNo: i,
          }));
          gamesCategory.push(...updatedCategory)
        }
      })
      .catch(error => {
        // Handle error
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);
  let allProviders = gamesData?.Providers;

  const [categoryId, setCategoryId] = useState(null);

  const Token = getItemFromLocalStorage("Token");
  const LanguageId = getItemFromLocalStorage("i18nextLng");
  const ClientId = getItemFromLocalStorage("Id");

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPageSize((prev) => prev + 24);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  const getGamesApi = async () => {
    let payload = {
      PageIndex: 0,
      PageSize: pageSize,
      WithWidget: false,
      CategoryId: categoryId,
      IsForMobile: false,
      Name: searchText,
      LanguageId: LanguageId,
      ProviderIds: providerId ? [providerId] : null,
    };
    if (Token) {
      payload = {
        ...payload,
        ClientId: ClientId || "",
        Token: Token || "",
      };
    }
    await casino(payload);
  };

  const handleSetProviders = (id) => {
    setProviderId(id);
  };

  const activityTrack = async () => {
    let deviceIp = await getDeviceIP();
    saveActivity({
      EventPage: "Casino",
      EventType: "Search",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: searchText || "",
      attribute3: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
  };
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize().innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function setShowGameProvidersHandler() {
    if (windowSize >= 991) {
      setShowGameProviders((prev) => !prev);
    }
  }
  if (windowSize < 991 && !showGameProviders) {
    setShowGameProviders(true);
  }

  useEffect(() => {
    getGamesApi();
    activityTrack();
    // eslint-disable-next-line
  }, [searchText, categoryId, favouriteStatus, pageSize, providerId]);

  useEffect(() => {
    setgamesdata(gamesCategory);
    //eslint-disable-next-line
  }, [themeMode]);

  return (
    <div className="casino-content">
      <div className="content-px uw-content">
        <div className="casino-nav">
          <div className="casino-nav-menu">
            {gamesdata?.map(
              ({ categoryImage, categoryName, category_id, categoryNo }, i) => (
                <button
                  className={`casino-category ${displayCategoryName === categoryName ? "active" : ""
                    }`}
                  key={categoryName}
                  onClick={() => {
                    if (!loading) {
                      setHighLightGame(categoryNo);
                      setCategoryId(category_id);
                      setDisplayCategoryImg(categoryImage);
                      setDisplayCategoryName(categoryName);
                      setShow((prev) => !prev);
                      setPageSize(24);
                    }
                  }}
                >
                  <img src={categoryImage} alt={categoryName} />
                  <p>{t(categoryName)}</p>
                </button>
              )
            )}
          </div>
          <div className="casino-nav-search">
            <Input
              placeholder={t("Search_game")}
              value={searchText}
              className="theme-input"
              onChange={(e) => setSearchText(e.target.value)}
              prefix={
                <img
                  width="18"
                  height="18"
                  src={Images.searchIcon}
                  alt="icon"
                  loading="lazy"
                />
              }
            />
          </div>
        </div>
        <div className="casino-providers-top">
          <p className="casino-providers-title">{t("Game_Providers")}</p>
          <div className="casino-divider casino-divider-center"></div>
          {windowSize >= 991 && (
            <div className="casino-providers-switch">
              <p className="casino-providers-show">{t("Show_Providers")}</p>
              <Switch
                defaultChecked
                className="gamenavbar"
                onChange={setShowGameProvidersHandler}
              />
            </div>
          )}
        </div>

        {showGameProviders && (
          <div className="casino-providers">
            <Swiper
              modules={[Mousewheel, FreeMode, Scrollbar]}
              slidesPerView={"auto"}
              freeMode={true}
              spaceBetween={10}
              scrollbar={{ draggable: true }}
              mousewheel={{ releaseOnEdges: true, forceToAxis: true }}
            >
              {allProviders?.length > 0 &&
                allProviders?.map(({ Id, Name }, i) => {
                  return (
                    <SwiperSlide key={Name}>
                      <button
                        className={`casino-providers-btn ${Id === providerId ? "seleceted" : ""
                          }`}
                        onClick={() => {
                          if (!loading) {
                            handleSetProviders(Id);
                            setPageSize(24);
                          }
                        }}
                      >
                        <img
                          src={`${providerBaseUrl}${Id}.png`}
                          alt={Name || "providers"}
                        />
                      </button>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        )}

        <div className="casino-divider casino-divider-center"></div>
        <div className="casino-games">
          {!loading && allGames && allGames?.length > 0 && (
            <div
              className="casino-games-grid"
              ref={listInnerRef}
              id="infinite-list"
              style={{ overflow: "auto" }}
            >
              {allGames?.map((game, i) => {
                let result = replaceGamesKeysNames(game);
                return (
                  <div key={i} ref={lastBookElementRef}>
                    <GameCards
                      data={result}
                      handleFavoruiteStatus={handleFavoruiteStatus}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {loading && (
            <div className="game-provider-loader mt-5 d-flex justify-content-center w-100">
              <Spin tip={t("Loading")} data-e2e="games-present" />
            </div>
          )}
          {scrollloading && !loading && (
            <div className="game-provider-loader mt-5 d-flex justify-content-center w-100">
              <Spin tip={t("Loading")} data-e2e="games-present" />
            </div>
          )}
          {!loading && allGames && allGames?.length < 1 && (
            <p className="no-results t-center">{t("No games found")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    gamesData: state?.casinoReducer?.casinoGames,
    loading: state?.casinoReducer?.loading,
    scrollloading: state?.casinoReducer?.scrollloading,
  };
};

export default connect(mapStateToProps, {
  casino,
})(GameNavbar);

function getWindowSize() {
  const { innerWidth } = window;
  return { innerWidth };
}
