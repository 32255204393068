import { Input, Select } from "antd";
import browser from "browser-detect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { saveActivity } from "../../../../../Components/ActivityTracking/activityTrackingService";
import { getDeviceIP } from "../../../../../Components/Common/getIp";
import { getItemFromLocalStorage } from "../../../../../utils/localStorage";
import dayjs from "dayjs";

import { Option } from "antd/lib/mentions";
import {
  getAllCities,
  getAllCountries
} from "../../../../../Components/Login/actions";
import {
  sendVerificationCode,
  updateProfileInfo
} from "../../Services/actions";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const browserInfo = browser();

const SettingsMain = () => {
  const dispatch = useDispatch();
  const LanguageId = getItemFromLocalStorage("i18nextLng");
  const { t } = useTranslation();

  let userData = getItemFromLocalStorage("userData");
  let allCountriesData = useSelector(
    (state) => state?.loginReducer?.allCountries?.data?.ResponseObject
  );

  const [dobError, setDobError] = useState(null);
  const [profileData, setProfileData] = useState({
    mobileNo: userData?.MobileNumber || "",
    email: userData?.Email || "",
    fName: userData?.FirstName || "",
    lName: userData?.LastName || "",
    BirthDate: userData?.BirthDate || "",
    address: userData?.Address || "",
    currency: userData?.CurrencyId || "",
    documentNo: userData?.DocumentNumber || "",
    documentIssuedBy: userData?.DocumentIssuedBy || "",
    ZipCode: userData?.ZipCode || "",
    CountryId: userData?.CountryId || "",
    city: userData?.City || "",
  });

  const handleChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };
// eslint-disable-next-line
  const handleSendVerification = () => {
    const payload = {
      Loader: true,
      Controller: "Client",
      Token: userData?.Token,
      PartnerId: userData?.PartnerId,
      ClientId: userData?.Id,
      Method: "SendVerificationCodeToMobileNumber",
      RequestData: JSON.stringify({
        MobileNumber: profileData?.mobileNo,
      }),
      TimeZone: 0,
    };
    dispatch(sendVerificationCode(payload));
  };
	// eslint-disable-next-line
	const handleSendEmailVerification = () => {
    const payload = {
      Loader: true,
      Controller: "Client",
      Token: userData?.Token,
      PartnerId: userData?.PartnerId,
      ClientId: userData?.Id,
      Method: "SendVerificationCodeToEmail",
      RequestData: JSON.stringify({
        email: profileData?.email,
      }),
      TimeZone: 0,
    };
    dispatch(sendVerificationCode(payload));
  };

  const handleUpdate = async () => {
    let payload = {
      Loader: true,
      PartnerId: userData?.PartnerId,
      TimeZone: userData?.TimeZone,
      LanguageId: LanguageId,
      ProductId: null,
      Method: "ChangeClientDetails",
      Controller: "Client",
      CategoryId: null,
      PageIndex: 0,
      PageSize: 100,
      ProviderIds: [],
      Index: null,
      ActivationKey: null,
      MobileNumber: profileData?.mobileNo,
      Code: null,
      RequestData: JSON.stringify({
        ClientId: userData?.Id,
        Email: profileData?.email,
        IsEmailVerified: userData?.IsEmailVerified,
        CurrencyId: profileData?.currency,
        BirthDate: profileData?.BirthDate?.$d,
        FirstName: profileData?.fName,
        LastName: profileData?.lName,
        DocumentNumber: profileData?.documentNo,
        DocumentIssuedBy: profileData?.documentIssuedBy,
        Info: null,
        Address: profileData?.address,
        MobileNumber: profileData?.mobileNo,
        IsMobileNumberVerified: userData?.IsMobileNumberVerified,
        LanguageId: LanguageId,
        IsDocumentVerified: userData?.IsDocumentVerified,
        ZipCode: profileData?.ZipCode,
        EmailOrMobile: profileData?.email,
        Gender: userData?.Gender,
        CountryId: profileData?.CountryId,
        RegionId: userData?.RegionId,
        Citizenship: userData?.Citizenship,
        City: profileData?.city
      }),
      ClientId: userData?.Id,
      Token: userData?.Token || localStorage.getItem("Token"),
    };
    if (dobError || !profileData?.BirthDate) return;
    dispatch(updateProfileInfo(payload));
    let deviceIp = await getDeviceIP();
    saveActivity({
      EventPage: "Cashier",
      EventType: "Profile_SaveChanges",
      attribute1: getItemFromLocalStorage("UserName") || "",
      attribute2: "",
      attribute3: "",
      attribute4: navigator.language,
      device_type: browserInfo.mobile ? "Mobile" : "Desktop",
      browser_type: browserInfo.name,
      browser_version: browserInfo.version,
      language: navigator.language,
      ip_address: deviceIp,
    });
  };

  let payload = { LanguageId: "en", PartnerId: 74, TimeZone: 0 };

  const onBirthdayChange = (value) => {
    setProfileData((prev) => {
      return {
        ...prev,
        BirthDate: value,
      };
    });
  };

  const handleCities = (value) => {
    setProfileData({...profileData, CountryId: value})
    dispatch(
      getAllCities({
        ...payload,
        ParentId: value,
        TypeId: 3,
      })
    );
  };
// const handleChangeCity = (value) => {
//   setProfileData({...profileData, CityId: value})
// }

  useEffect(() => {
    dispatch(getAllCountries({ ...payload, TypeId: 5 }));
    dispatch(
      getAllCities({
        ...payload,
        ParentId: userData?.CountryId,
        TypeId: 3,
      })
    ); // eslint-disable-next-line
  }, []);

  return (
    <div className="profile-settings profile-pd">
      <p className="cabinetForm-title">{t("PersonalDetails")}</p>
      <div className="profile-settings-fields">
        <div className="inputGroup">
          <p className="inputGroup-label">{t("FirstName")}</p>
          <Input
            className="theme-input no-border"
            type="text"
            placeholder={t("Firstname-placeholder")}
            data-e2e="first-name"
            name="fName"
            value={profileData.fName}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("LastName")}</p>
          <Input
            className="theme-input no-border"
            type="text"
            placeholder={t("Lastname-placeholder")}
            data-e2e="last-name"
            name="lName"
            value={profileData.lName}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">
            {t("Date-of-birth")} {dobError && <span className="error-text">*Please, enter a valid Birthdate</span>}
            {!profileData?.BirthDate && <span className="error-text">*Birthdate value is required</span>}
          </p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                className="dobDatePicker"
                onChange={(date, dateString) => onBirthdayChange(date)}
                value={profileData?.BirthDate && dayjs(profileData?.BirthDate)}
                minDate={dayjs().subtract(99, "year")}
                maxDate={dayjs().subtract(18, "year")}
                views={["year", "month", "day"]}
                format="YYYY-MM-DD"
                onError={(newError) => setDobError(newError)}
            />
          </LocalizationProvider>
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label" data-e2e={t("Currency")}>
            {t("Currency")}
          </p>
          <Input
            className="theme-input no-border"
            placeholder="Eur"
            value={profileData?.currency}
            name={t("Currency")}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("Email")}</p>
          <div className="cabinet-blockBtn">
            <Input
                className="theme-input no-border"
                data-e2e="email"
                type="email"
                name="email"
                placeholder={t("Email-placeholder")}
                value={profileData.email}
                onChange={(e) => handleChange(e)}
            />
            {/* <button data-e2e={t("Verify")} onClick={handleSendEmailVerification} className="cabinet-btn">
          </div>
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("Mobile-number")}</p>
          <div className="cabinet-blockBtn">
            <Input
              className="theme-input no-border"
              type="text"
              placeholder={t("Mobile-number-placeholder")}
              value={profileData?.mobileNo}
              name="mobileNo"
              onChange={(e) => handleChange(e)}
              data-e2e="mobile-number"
            />
            {/* <button
              className="cabinet-btn"
              onClick={handleSendVerification}
              data-e2e={t("Send")}
            >
              {t("Send")}
            </button> */}
          </div>
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("Country")}</p>
          <Select
            className="theme-input suffix-select no-border"
            showSearch
            data-e2e="country"
            placeholder={t("Country-option")}
            optionFilterProp="children"
            onChange={handleCities}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            value={profileData?.CountryId}
          >
            {allCountriesData?.map(({ Id, Name }) => (
              <Option value={Id} key={Id} data-e2e={Name}>
                {Name}
              </Option>
            ))}
          </Select>
        </div>
        {/*<div className="inputGroup">*/}
        {/*  <p className="inputGroup-label">{t("City-option")}</p>*/}
        {/*  <Select*/}
        {/*    className="theme-input suffix-select"*/}
        {/*    showSearch*/}
        {/*    data-e2e="country"*/}
        {/*    placeholder="Cities"*/}
        {/*    optionFilterProp="children"*/}
        {/*    onChange={handleChangeCity}*/}
        {/*    filterOption={(input, option) =>*/}
        {/*      option.children.toLowerCase().includes(input.toLowerCase())*/}
        {/*    }*/}
        {/*    value={profileData?.CityId}*/}
        {/*  >*/}
        {/*    {allCitiesData?.map(({ Id, Name }) => (*/}
        {/*      <Option value={Id} key={Id} data-e2e={Name}>*/}
        {/*        {Name}*/}
        {/*      </Option>*/}
        {/*    ))}*/}
        {/*  </Select>*/}
        {/*</div>*/}
        <div className="inputGroup">
          <p className="inputGroup-label">{t("City")}</p>
          <Input
              className="theme-input no-border"
              placeholder={t("City")}
              data-e2e="country"
              type="text"
              name="city"
              value={profileData.city}
              onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("Address")}</p>
          <Input
            className="theme-input no-border"
            placeholder={t("Address-placeholder")}
            data-e2e="address"
            type="text"
            name="address"
            value={profileData.address}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="inputGroup">
          <p className="inputGroup-label">{t("zipcode")}</p>
          <Input
            className="theme-input no-border"
            placeholder="ZipCode"
            data-e2e="ZipCode"
            type="text"
            name="ZipCode"
            value={profileData.ZipCode}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="profile-settings-btns">
        <button
          className="cabinet-btn"
          onClick={() => handleUpdate()}
          data-e2e={t("Save-Changes")}
        >
          {t("Save-Changes")}
        </button>
        <button className="cabinet-btn outlined" data-e2e={t("Cancel-changes")}>
          <span>{t("Cancel-changes")}</span>
        </button>
      </div>
    </div>
  );
};

export default SettingsMain;
