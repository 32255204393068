import PaymentCard from "./PaymentCard";
import { useContext, useState, useEffect } from "react";
import CasherNav from "../../context/context";
import { GetClientBalanceForWithdrawSuccess, selectedCard } from "../../Services/actions";
import { connect } from "react-redux";
import { handleClientBalance } from "../../lib/constants";

const WithdrawPayMethods = (props) => {
  const [cardSelected, setCardSelected] = useState(0);
  const [methods, setMethods] = useState([]);
  const { partnerPayment, selectedCurrency } = useContext(CasherNav);

  const { selectedCard, GetClientBalanceForWithdrawSuccess } = props;

  useEffect(() => {
    const options = [];
    if (partnerPayment.length > 0) {
      // eslint-disable-next-line
      partnerPayment.map((p) => {
        if (p.CurrencyId === selectedCurrency && p.Type === 1) {
          options.push(p);
        }
      });
    }

    setMethods(options);
    // eslint-disable-next-line
  }, [selectedCurrency, partnerPayment]);

  useEffect(() => {
    if (methods.length > 0) {
      selectedCard(methods[cardSelected]);
      handleClientBalance().then((res) => {
        if (res.status === 200) {
          GetClientBalanceForWithdrawSuccess(res);
        }
      });
    }
    // eslint-disable-next-line
  }, [cardSelected, methods]);

  return (
    <div className="deposit-scroll">
      <div className="deposit-grid">
        {methods.map((card, i) => (
          <PaymentCard
            key={i}
            cardSelected={cardSelected}
            setCardSelected={setCardSelected}
            i={i}
            card={card}
            CommissionPercent={card.CommissionPercent}
          />
        ))}
      </div>
    </div>
  );
};

export default connect(null, {
  selectedCard,
  GetClientBalanceForWithdrawSuccess,
})(WithdrawPayMethods);
