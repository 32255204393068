import { Images } from "../../Images";

export default function FooterMenuTitle({ title }) {
  function toggleTab(e) {
    const tab = e.currentTarget.nextElementSibling;
    if (tab.classList.contains("active")) {
      tab.classList.remove("active");
      return;
    } else {
      tab.classList.add("active");
    }
  }

  return (
    <button
      onClick={(e) => toggleTab(e)}
      className="footer-menu-header">
      <p className="footer-menu-title">{title}</p>
      <img
        width="10"
        height="7"
        loading="lazy"
        src={Images.downArrowGray}
        alt="icon"
      />
    </button>
  );
}
