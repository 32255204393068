import { Alert, Form, Input, Modal } from "antd";
import React, { useState } from "react";
// import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Images } from "../../../Images/index";
import { checkLoginStatus } from "../../../modules/Games/actions";
import { rsaEncryption } from "../../../utils/encrytion.js";
import { forgotPassword, login, loginModal } from "../../Login/actions";
import AuthModalImg from "../AuthModalImg";
import { openNotificationWithIcon } from "../../../utils/helper";
import routes from "../../../Route/URLs";

const Login = (props) => {
  const { Description, ResponseCode, loginModal, checkLoginStatus, loginStatus, forgotPassword } = props;
  const [form] = Form.useForm();
  const [isLoggedInProcess, setIsLoggedInProcess] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const publicKey = useSelector((state) => state?.config?.REACT_APP_LOGIN_API_PUBLIC_KEY) || "";
  const modal = useSelector((state) => state?.loginReducer?.modal);
  const themeMode = useSelector((state) => state?.themeReducer?.theme);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [forgotpasswindow, setforgotpasswindow] = useState(false);
  const onFinish = async (values) => {
    const { login } = props;
    if (values) {
      const encryptedData = rsaEncryption(values, publicKey);
      await login(encryptedData);
      await checkLoginStatus(false);
    }
  };

  // console.log(publicKey + "  _ publicKey")

  function goToSignup() {
    handleClose();
    history.push(routes.SIGN_UP);
  }

  const handleRecoveryPassword = async () => {
    if (!emailOrMobile.length || emailOrMobile === "") return;
    await forgotPassword({
      EmailOrMobile: emailOrMobile,
      LanguageId: "en",
      PartnerId: 74,
      TimeZone: 0,
    });
    handleClose();
    openNotificationWithIcon("success", "Success", "Forgot password link is sent to your email.");
  };

  const onSubmitCapture = () => {
    setIsLoggedInProcess(true);
    setTimeout(() => {
      setIsLoggedInProcess(false);
    }, 3000);
  };

  const handleClose = async () => {
    loginModal(false);
    setForgotPasswordModal(false);
    setEmailOrMobile("");
    setforgotpasswindow(false);
    // history.push(routes.HOME_PAGE)
    await checkLoginStatus(false);
  };

  // const hostname = window.location;

  // useEffect(() => {
  //   if (hostname?.pathname && hostname?.pathname.includes("change-password")) {
  //     loginModal(true);
  //     setForgotPasswordModal(true);
  //     setforgotpasswindow(true);
  //   }
  //   //eslint-disable-next-line
  // }, [hostname]);

  return (
    <Modal
      open={loginStatus ? loginStatus : modal}
      // open={true}
      footer={null}
      width="880px"
      centered
      className="theme-modal authModal"
      onCancel={handleClose}
      closable={false}>
      {forgotPasswordModal ? (
        <div className="authModal-row">
          <AuthModalImg />
          <div className="theme-modal-body authModal-body">
            <div className="theme-modal-header authModal-header">
              <p className="theme-modal-heading">{t("Forgot Password")}</p>
              <button
                type="button"
                onClick={handleClose}
                className={`theme-modal-close ${themeMode === "light" ? "light-theme" : ""}`}>
                <img
                  width="19"
                  loading="lazy"
                  height="19"
                  src={Images.closeIcon}
                  alt="icon"
                />
              </button>
            </div>
            {forgotpasswindow ? (
              ""
            ) : (
              <div>
                <div className="authModal-forgot">
                  <img
                    width="58"
                    height="76"
                    className={`authModal-forgot-icon ${themeMode === "light" ? "black-image" : ""}`}
                    src={Images.lockIcon}
                    alt="lock icon"
                    loading="lazy"
                  />
                  <p className="authModal-forgot-text">{t("Provide your account's email")}</p>
                </div>
                <Input
                  placeholder={t("Email")}
                  name="EmailOrMobile"
                  value={emailOrMobile}
                  className="theme-input"
                  onChange={(e) => setEmailOrMobile(e.target.value)}
                  prefix={
                    <img
                      width="18"
                      height="18"
                      src={Images.emailIcon}
                      alt="icon"
                      loading="lazy"
                    />
                  }
                />
                <div className="authModal-recoveryText">
                  <Link
                    className="link-style"
                    to="/support"
                    onClick={handleClose}>
                    {t("Support")}
                  </Link>
                </div>
                <button
                  className={`authModal-recoveryBtn theme-btn full-width sqr-btn no-mobile ${isLoggedInProcess ? "cursor-not-allowed" : ""}`}
                  onClick={handleRecoveryPassword}
                  disabled={isLoggedInProcess}>
                  {t("Send me a recovery link")}
                </button>
              </div>
            )}
            {forgotpasswindow && <div className=""></div>}
          </div>
        </div>
      ) : (
        <Form
          name=""
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onSubmitCapture={onSubmitCapture}
          // autoComplete="off"
        >
          <div className="authModal-row">
            <AuthModalImg />

            <div className="theme-modal-body authModal-body">
              <div className="theme-modal-header authModal-header">
                <p className="theme-modal-heading pd-r">{t("Sign In")}</p>
                <button
                  type="button"
                  onClick={handleClose}
                  className={`theme-modal-close ${themeMode === "light" ? "light-theme" : ""}`}>
                  <img
                    width="19"
                    height="19"
                    src={Images.closeIcon}
                    alt="icon"
                    loading="lazy"
                  />
                </button>
              </div>

              <div className="authModal-form">
                <Form.Item
                  name="ClientIdentifier"
                  rules={[{ required: true, message: t("Please enter Username") }]}>
                  <Input
                    data-e2e="user-name"
                    placeholder={t("Login1")}
                    className="theme-input"
                    prefix={
                      <img
                        width="18"
                        height="18"
                        src={Images.emailIcon}
                        alt="icon"
                        loading="lazy"
                      />
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="Password"
                  autoComplete="off"
                  rules={[
                    {
                      required: true,
                      type: "password",
                      message: t("Please enter your Password"),
                    },
                  ]}>
                  <Input
                    placeholder={t("Password")}
                    className="theme-input"
                    type="password"
                    prefix={
                      <img
                        className="password-icon"
                        src={Images.passwordIcon}
                        alt=""
                      />
                    }
                    data-e2e="pass-word"
                  />
                </Form.Item>
                <div className="d-flex">
                  <div
                    className="authModal-link"
                    onClick={() => setForgotPasswordModal(true)}>
                    {t("Forgot Password")}?
                  </div>
                </div>
                {ResponseCode && ResponseCode !== 0 ? (
                  <Alert
                    message={Description || t("Something Went wrong")}
                    type="error"
                    // closable
                    style={{ margin: "10px 0px" }}
                    showIcon
                  />
                ) : null}
                {/* --- */}
                <button
                  data-e2e="log-in"
                  type="submit"
                  className={`authModal-btn login-btn theme-btn full-width sqr-btn no-mobile ${isLoggedInProcess ? "cursor-not-allowed" : ""}`}
                  onClick={() => {}}
                  disabled={isLoggedInProcess}>
                  {t("Sign In")}
                </button>
                <p className="authModal-terms authModal-text">
                  {t("accessing_site")}{" "}
                  <Link
                    to="/terms&conditions"
                    onClick={handleClose}>
                    {t("Terms and Conditions")}
                  </Link>
                </p>
                <div className="authModal-text">
                  <span>{t("Dont_have_an_account")}</span>{" "}
                  <button
                    className="link-style"
                    type="button"
                    onClick={goToSignup}>
                    {t("Sign Up")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    loginStatus: state?.gamesReducer?.loginStatus,
    recoveryData: state?.loginReducer?.recoveryData,
  };
};

export default connect(mapStateToProps, {
  login,
  loginModal,
  checkLoginStatus,
  forgotPassword,
})(Login);
