export const resources = {
  en: { translation: require("./en.json") },
  da: { translation: require("./da.json") },
  de: { translation: require("./de.json") },
  fr: { translation: require("./fr.json") },
  es: { translation: require("./es.json") },
  it: { translation: require("./it.json") },
  nn: { translation: require("./nn.json") },
  ru: { translation: require("./ru.json") },
  zh: { translation: require("./zh.json") },
  pt: { translation: require("./pt.json") },
  tr: { translation: require("./tr.json") },
  he: { translation: require("./he.json") },
  ar: { translation: require("./ar.json") },
  // ptBR: { translation: require('./ptBR.json') }
};
