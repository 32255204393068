import { Images } from "../../../Images";

export const productCode = {
  PRAGMATIC: 54230,
  EVOLUTION: 21000,
  EZUGI: 5001,
  VIVOGAMING: 75697,
	MONOPOLY: 21000,
	SWEETBONANZA: 54230,
	TEENPATTI: 75697,
	LIGHTROULETTE: 21000,
	CRAZYTIME: 21000,
	BOOMCITY: 54230
};

export const liveCasino = [
	{
		img: Images.pragmaticLiveNoLogo,
		title: "Pragmatic Play",
		productCode: productCode.PRAGMATIC,
		logo: Images.pragmatic,
		class: "pragmatic",
		slug: "pragmatic-play"
	},
	{
		img: Images.evolutionLiveNoLogo,
		title: "Evolution",
		productCode: productCode.EVOLUTION,
		logo: Images.evolution,
		class: "evolution",
		slug: "evolution"
	},
	{
		img: Images.ezugiLiveNoLogo,
		title: "Ezugi",
		productCode: productCode.EZUGI,
		logo: Images.ezugi,
		class: "ezugi",
		slug: "ezugi"

	},
	{
		img: Images.vivoGamingLiveNoLogo,
		title: "Vivo Gaming",
		productCode: productCode.VIVOGAMING,
		logo: Images.vivoGaming,
		class: "vivo",
		slug: "vivo-gaming"
	},
];
