import CommonFooterTermsAndConditions from "./CommonFooterTermsAndConditions";
import { useTranslation } from "react-i18next";

const ResponsibleGames = () => {
  const { t } = useTranslation();
  return (
    <CommonFooterTermsAndConditions
      title={t("Responsible Gaming")}
      data={t("responsible_gaming_text")}
    />
  );
};

export default ResponsibleGames;
