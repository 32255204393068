import { NavLink } from "react-router-dom/cjs/react-router-dom";
import routes from "../../Route/URLs";
import { Images } from "../../Images";
import { apiVars } from "../../api";

export default function ProductLinks() {
  return (
    <>
      <a
        href={apiVars.pokerUrl}
        className="landHeader__products-item">
        <img
          src={Images.pokerProductLink}
          alt=""
        />
        <div className="landHeader__products-line poker"></div>
      </a>
      <NavLink
        to={routes.CASINO}
        className="landHeader__products-item">
        <img
          src={Images.casinoProductLink}
          alt=""
        />
        <div className="landHeader__products-line casino"></div>
      </NavLink>
      <NavLink
        to={routes.SPORTS}
        className="landHeader__products-item">
        <img
          src={Images.sportProductLink}
          alt=""
        />
        <div className="landHeader__products-line sports"></div>
      </NavLink>
    </>
  );
}
