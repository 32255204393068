import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { loginModal } from "../Components/Login/actions";
import { Images } from "../Images";
import { getItemFromLocalStorage } from "../utils/localStorage";
import { useTranslation } from "react-i18next";
import routes from "../Route/URLs";
import { checkLoginStatus } from "../modules/Games/actions";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import BigNumber from "bignumber.js";

const ProfileBlock = (props) => {
  const { t } = useTranslation();
  const { loginModal } = props;
  const history = useHistory();
  const [userbalance, setuserbalance] = useState(0);

  const loggedIn = getItemFromLocalStorage("Token");
  const username = getItemFromLocalStorage("UserName");
  const userData = getItemFromLocalStorage("userData");
  const Theme = useSelector((state) => state?.themeReducer?.theme);

  const AvailableBalance = useSelector(
    (state) =>
        state?.headerReducer?.getClientBalanceData?.data?.Balances?.reduce((total, balance) => {
            // Check if the balance's TypeId is 1, 2, or 12 and add it to the total
            if (balance?.TypeId === 1 || balance?.TypeId === 2 || balance?.TypeId === 12) {
              // Add balance.Balance to the total amount (as BigNumber)
              return total.plus(balance.Balance);
            }
            return total;
          }, new BigNumber(0))
);

  const isClientBalance = useSelector(
    (state) => state?.headerReducer?.isClientBalance
  );
  const [userlogin, setuserlogin] = useState(false);

  const showLoginModal = () => {
    loginModal(true);
  };

  useEffect(() => {
    if (isClientBalance) {
      setuserbalance(AvailableBalance?.toString());
    }
     // eslint-disable-next-line
  }, [AvailableBalance?.toString(), isClientBalance]);

  useEffect(() => {
    if (loggedIn) {
      setuserlogin(true);
      loginModal(false);
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  const handledeposit = () => {
    if (loggedIn) {
      history.push(routes.CASHER_WALLETS);
    } else {
      checkLoginStatus(true);
    }
  };

  return (
    <>
      <div className="sidebar-profile block-fill">
        <div className="sidebar-profile-img">
          <img
            width="80"
            height="80"
            src={Theme === "dark" ? Images.userIcon : Images.userIconLight}
            alt="user icon"
            loading="lazy"
          />
        </div>

        {!userlogin ? (
          <>
            <div className="d-flex sidebar-profile-btn">
              <button className="landHeader__actions-login full-width" onClick={showLoginModal}>
                {t("Sign In")}
              </button>
            </div>
            <div className="d-flex">
              <NavLink to={routes.SIGN_UP}
              exact activeClassName="active"
                className="theme-btn fill-btn full-width"
              >
                {t("Sign Up")}
              </NavLink>
            </div>
          </>
        ) : (
          <>
            <p className="sidebar-profile-username">{username}</p>
            <div className="sidebar-profile-amount">
              <p>
                {userData?.CurrencySymbol}
                {userbalance}
              </p>
              <button
                className="sidebar-profile-amount-add"
                onClick={handledeposit}
              >
                <span>+</span>
              </button>
            </div>
          </>
        )}
      </div>
      {/* <Register /> */}
    </>
  );
};

export default connect(null, {
  loginModal,
  checkLoginStatus,
})(ProfileBlock);
